import React from 'react';
import { Avatar, Box, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SubsidiarySelector from '../TopBar/SubsidiarySelector';
import { useRestaurant } from '../../../hooks/useRestaurant';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 280,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    borderRadius: 10
  },
  restaurantName: {
    margin: theme.spacing(1)
  }
}));

const RestaurantOptions = () => {
  const classes = useStyles();
  const restaurant = useRestaurant();
  return (
    <Box m={2}>
      <Box display="flex" alignItems="center">
        <RouterLink to="/app/configuration">
          <Avatar
            alt="Restaurant"
            variant="rounded"
            className={classes.avatar}
            src={
              restaurant.logo_photo.x400
                ? restaurant.logo_photo.x400
                : restaurant.logo_photo.original
            }
          />
        </RouterLink>
        <Link
          component={RouterLink}
          to="/app/configuration"
          variant="h5"
          color="textPrimary"
          underline="none"
          className={classes.restaurantName}
        >
          {restaurant.name}
        </Link>
      </Box>
      <SubsidiarySelector />
    </Box>
  );
};

export default RestaurantOptions;
