import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  Grid
} from '@material-ui/core';
import Logo from 'src/components/LogoEntregasCom';
import { useCurrentUser } from '../../hooks/useUser';
import { ROUTES_PATHS } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 84,
    width: '100%'
  },
  logo: {
    marginRight: theme.spacing(2),
    width: '130px'
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  buttonContainer: {
    marginLeft: 20
  },
  buttonContained: {
    backgroundColor: theme.palette.expressColors.primary,
    color: theme.palette.expressColors.white,
    borderRadius: 0
  },
  buttonOutlined: {
    backgroundColor: theme.palette.expressColors.white,
    color: theme.palette.expressColors.primary,
    borderColor: theme.palette.expressColors.primary,
    borderRadius: 0,
    cursor: 'pointer'
  },
  buttonText: {
    textTransform: 'none',
    fontWeight: '600',
    [theme.breakpoints.down('400')]: {
      marginTop: 10
    }
  },
  containerItem: {
    [theme.breakpoints.down('400')]: {
      display: 'flex'
    }
  },
  containerButtons: {
    [theme.breakpoints.down('400')]: {
      display: 'flex',
      justifyContent: 'flex-start'
    }
  }
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const currentPath = location.pathname;
  const auxPath = `/${currentPath.split('/')[1]}`;
  const goToLogin = () => {
    switch (auxPath) {
      case ROUTES_PATHS.DRIVER_REGISTER:
        history.push(ROUTES_PATHS.DRIVER_LOGIN);
        break;
      default:
        history.push(ROUTES_PATHS.WELCOME_COMMERCE);
    }
  };
  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.containerItem}>
          <Grid item xs={6} sm={6} md={6}>
            <RouterLink to="/admin">
              <Logo className={classes.logo} />
            </RouterLink>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Grid
              container
              justify="flex-end"
              className={classes.containerButtons}
            >
              {!currentUser && (
                <Grid item>
                  <Box className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      className={clsx(
                        classes.buttonOutlined,
                        classes.buttonText
                      )}
                      onClick={goToLogin}
                    >
                      Ya soy aliado
                    </Button>
                  </Box>
                </Grid>
              )}
              {auxPath !== ROUTES_PATHS.DRIVER_REGISTER && (
                <Grid item>
                  <Box className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      className={clsx(
                        classes.buttonContained,
                        classes.buttonText
                      )}
                      onClick={() => history.push(`/login`)}
                    >
                      {!currentUser && <>Entrar</>}
                      {currentUser && <>Ir al Tablero</>}
                    </Button>
                  </Box>
                </Grid>
              )}
              {currentUser && !currentUser.is_driver && (
                <Grid item>
                  <Box className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      className={clsx(
                        classes.buttonContained,
                        classes.buttonText
                      )}
                      onClick={() => history.push(`/home`)}
                    >
                      Restaurante
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
