import React, { useState, useEffect, useCallback } from 'react';
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  Grid,
  Chip,
  CircularProgress,
  IconButton,
  SvgIcon,
  makeStyles,
  Typography
} from '@material-ui/core';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-multi-lang/lib';
import Logo from 'src/components/Logo';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Menu as MenuIcon } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import ImageIcon from 'src/components/ImageIcon';
import { actionTypes } from 'redux-firestore';
import { logout } from 'src/actions/accountActions';
import ListOrderCard from './ListOrderCard';
import { getUserRole } from '../../../utils/helpers/userHelper';
import { COCKPIT_OPTIONS, SIDEICONS } from '../../../views/orders/constants';
import { ORDER_STATUS } from '../../../dto/enum';
import { useCurrentSubsidiary } from '../../../hooks/useRestaurant';
import {
  useNewOrders,
  useOrdersInProcess,
  useOrdersBeenDelivery,
  useOrdersCompleted
} from '../../../hooks/Order/useOrders';
import { COLLECTIONS } from '../../../constants';
import SubsidiarySelector from '../TopBar/SubsidiarySelector';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 290,
    backgroundColor: theme.palette.expressColors.ordersCockpitBackground
  },
  menuButton: {
    color: theme.palette.expressColors.black
  },
  headerContainer: {
    padding: '20px 18px 20px 9px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  desktopDrawer: {
    width: 280,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 58,
    height: 58
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '13px',
    padding: '25px 20px'
  },
  profileContainerInformation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '6px',
    maxWidth: '150px',
    overflowWrap: 'break-word'
  },
  userName: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'unset'
  },
  role: {
    fontWeight: 400,
    lineHeight: 'unset',
    fontSize: 10
  },
  listItem: {
    padding: '14px 22px',
    display: 'flex',
    flexDirection: 'row',
    gap: '14px'
  },
  sectionIcon: {
    width: 27,
    height: 27,
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20
    }
  },
  activeSection: {
    background: theme.palette.expressColors.activeGreen,
    borderTop: `1px solid ${theme.palette.expressColors.activeGreen}`,
    borderBottom: `1px solid ${theme.palette.expressColors.activeGreen}`
  },
  section: {
    background: theme.palette.expressColors.greenCake,
    '&:hover': {
      background: theme.palette.expressColors.hoverGreen
    }
  },
  subsidiarySelector: {
    paddingLeft: '20px',
    paddingBottom: '10px'
  }
}));

const NavBarMobile = ({ openMobile, onMobileClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const translation = useTranslation();
  const dispatch = useDispatch();
  const limitDay = 0;
  const history = useHistory();
  const { orderId } = useParams();
  const newOrder = orderId === 'new';
  const [options, setOptions] = useState(COCKPIT_OPTIONS);
  const account = useSelector(state => state.account);
  const allOrder = useSelector(state => state.firestore.ordered.order);
  const currentSubsidiary = useCurrentSubsidiary();
  const [ordersInProcess, setOrdersInProcess] = useState([]);
  const [newOrders, setNewOrders] = useState(false);
  const [ordersCompleted, setOrdersCompleted] = useState(false);
  const [ordersBeenDelivery, setBeenDelivery] = useState(false);
  const callNewOrders = useNewOrders(1);
  const callBeenDelivery = useOrdersBeenDelivery(limitDay);
  const callOrdersCompleted = useOrdersCompleted(limitDay, true);
  const ordersInProcessRaw = useOrdersInProcess(limitDay);

  const getStatus = status => {
    switch (status) {
      case ORDER_STATUS.READY_TO_CASHIER:
        return 0;
      case ORDER_STATUS.READY_TO_PICK:
        return 1;
      case ORDER_STATUS.ACCEPTED:
        return 2;
      default:
        return null;
    }
  };

  const setOption = useCallback(
    id => {
      history.push(`/app/orders/${id}`);
    },
    [history]
  );

  useEffect(() => {
    let sortedArray = [];
    if (ordersInProcessRaw)
      sortedArray = ordersInProcessRaw
        .map(item => ({ ...item, orderToSort: getStatus(item.status) }))
        .sort((a, b) => (a.orderToSort > b.orderToSort ? 1 : -1));
    if (callNewOrders && newOrders !== callNewOrders)
      setNewOrders(callNewOrders);
    if (ordersInProcessRaw && ordersInProcessRaw !== ordersInProcess)
      setOrdersInProcess(sortedArray);
    if (callBeenDelivery && callBeenDelivery !== ordersBeenDelivery)
      setBeenDelivery(callBeenDelivery);
    if (callOrdersCompleted && ordersCompleted !== callOrdersCompleted)
      setOrdersCompleted(callOrdersCompleted);
    // eslint-disable-next-line
  }, [allOrder]);

  useEffect(() => {
    if (ordersInProcess && ordersCompleted && ordersBeenDelivery) {
      if (!orderId && ordersInProcess.length) {
        const [order] = ordersInProcess;
        history.push(`/app/orders/${order.id}`);
      }
      if (orderId && allOrder) {
        if (!newOrder) history.push(`/app/orders`);
      }
    }
    // eslint-disable-next-line
  }, [orderId, ordersInProcess, ordersCompleted, ordersBeenDelivery]);

  const getRole = () => {
    const r = getUserRole(account.user);
    if (r) return translation(`roles.${r.toLowerCase()}`);
    return translation(`roles.unknown`);
  };

  const onClickList = (index, option) => {
    onMobileClose();
    const newOptions = [...options];
    newOptions[index].open = !newOptions[index].open;
    setOptions(newOptions);
    if (
      option.value === ORDER_STATUS.MAKE_ORDER &&
      currentSubsidiary?.xpress_motos
    ) {
      history.push(`/app/orders/new`);
    }
  };

  const configureCards = array => {
    return array.map(order => {
      const driverName = _.isEmpty(order.driver)
        ? null
        : `${order.driver.first_name} ${order.driver.last_name}`;

      return (
        <Box key={order.id} my={1.5}>
          <ListOrderCard
            orderId={order.id}
            activeOrder={orderId}
            orderStatus={order.status}
            orderCode={order.order_code}
            orderType={order.order_type}
            orderCookingTime={order.cooking_time}
            acceptedTime={order.accepted_time}
            userFullName={order.user_full_name}
            createdAt={order.created_at}
            onClickedCard={props => {
              setOption(props);
              onMobileClose();
            }}
            driverId={order.driver_id}
            driverName={driverName}
            deliveryStatus={order.delivery_status}
            amountOfProducts={order.products_available_number}
            total={order.total}
            deliveryTime={order.delivery_time}
          />
        </Box>
      );
    });
  };

  const displayCards = value => {
    switch (value) {
      case ORDER_STATUS.IN_PROCESS:
        return configureCards(newOrders);
      case ORDER_STATUS.ACCEPTED:
        return configureCards(ordersInProcess);
      case ORDER_STATUS.DELIVERING:
        return configureCards(ordersBeenDelivery);
      case ORDER_STATUS.COMPLETED:
        return configureCards(ordersCompleted);
      case ORDER_STATUS.SCHEDULE:
        return [];
      default:
        return [];
    }
  };

  const constructListItem = (
    label,
    size,
    icon = SIDEICONS.inProcess,
    disabled
  ) => {
    return (
      <Grid item container>
        <ImageIcon styles={clsx(classes.sectionIcon)} src={icon} />
        <Typography
          style={{ color: !disabled ? 'red' : '' }}
          className={clsx(classes.sectionTitle)}
        >
          {!disabled ? `${translation('dashboard.disable')}-` : ''}
          {translation(label)}
        </Typography>
        {size > 0 && (
          <Chip
            size="small"
            label={size}
            className={clsx(classes.activeChip)}
          />
        )}
      </Grid>
    );
  };

  const getListItem = (value, label) => {
    switch (value) {
      case ORDER_STATUS.MAKE_ORDER:
        return constructListItem(
          label,
          0,
          SIDEICONS.makeOrder,
          currentSubsidiary?.xpress_motos || false
        );
      case ORDER_STATUS.IN_PROCESS:
        return constructListItem(label, newOrders.length, true);
      case ORDER_STATUS.ACCEPTED:
        return constructListItem(
          label,
          ordersInProcess.length,
          SIDEICONS.inProcess,
          true
        );
      case ORDER_STATUS.DELIVERING:
        return constructListItem(
          label,
          ordersBeenDelivery.length,
          SIDEICONS.delivering,
          true
        );
      case ORDER_STATUS.COMPLETED:
        return constructListItem(
          label,
          ordersCompleted.length,
          SIDEICONS.completed,
          true
        );
      case ORDER_STATUS.SCHEDULE:
        return constructListItem(
          label,
          newOrders.length,
          SIDEICONS.scheduled,
          true
        );
      default:
        return console.log('unhandled order status', value);
    }
  };

  const handleLogout = async () => {
    try {
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.SUBSIDIARY }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.RESTAURANT }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.PRODUCT }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.ORDER }
      });
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar(translation('account.unable_logout'), {
        variant: 'error'
      });
    }
  };

  const content = (
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column">
          <Box className={classes.headerContainer}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
            <IconButton
              className={classes.menuButton}
              color="primary"
              onClick={onMobileClose}
            >
              <SvgIcon>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Divider />
          <Box className={classes.profileContainer}>
            <Avatar
              alt="User"
              className={classes.avatar}
              src={
                account.user.profile_photo ? account.user.profile_photo.x40 : ''
              }
            />
            <Box className={classes.profileContainerInformation}>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.userName}
              >
                {`${account.user.first_name} ${account.user.last_name}`}
              </Typography>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.role}
              >
                {getRole()}
              </Typography>
            </Box>
          </Box>
          <div className={classes.subsidiarySelector}>
            <SubsidiarySelector styles={{ background: 'white' }} />
          </div>
          <Divider />
          {!newOrders &&
            !ordersInProcess &&
            !ordersCompleted &&
            !ordersBeenDelivery && (
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            )}
          {newOrders &&
            ordersInProcess &&
            ordersCompleted &&
            ordersBeenDelivery && (
              <List>
                {options.map((option, index) => {
                  return (
                    <Box width="100%" key={`${option.label}-${index}`}>
                      <ListItem
                        className={classes.listItem}
                        onClick={() => onClickList(index, option)}
                      >
                        {getListItem(option.value, option.label)}
                      </ListItem>
                      <Collapse
                        className={classes.collapsable}
                        xs={12}
                        in={option.open}
                        style={{ width: '100%' }}
                      >
                        {displayCards(option.value)}
                      </Collapse>
                      <Divider className={classes.divider} />
                    </Box>
                  );
                })}
              </List>
            )}
        </Box>
        <Box className={classes.listItem} onClick={handleLogout}>
          <Typography className={clsx(classes.sectionTitle)}>
            {translation('account.logout')}
          </Typography>
        </Box>
      </Box>
    </PerfectScrollbar>
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.mobileDrawer }}
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default NavBarMobile;
