/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import AdminLayout from 'src/layouts/AdminLayout';
import DriverLayout from 'src/layouts/MainLayout';
import CommerceLayout from 'src/layouts/CommerceLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import DriverDashBoard from 'src/layouts/Driver';
import AdminGuard from './components/AdminGuard';
import { USER_ROLES } from './dto/enum';
import RolGuard from './components/RolGuard';
import CompleteRegisterDocuments from './views/commerceRegister/CompleteRegisterDocuments';
import SuccessfulRegistration from './views/commerceRegister/SuccessfulRegistration';
import { ROUTES_PATHS } from './constants';
import DriverLogin from './views/driver/login';
import AuthDriver from './components/AuthDriver';
import AuthGuestDriver from './components/AuthGuestDriver';
import AuthLoginDriver from './components/AuthLoginDriver';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/tracking/:orderId',
    component: lazy(() => import('src/views/tracking'))
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    component: lazy(() =>
      import('src/views/docs/TermAndConditions/TermsAndConditions')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/policy-and-privacy',
    component: lazy(() =>
      import('src/views/docs/PolicyAndPrivacy/PolicyAndPrivacy')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/completeRegister/:id',
    component: lazy(() => import('src/views/auth/CompleteRestaurant'))
  },
  {
    exact: true,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/social-networks',
    component: lazy(() => import('src/views/auth/SocialNetworks'))
  },
  {
    exact: true,
    path: '/user-suspended',
    component: lazy(() => import('src/views/auth/UserSuspended'))
  },
  {
    path: '/admin',
    guard: AdminGuard,
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: '/admin',
        component: () => <Redirect to="/admin/orders" />
      },
      {
        exact: true,
        path: '/admin/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/admin/orders',
        component: lazy(() => import('src/views/admin/orders'))
      },
      {
        exact: true,
        path: '/admin/orders/:restId/:subId',
        component: lazy(() => import('src/views/admin/orders/GenerateOrder'))
      },
      {
        exact: true,
        path: '/admin/history/:restId/:subId',
        component: lazy(() => import('src/views/costumerHistory'))
      },
      {
        exact: true,
        path: '/admin/userHistory/:userId/:subId/:restId',
        component: lazy(() => import('src/views/userHistory'))
      }
    ]
  },
  {
    path: '/driver_register',
    layout: DriverLayout,
    guard: AuthGuestDriver,
    routes: [
      {
        exact: true,
        path: '/driver_register',
        component: lazy(() => import('src/views/driverRegister'))
      },
      {
        exact: true,
        path: '/driver_register/:userID',
        component: lazy(() => import('src/views/driverRegister/driverStatus'))
      },
      {
        exact: true,
        path: '/driver_register/:userID/:phoneNumber',
        component: lazy(() => import('src/views/driverRegister/driverLogged'))
      }
    ]
  },
  {
    path: '/commerce_register',
    layout: CommerceLayout,
    routes: [
      {
        exact: true,
        path: '/commerce_register',
        component: lazy(() => import('src/views/commerceRegister'))
      },
      {
        exact: true,
        path: '/commerce_register/completeRegisterDocuments/:commerceId',
        component: CompleteRegisterDocuments
      },
      {
        exact: true,
        path: '/commerce_register/successful_registration',
        component: SuccessfulRegistration
      }
    ]
  },
  {
    path: ROUTES_PATHS.DRIVER,
    guard: AuthDriver,
    layout: DriverDashBoard,
    routes: [
      {
        exact: true,
        path: ROUTES_PATHS.DRIVER,
        component: () => <Redirect to={ROUTES_PATHS.DRIVER_DASHBOARD} />
      },
      {
        exact: true,
        path: ROUTES_PATHS.DRIVER_DASHBOARD,
        component: lazy(() => import('src/views/driver/dashBoard'))
      },
      {
        exact: true,
        path: ROUTES_PATHS.DRIVER_ORDERS,
        component: lazy(() => import('src/views/driver/orders'))
      },
      {
        exact: true,
        path: ROUTES_PATHS.DRIVER_QUALIFICATION,
        component: lazy(() => import('src/views/driver/qualification'))
      },
      {
        exact: true,
        path: ROUTES_PATHS.DRIVER_SETTINGS,
        component: lazy(() => import('src/views/driver/settings'))
      }
    ]
  },
  {
    path: '/login-driver',
    guard: AuthLoginDriver,
    layout: DriverLogin
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/orders" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path: '/app/orders',
        component: lazy(() => import('src/views/orders'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId',
        component: lazy(() => import('src/views/orders'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/members',
        component: lazy(() => import('src/views/members/MemberListView'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/dealers',
        component: lazy(() => import('src/views/dealers/DealerListView'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/members/create',
        component: lazy(() => import('src/views/members/MemberCreateView'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/members/:memberId',
        component: lazy(() => import('src/views/members/MemberDetailsView'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/members/:memberId/edit',
        component: lazy(() => import('src/views/members/MemberEditView'))
      },

      {
        exact: true,
        path: '/app/management/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },

      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/management/dashboard" />
      },
      {
        exact: true,
        path: '/app/configuration',
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        component: lazy(() =>
          import('src/views/configurations/ConfigurationsView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/subsidiary/:id',
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        component: lazy(() => import('src/views/commerce/Subsidiary'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        path: '/app/performance/earnings',
        component: lazy(() =>
          import('src/views/performance/EarningsReport/EarningsReport')
        )
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        path: '/app/performance/salesReport',
        component: lazy(() => import('src/views/performance/salesReport'))
      },
      {
        exact: true,
        path: '/app/history',
        component: lazy(() => import('src/views/costumerHistory'))
      },
      {
        exact: true,
        path: '/app/userHistory/:userId',
        component: lazy(() => import('src/views/userHistory'))
      },
      {
        exact: true,
        path: '/app/centralPayments',
        component: lazy(() => import('./views/centralPayments'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: CommerceLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: lazy(() => import('src/views/welcomeCommerce'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const blockProfiles = route.blockProfiles
            ? { blockProfiles: route.blockProfiles }
            : {};

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard {...blockProfiles}>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
