/* eslint-disable no-param-reassign */
import { SET_CHATS } from '../actions/chatActions';

const initialState = [];

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS: {
      const { chats } = action.payload;
      return [...chats];
    }
    default:
      return state;
  }
};

export default chatReducer;
