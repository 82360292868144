import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const ConfirmationDialog = ({
  open,
  handleCancel,
  handleAccept,
  question,
  children,
  acceptLabel = 'Aceptar',
  cancelLabel = 'Cancelar'
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{question}</DialogTitle>

      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={handleAccept} color="primary" autoFocus>
          {acceptLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
