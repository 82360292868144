import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  icon: {
    width: 25
  }
}));
const ImageIcon = props => {
  const { src, styles } = props;
  const classes = useStyles();
  return <img src={src} alt="icon" className={clsx(classes.icon, styles)} />;
};

export default ImageIcon;
