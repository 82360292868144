import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '@material-ui/core';
import clsx from 'clsx';
import { useChatViewStyles } from './chatStyles';
import Conversation from './Conversation';

const ChatViewPage = () => {
  const classes = useChatViewStyles();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const chats = useSelector(state => state.chats);
  if (!chats || !chats.length) return <></>;

  return (
    <>
      <Card
        className={clsx(classes.chatCard, {
          [classes.minimizedChard]: isCollapsed
        })}
      >
        <Conversation
          chatId={chats[0].id}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </Card>
    </>
  );
};

export default ChatViewPage;
