import React from 'react';
import { Container, Typography, Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { OrangeButtonCommerce } from '../../components/Inputs/Buttons';
import { COLORS } from '../../theme/colors';
import BoxImage from '../../components/BoxImage';

const useStyles = makeStyles(theme => ({
  textTitle: {
    fontSize: '60px',
    color: COLORS.secondaryGreen
  },
  textSubtitle: {
    fontSize: '30px',
    fontWeight: 'normal'
  },
  containerMain: {
    marginTop: '138px'
  },
  containerButton: {
    marginTop: '181px'
  },
  containerImage: {
    width: '516px',
    height: '308px',
    position: 'relative',
    left: '-406px',
    top: '-76px'
  },
  styleImage: {
    width: '516px',
    height: '308px'
  },
  [theme.breakpoints.down('969')]: {
    textTitleResponsive: {
      fontSize: '40px',
      marginBottom: '40px'
    },
    containerMainResponsive: {
      marginTop: '60px'
    },
    containerButtonResponsive: {
      marginTop: '50px'
    },
    containerImageResponsive: {
      width: '388px',
      height: '232px',
      position: 'relative',
      left: '0px',
      top: '0px',
      marginTop: '42px'
    },
    styleImageResponsive: {
      width: '388px',
      height: '232px'
    }
  }
}));

const SuccessfulRegistration = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container>
      <Box
        className={clsx(classes.containerMain, classes.containerMainResponsive)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box>
          <Typography
            className={clsx(classes.textTitle, classes.textTitleResponsive)}
          >
            !Has registrado tu negocio exitosamente!
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.textSubtitle}>
            Se te enviará un correo cuando respondamos a tu solicitud.
          </Typography>
        </Box>
        <Box
          className={clsx(
            classes.containerButton,
            classes.containerButtonResponsive
          )}
        >
          <OrangeButtonCommerce
            style={{ width: '342px', height: '80px' }}
            onClick={() => history.push('/home')}
          >
            VOLVER AL INICIO
          </OrangeButtonCommerce>
        </Box>
        <Box
          className={clsx(
            classes.containerImage,
            classes.containerImageResponsive
          )}
        >
          <BoxImage
            className={clsx(classes.styleImage, classes.styleImageResponsive)}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default SuccessfulRegistration;
