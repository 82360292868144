import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { COLLECTIONS } from '../constants';
import { REGISTER_STATUS } from '../dto/enum';
import { useAllRestaurantSubsidiaries } from './AdminHooks/subsidiaries/useSubsidiaries';
import { isOwner } from '../utils/helpers/userHelper';

export function useRestaurant(restFromState = null) {
  const restaurant = useSelector(state => state.account.restaurant);
  const userRestaurant = useSelector(
    state => state.firestore.data.userRestaurant
  );
  const getId = () => {
    if (restFromState) return restFromState;
    if (restaurant) return restaurant.id;
    return '';
  };
  const id = getId();

  useFirestoreConnect({
    collection: COLLECTIONS.RESTAURANT,
    doc: id,
    storeAs: restaurant ? 'userRestaurant' : 'restaurant'
  });

  const rest = useSelector(state => state.firestore.data.restaurant);
  if (restFromState) return rest || {};

  return userRestaurant || restaurant;
}

export function useSubsidiaries() {
  const subsidiaries = useSelector(state => state.account.subsidiaries);
  return subsidiaries;
}

export function useAccountRestaurant() {
  const restaurant = useSelector(state => state.account.restaurant);
  return restaurant;
}

export function useSubsidiariesUser(restId) {
  const subsidiaries = useSubsidiaries();
  const currentUser = useSelector(state => state.account.user);
  const ownerSubsidiariesForAdmin = useAllRestaurantSubsidiaries(restId);
  if (restId && ownerSubsidiariesForAdmin) {
    return ownerSubsidiariesForAdmin;
  }
  if (isOwner(currentUser)) {
    return subsidiaries;
  }
  return subsidiaries.filter(s => currentUser.subsidiary_id.includes(s.id));
}

export function useCurrentSubsidiary(isAdmin = null) {
  const subsidiary = useSelector(state => state.account.currentSubsidiary);
  const subsidiaryFS = useSelector(
    state => state.firestore.data.current_subsidiary
  );
  const sub = isAdmin ? subsidiaryFS : subsidiary;
  return sub;
}

export function useCurrentListenSubsidiary() {
  const subsidiary = useSelector(state => state.account.currentSubsidiary);
  useFirestoreConnect({
    collection: COLLECTIONS.SUBSIDIARY,
    doc: subsidiary.id,
    storeAs: 'current_subsidiary_schedule'
  });
  let subsidiaryFS = useSelector(
    state => state.firestore.ordered.current_subsidiary_schedule
  );
  if (subsidiaryFS && subsidiaryFS.length !== 0) [subsidiaryFS] = subsidiaryFS;
  return subsidiaryFS || subsidiary;
}

export default function useRestaurantAndSubsidiaries() {
  const restaurant = useRestaurant();
  const subsidiaries = useSubsidiaries();

  return [restaurant, subsidiaries];
}

export const useRestaurantSnap = restaurantId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      storeAs: `restaurant`
    }
  ]);
  const restaurantSnap = useSelector(state => state.firestore.data.restaurant);

  return restaurantSnap;
};

export function useAppOrders(restaurantId, subsidiaryId) {
  const limitDay = moment()
    .startOf('day')
    .subtract(1, 'days');
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['restaurant_id', '==', restaurantId],
        ['subsidiary_id', '==', subsidiaryId],
        ['created_at', '>=', limitDay.toDate()],
        ['xpressMotos', '==', true]
      ]
    }
  ]);
  return true;
}

export function useAppSummary(restaurantId, subsidiaryId) {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUMMARY,
      where: [
        ['restaurant_id', '==', restaurantId],
        ['subsidiary', 'array-contains', subsidiaryId],
        ['summary.size_completed', '>=', 1]
      ]
    }
  ]);
  return true;
}

export function useAppSub(restaurantId, subsidiaryId) {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PRODUCT,
      where: [
        ['subsidiary_id', '==', subsidiaryId || ''],
        ['restaurant_id', '==', restaurantId || '']
      ]
    }
  ]);
  return true;
}

export function useRestaurantStatus(restaurant) {
  if (restaurant && restaurant.length > 0) {
    const restActive = restaurant[0];
    if (
      restActive.register_status === REGISTER_STATUS.ACTIVE ||
      restActive.register_status === REGISTER_STATUS.SUSPENDED
    ) {
      return true;
    }
  }
  return false;
}

export const useRestaurantInvoices = restId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.INVOICE,
      where: [['restaurant_id', '==', restId]],
      storeAs: `restaurant_invoices`
    }
  ]);
  const restaurantInvoices = useSelector(
    state => state.firestore.ordered.restaurant_invoices
  );

  return restaurantInvoices || [];
};
