import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { COLORS } from '../../../theme/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: COLORS.orangeDark,
    color: theme.palette.expressColors.white,
    fontWeight: 'bold',
    padding: '13px 17px',
    borderRadius: 7,
    '&:hover': {
      backgroundColor: theme.palette.expressColors.gray,
      color: theme.palette.expressColors.white
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      margin: 0
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      height: 32
    }
  }
}));

const PrintButton = props => {
  const translation = useTranslation();
  const { text, propsClasses, disabled } = props;
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      onClick={window.print}
      className={clsx(classes.root, propsClasses)}
    >
      {text || translation('orders_view.cockpit.buttons.accept_print')}
    </Button>
  );
};

export default PrintButton;
