import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import User from 'src/dto/user';
import { actionTypes } from 'redux-firestore';
import firebaseService from './firebaseService';
import { COLLECTIONS } from '../constants';
import { logout } from '../actions/accountActions';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password, loginAdmin) =>
    new Promise((resolve, reject) => {
      firebaseService
        .loginWithEmailAndPassword({ email, password }, loginAdmin)
        .then(user => {
          if (user) {
            this.setSession(user.uid);
            resolve({
              ...new User(user),
              id: user.id,
              is_driver: !!user.is_driver
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      firebaseService
        .getDbUser(this.getAccessToken())
        .then(user => {
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
    firebaseService.signOut();
  };

  logoutClean = async (dispatch = () => {}) => {
    dispatch({
      type: actionTypes.CLEAR_DATA,
      meta: { collection: COLLECTIONS.SUBSIDIARY }
    });
    dispatch({
      type: actionTypes.CLEAR_DATA,
      meta: { collection: COLLECTIONS.RESTAURANT }
    });
    dispatch({
      type: actionTypes.CLEAR_DATA,
      meta: { collection: COLLECTIONS.PRODUCT }
    });
    dispatch({
      type: actionTypes.CLEAR_DATA,
      meta: { collection: COLLECTIONS.ORDER }
    });
    await dispatch(logout());
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();

  passwordResetEmail = email =>
    new Promise((resolve, reject) => {
      firebaseService
        .passwordResetEmail(email)
        .then(() => {
          resolve({ send: 'send' });
        })
        .catch(error => {
          reject(error);
        });
    });

  loginWithGoogle = () =>
    new Promise((resolve, reject) => {
      firebaseService
        .loginWithGoogle()
        .then(user => {
          if (user) {
            this.setSession(user.uid);
            resolve({ ...new User(user), id: user.id });
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginWithFacebook = () =>
    new Promise((resolve, reject) => {
      firebaseService
        .loginWithFacebook()
        .then(user => {
          if (user) {
            this.setSession(user.uid);
            resolve({ ...new User(user), id: user.id });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

const authService = new AuthService();

export default authService;
