import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from 'src/components/LogoEntregasCom';
import * as FeatherIcon from 'react-icons/fi';
import { COLORS, COLORS_ENTREGAS_COM } from 'src/theme/colors';
import { useTranslation } from 'react-multi-lang';
import { useWebView } from 'src/hooks/window/windowHooks';
import { useDispatch } from 'react-redux';
import authService from '../../services/authService';
import { ROUTES_PATHS } from '../../constants';

const drawerWidth = window.matchMedia('(min-width: 969px)').matches ? 300 : 225;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: COLORS_ENTREGAS_COM.white,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    color: COLORS_ENTREGAS_COM.orange,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  link: {
    textDecoration: 'none',
    color: COLORS_ENTREGAS_COM.gray,
    margin: '3%',
    fontSize: 18,
    '&:link': {
      textDecoration: 'none',
      borderRadius: 15,
      width: '70%'
    }
  },
  activeLink: {
    textDecoration: 'none',
    color: COLORS_ENTREGAS_COM.gray,
    margin: '3%',
    fontSize: 18,
    '&:link': {
      textDecoration: 'none',
      color: COLORS_ENTREGAS_COM.white,
      background: COLORS_ENTREGAS_COM.green,
      borderRadius: 15,
      width: '70%'
    }
  },
  containerLogo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15%%',
    marginBottom: '10%'
  },
  logo: {
    marginRight: theme.spacing(2),
    width: '70%'
  },
  centerItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    color: COLORS_ENTREGAS_COM.gray,
    height: 20,
    margin: 'auto 5'
  },
  logoutIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0
  },
  iconItem: {
    marginLeft: '8%'
  },
  menuIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

function NavBar() {
  const classes = useStyles();
  const translation = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // eslint-disable-next-line
  const [itemSelected, setItemSelected] = useState(0);
  const webView = useWebView();
  const sideBarData = [
    {
      title: translation('driverDashBoard.navBar.itemDashBoard'),
      path: ROUTES_PATHS.DRIVER_DASHBOARD,
      icon: '/static/icons/user'
    },
    {
      title: translation('driverDashBoard.navBar.itemOrders'),
      path: ROUTES_PATHS.DRIVER_ORDERS,
      icon: '/static/icons/bike'
    },
    {
      title: translation('driverDashBoard.navBar.itemQualification'),
      path: ROUTES_PATHS.DRIVER_QUALIFICATION,
      icon: '/static/icons/Review'
    }
    /* {
      title: translation('driverDashBoard.navBar.settings'),
      path: ROUTES_PATHS.DRIVER_SETTINGS,
      icon: '/static/icons/settings'
    } */
  ];
  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onCloseSession = async () => {
    if (!webView) {
      handleDrawer();
    }
    await authService.logoutClean(dispatch);
  };

  useEffect(() => {
    if (webView) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [webView]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    sideBarData.map((item, index) => {
      if (location.pathname === item.path) {
        setItemSelected(index);
      }
    });
  }, [location.pathname, sideBarData]);

  return (
    <>
      {!webView && (
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: isDrawerOpen
          })}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawer}
              className={clsx(classes.menuButton, isDrawerOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        style={
          isDrawerOpen
            ? { width: drawerWidth, backgroundColor: COLORS.defaultPage }
            : { backgroundColor: COLORS.defaultPage }
        }
        className={classes.drawer}
        variant={webView ? 'persistent' : 'temporary'}
        anchor="left"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        PaperProps={{ elevation: 0 }}
        onClose={handleDrawer}
      >
        <List
          style={{
            height: '100vh',
            position: 'relative'
          }}
        >
          {!webView && (
            <div className={classes.menuIcon}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="end"
                className={clsx(
                  classes.menuButton,
                  isDrawerOpen && classes.hide
                )}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <div className={classes.containerLogo}>
            <Logo className={classes.logo} />
          </div>

          {sideBarData.map((item, index) => (
            <div className={classes.centerItem} key={index}>
              <NavLink
                to={item.path}
                className={classes.link}
                onClick={
                  !webView
                    ? () => {
                        handleDrawer();
                      }
                    : null
                }
                activeClassName={classes.activeLink}
              >
                <ListItem button>
                  <ListItemIcon className={classes.iconItem}>
                    <img
                      src={
                        itemSelected !== index
                          ? `${item.icon}.png`
                          : `${item.icon}White.png`
                      }
                      alt=""
                      className={classes.icon}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </NavLink>
            </div>
          ))}
          <div className={classes.logoutIconContainer}>
            <NavLink
              to={ROUTES_PATHS.DRIVER_DASHBOARD}
              className={classes.link}
              onClick={onCloseSession}
            >
              <ListItem button className={classes.centerItem}>
                <ListItemIcon className={classes.iconItem}>
                  <FeatherIcon.FiLogOut className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={translation('driverDashBoard.navBar.itemLogout')}
                />
              </ListItem>
            </NavLink>
          </div>
        </List>
      </Drawer>
    </>
  );
}

export default NavBar;
