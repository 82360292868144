import React from 'react';
import { Card, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-multi-lang/lib';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import useUser from '../../../hooks/useUser';
import { useFacturationStyles } from './styles';

const Facturation = ({
  order,
  className,
  onlyContent = false,
  showWhatapp = false
}) => {
  const classes = useFacturationStyles();
  const [user] = useUser(order.user_id);
  const translation = useTranslation();

  if (!user) {
    return null;
  }

  const sendWhatsApp = () => {
    window.open(
      `${process.env.REACT_APP_WHATSAPP}${order.user_phone}?text=`,
      '_blank'
    );
  };

  const getContent = () => (
    <Box className={classes.content}>
      <Typography className={classes.text}>
        <strong>{translation('orders.details.billing.reason')}</strong>
        <span>&nbsp;&nbsp;</span>
        {order.invoice_name ? order.invoice_name : '-'}
      </Typography>

      <Typography className={classes.text}>
        <strong>{translation('orders.details.billing.nit')}</strong>
        <span>&nbsp;&nbsp;</span>
        {order.invoice_nit}
      </Typography>

      <Grid className={classes.containerPhoneNumber}>
        <Typography className={classes.textPhoneNumber}>
          <strong>{translation('orders.details.billing.user_phone')}</strong>
          <span>&nbsp;&nbsp;</span>
          {order.user_phone ? order.user_phone.substr(4) : '-'}
        </Typography>
        {order.user_phone && (
          <Button
            onClick={() => sendWhatsApp()}
            variant="contained"
            color="secondary"
            type="submit"
          >
            <WhatsAppIcon />
          </Button>
        )}
      </Grid>
    </Box>
  );

  if (onlyContent) {
    return getContent();
  }

  return (
    <Card className={clsx(classes.root)}>
      <Box className={classes.header}>
        <Typography className={clsx(classes.text, classes.noMarging)}>
          <strong>{translation('orders.details.billing.title')}</strong>
        </Typography>
      </Box>
      {getContent()}
    </Card>
  );
};

export default Facturation;
