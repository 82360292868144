import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Lottie from 'react-lottie';
import EntregasAnimation from '../assets/lotties/EntregasLoop.json';
import LineDoubleColor from '../assets/lotties/LineDoubleColor.json';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  },
  logo: {
    width: 200,
    maxWidth: '100%'
  }
}));

function SlashScreen() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: EntregasAnimation
          }}
          height={300}
          width={300}
        />
      </Box>
      <Box justifyItems="center" style={{ position: 'relative', top: -35 }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: LineDoubleColor
          }}
          width={200}
        />
      </Box>
    </div>
  );
}

export default SlashScreen;
