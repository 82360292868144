import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useWebView } from 'src/hooks/window/windowHooks';
import { COLORS_ENTREGAS_COM } from 'src/theme/colors';
import NavBar from './NavBar';

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    paddingTop: 64,
    width: '100%',
    height: '100vh',
    margin: 0,
    backgroundColor: theme.palette.background.dark
  },
  drawerHeaderMobile: {
    paddingTop: 64,
    width: '100%',
    height: '100vh',
    backgroundColor: COLORS_ENTREGAS_COM.white
  },
  container: {
    backgroundColor: theme.palette.background.dark
  },
  containerMobile: {
    backgroundColor: COLORS_ENTREGAS_COM.white
  }
}));

function DashboardDriver({ children }) {
  const classes = useStyles();
  const webView = useWebView();
  return (
    <div style={{ display: 'flex' }}>
      <NavBar />
      <div
        className={webView ? classes.drawerHeader : classes.drawerHeaderMobile}
      >
        <Container
          className={webView ? classes.container : classes.containerMobile}
        >
          {children}
        </Container>
      </div>
    </div>
  );
}

export default DashboardDriver;
