import React from 'react';
import { Dialog, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import IconButton from '../Inputs/IconButton';
import Icons from '../../assets/icons';

const useStyles = makeStyles(theme => ({
  modal: {
    padding: theme.spacing(2),
    width: '100%',
    borderRadius: '20px'
  },
  cardHeader: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingBottom: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

const ModalTemplateCustomHeader = ({
  show,
  children = <div />,
  onClose = () => {},
  size = 'md',
  CustomHeader,
  label,
  hideCloseIcon = false
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      maxWidth={size}
      BackdropProps={{
        style: {
          background: `transparent linear-gradient(180deg, ${theme.palette.modalBackground.primary} 100%, ${theme.palette.modalBackground.primaryDegradation} 100%) 0% 0% no-repeat padding-box`,
          opacity: 0.44
        }
      }}
      open={show}
      PaperProps={{
        style: {
          borderRadius: '20px'
        }
      }}
    >
      <Box className={classes.cardHeader}>
        {label && (
          <Typography>
            <strong>{label}</strong>
          </Typography>
        )}
        {CustomHeader && <CustomHeader />}
        {!hideCloseIcon && (
          <IconButton
            src={Icons.close}
            onClick={() => onClose()}
            component={Icon}
          />
        )}
      </Box>
      <Box className={classes.modal}>{children}</Box>
    </Dialog>
  );
};
export default ModalTemplateCustomHeader;
