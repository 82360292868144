import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  Grid
} from '@material-ui/core';
import Logo from 'src/components/LogoEntregasCom';
import { useCurrentUser } from '../../hooks/useUser';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 84,
    [theme.breakpoints.down('400')]: {
      height: 150
    }
  },
  logo: {
    marginRight: theme.spacing(2),
    width: '130px'
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  buttonContainer: {
    marginLeft: 20
  },
  buttonContained: {
    backgroundColor: theme.palette.expressColors.primary,
    color: theme.palette.expressColors.white,
    borderRadius: 0
  },
  buttonOutlined: {
    backgroundColor: theme.palette.expressColors.white,
    color: theme.palette.expressColors.primary,
    borderColor: theme.palette.expressColors.primary,
    borderRadius: 0
  },
  buttonText: {
    textTransform: 'none',
    fontWeight: '600',
    [theme.breakpoints.down('400')]: {
      marginTop: 10
    }
  },
  containerItem: {
    [theme.breakpoints.down('400')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  containerButtons: {
    [theme.breakpoints.down('400')]: {
      display: 'flex',
      justifyContent: 'flex-start'
    }
  }
}));

const TopBarCommerce = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { pathname } = useLocation();
  console.log('pathname', pathname);
  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.containerItem}>
          <Grid item xs={2} sm={6} md={6}>
            <RouterLink to="/admin">
              <Logo className={classes.logo} />
            </RouterLink>
          </Grid>
          <Grid
            container
            item
            xs={10}
            sm={6}
            md={6}
            justify="flex-end"
            className={classes.containerButtons}
          >
            {!currentUser && (
              <Grid item>
                <Box className={classes.buttonContainer}>
                  <Button
                    variant="outlined"
                    className={clsx(classes.buttonOutlined, classes.buttonText)}
                    onClick={() => history.push(`/driver_register`)}
                  >
                    Repartidor
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid item>
              <Box className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={clsx(classes.buttonContained, classes.buttonText)}
                  onClick={() => history.push(`/login`)}
                >
                  {!currentUser && <>Entrar</>}
                  {currentUser && <>Ir al Tablero</>}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

TopBarCommerce.propTypes = {
  className: PropTypes.string
};

export default TopBarCommerce;
