import DevEnv from './dev';
import TestEnv from './test';
import ProductionEnv from './production';

const Environments = {
  dev: DevEnv,
  test: TestEnv,
  production: ProductionEnv
};

export default Environments.production;
