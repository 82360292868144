import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',

    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const Delete = ({ handleConfirm, handleClose }) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('delete.title')}
        />
        <Divider />
        <CardContent>
          <Grid container justify="space-between" alignItems="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleConfirm}
            >
              {translation('button_delete')}
            </Button>
            <Button color="secondary" variant="contained" onClick={handleClose}>
              {translation('cancel')}
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Delete;
