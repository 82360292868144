import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  Grid,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { documentsStatus } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const EditDocument = ({ handleConfirm, handleClose, status, name }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [docName, setDocName] = useState(name || '');
  const [localStatus, setLocalStatus] = useState(status);

  const handleRadioChange = event => {
    setLocalStatus(event.target.value);
  };

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('modals.editFile.title')}
        />
        <Divider />
        <CardContent>
          <Grid item container xs={9} md={9}>
            <Box pb={3} pr={3} width="100%">
              <TextField
                fullWidth
                label={translation('modals.uploadFile.doc_name')}
                name="name"
                onChange={e => setDocName(e.target.value)}
                value={docName}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid>
            <RadioGroup
              aria-label="status"
              name="status"
              value={localStatus}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={documentsStatus.PENDING}
                control={<Radio />}
                label={translation('modal_common.edit_doc.pending')}
              />
              <FormControlLabel
                value={documentsStatus.ACCEPTED}
                control={<Radio />}
                label={translation('modal_common.edit_doc.accepted')}
              />
              <FormControlLabel
                value={documentsStatus.REJECTED}
                control={<Radio />}
                label={translation('modal_common.edit_doc.rejected')}
              />
            </RadioGroup>
          </Grid>
          <Box mt={3} width="100%">
            <Grid item container justify="center">
              <Box mr={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleConfirm(localStatus, docName)}
                >
                  {translation('modals.uploadFile.btn_save')}
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleClose(false)}
                >
                  {translation('cancel')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EditDocument;
