import { USER_ROLES } from '../../dto/enum';
import { capitalizeAllWord } from '../string';

const getUserRoles = (user, text = false) => {
  let roles = [];
  const r = user.roles;
  if (r) {
    if (r[USER_ROLES.OWNER]) {
      roles.push({
        value: USER_ROLES.OWNER,
        label: 'Propietario'
      });
    }
    if (r[USER_ROLES.CASHIER]) {
      roles.push({
        value: USER_ROLES.CASHIER,
        label: 'Cajero'
      });
    }
    if (r[USER_ROLES.SUPERVISOR])
      roles.push({
        value: USER_ROLES.SUPERVISOR,
        label: 'Supervisor'
      });
    if (r[USER_ROLES.SUPER_ADMIN])
      roles.push({
        value: USER_ROLES.SUPER_ADMIN,
        label: 'Super Admin'
      });
    if (r[USER_ROLES.ACCOUNTANT]) {
      roles.push({
        value: USER_ROLES.ACCOUNTANT,
        label: 'Contador'
      });
    }

    if (r[USER_ROLES.CUSTOMER_SERVICE]) {
      roles.push({
        value: USER_ROLES.CUSTOMER_SERVICE,
        label: 'Servicio al cliente'
      });
    }
    if (r[USER_ROLES.CUSTOMER_SERVICE_ADMIN]) {
      roles.push({
        value: USER_ROLES.CUSTOMER_SERVICE_ADMIN,
        label: 'Logistica atc'
      });
    }
    if (r[USER_ROLES.MARKETING]) {
      roles.push({
        value: USER_ROLES.MARKETING,
        label: 'Marketing'
      });
    }
  }

  if (text) {
    roles = roles
      .map(role => role.label)
      .join(', ')
      .trim(' ')
      .trim(',');
    return roles;
  }

  return roles.map(role => role.value);
};
export const getUserRole = user => {
  const r = getUserRoles(user);
  if (r.includes(USER_ROLES.SUPER_ADMIN)) return USER_ROLES.SUPER_ADMIN;
  if (r.includes(USER_ROLES.ACCOUNTANT)) return USER_ROLES.ACCOUNTANT;
  if (r.includes(USER_ROLES.CUSTOMER_SERVICE))
    return USER_ROLES.CUSTOMER_SERVICE;
  if (r.includes(USER_ROLES.CUSTOMER_SERVICE_ADMIN))
    return USER_ROLES.CUSTOMER_SERVICE_ADMIN;
  if (r.includes(USER_ROLES.OWNER)) return USER_ROLES.OWNER;
  if (r.includes(USER_ROLES.CASHIER)) return USER_ROLES.CASHIER;
  if (r.includes(USER_ROLES.SUPERVISOR)) return USER_ROLES.SUPERVISOR;
  if (r.includes(USER_ROLES.MARKETING)) return USER_ROLES.MARKETING;
};

export const isSuperAdmin = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.SUPER_ADMIN);
};
export const isAccountant = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.ACCOUNTANT);
};
export const isCustomerService = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.CUSTOMER_SERVICE);
};
export const isCustomerServiceAdmin = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.CUSTOMER_SERVICE_ADMIN);
};
export const isMarketingUser = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.MARKETING);
};
export const isDriver = (user = {}) => {
  return !!user?.is_driver;
};
export const isDriverAccepted = (user = {}) => {
  return user?.driver_information?.status === 'ACCEPTED';
};
export const isDriverInProcess = (user = {}) => {
  return user?.driver_information?.status === 'IN_PROCESS';
};
export const isDriverPending = (user = {}) => {
  return user?.driver_information?.status === 'APPLIED';
};
export const isDriverRejected = (user = {}) => {
  return user?.driver_information?.status === 'REJECTED';
};

export const isAdmin = user => {
  if (!user) return;
  return (
    isSuperAdmin(user) ||
    isAccountant(user) ||
    isCustomerService(user) ||
    isCustomerServiceAdmin(user) ||
    isMarketingUser(user)
  );
};

export const isATC = user => {
  if (!user) return;
  return isCustomerService(user) || isCustomerServiceAdmin(user);
};

export const isCashier = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.CASHIER);
};

export const isSupervisor = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.SUPERVISOR);
};

export const isOwner = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.OWNER);
};

export const blockCashierEdit = (user, restaurant) => {
  const userRole = isCashier(user);
  let blockUser = false;
  if (restaurant) blockUser = restaurant.block_cashier || false;
  return userRole && blockUser;
};

export const isMerchant = user => {
  if (!user) return false;
  return isCashier(user) || isSupervisor(user) || isOwner(user);
};

export const getUserFullName = (user = {}) =>
  capitalizeAllWord(`${user?.first_name || ''} ${user?.last_name || ''}`);
export default getUserRoles;
