import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  isDriverInProcess,
  isDriverPending,
  isDriverAccepted,
  isDriverRejected
} from '../utils/helpers/userHelper';
import { ROUTES_PATHS } from '../constants';
import { useFirebaseUser } from '../hooks/useUser';

function AuthLoginDriver({ children }) {
  const currentUser = useFirebaseUser();
  const account = useSelector(state => state.account);
  if (isDriverAccepted(account.user) || isDriverRejected(account.user)) {
    return <Redirect to={ROUTES_PATHS.DRIVER_DASHBOARD} />;
  }

  if (isDriverPending(account?.user)) {
    return (
      <Redirect to={`${ROUTES_PATHS.DRIVER_REGISTER}/${currentUser.uid}`} />
    );
  }
  if (isDriverInProcess(account?.user)) {
    return (
      <Redirect
        to={`${ROUTES_PATHS.DRIVER_REGISTER}/${currentUser.uid}/${currentUser.phoneNumber}`}
      />
    );
  }

  return children;
}

AuthLoginDriver.propTypes = {
  children: PropTypes.any
};

export default AuthLoginDriver;
