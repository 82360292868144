import Base from './base';
import { DRIVER_STATUS } from './enum';

/* eslint camelcase: 0 */
export default class User extends Base {
  constructor({
    id,
    enabled = true,
    created_at,
    updated_at,
    first_name,
    last_name,
    phone_number,
    email,
    is_driver = true,
    ci,
    location,
    profile_photo,
    roles = {},
    orders_completed = 0,
    availability = true,
    restaurant_id,
    subsidiary_id,
    addresses = [],
    device_id = [],
    city_id = '',
    current_device_id = '',
    delivering_status = DRIVER_STATUS.UNAVAILABLE,
    temporal_password = '',
    driver_rate_stats = {},
    driver_information = {},
    bank_account_type = '',
    bank_account = '',
    bank_name = ''
  } = {}) {
    super(id, enabled, created_at, updated_at);
    this.first_name = first_name;
    this.last_name = last_name;
    this.orders_completed = orders_completed;
    this.availability = availability;
    this.delivering_status = delivering_status;
    this.phone_number = phone_number;
    this.email = email;
    this.is_driver = is_driver;
    if (ci) this.ci = ci;
    if (location) this.location = location;
    if (profile_photo) this.profile_photo = profile_photo;
    if (roles) this.roles = roles;
    if (restaurant_id) this.restaurant_id = restaurant_id;
    if (subsidiary_id) this.subsidiary_id = subsidiary_id;
    if (addresses.length) this.addresses = addresses;
    this.device_id = device_id;
    this.city_id = city_id;
    this.current_device_id = current_device_id;
    this.temporal_password = temporal_password;
    this.driver_rate_stats = driver_rate_stats;
    this.driver_information = driver_information;
    this.bank_account_type = bank_account_type;
    this.bank_account = bank_account;
    this.bank_name = bank_name;
  }
}
