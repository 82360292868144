import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { COLLECTIONS } from '../constants';
import admin from '../config/firebaseConfig';
import User from '../dto/user';

const useUser = userId => {
  let query = [];
  if (userId) {
    query = [
      {
        collection: COLLECTIONS.USER,
        doc: userId,
        storeAs: `user_${userId}`
      }
    ];
  }
  useFirestoreConnect(query);
  const user = useSelector(state => state.firestore.ordered[`user_${userId}`]);
  if (user && userId) {
    return user;
  }
  return [new User({ first_name: '', last_name: '' })];
};

export function useCurrentUser() {
  return useSelector(state => state.account.user);
}

export default useUser;

export const useUserInRealTime = userId => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let subscription = () => {};
    if (userId) {
      subscription = admin
        .firestore()
        .collection(COLLECTIONS.USER)
        .doc(userId)
        .onSnapshot(userDB => {
          setUser(userDB?.data());
        });
    } else {
      setUser(null);
    }
    return () => subscription();
  }, [userId]);
  return user;
};
export function useFirebaseUser() {
  const [result, setResult] = useState(admin.auth().currentUser);
  useEffect(() => {
    return admin.auth().onAuthStateChanged(nextUser => setResult(nextUser));
  }, []);
  return result;
}
export const useSalesUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.SALES', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};

export const useMarketingUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.MARKETING', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};
export const useAccountantUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.ACCOUNTANT', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};

export const useSuperAdminUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.SUPER_ADMIN', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};
export const useUserBusinessExecutive = () => {
  const salesServices = useSalesUsersNative();
  const marketingUsers = useMarketingUsersNative();
  const accountantUsers = useAccountantUsersNative();
  const superAdminUsers = useSuperAdminUsersNative();
  return useMemo(() => {
    if (marketingUsers && superAdminUsers && salesServices && accountantUsers) {
      return [
        ...marketingUsers,
        ...superAdminUsers,
        ...salesServices,
        ...accountantUsers
      ];
    }
  }, [marketingUsers, superAdminUsers, salesServices, accountantUsers]);
};
