import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTES_PATHS } from '../constants';
import { isDriverAccepted } from '../utils/helpers/userHelper';

function AuthGuestDriver({ children }) {
  const account = useSelector(state => state.account);
  if (isDriverAccepted(account.user)) {
    return <Redirect to={ROUTES_PATHS.DRIVER_DASHBOARD} />;
  }

  return children;
}

AuthGuestDriver.propTypes = {
  children: PropTypes.any
};

export default AuthGuestDriver;
