import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import ModalTemplateCustomHeader from './ModalTemplateCustomHeader';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    width: '100%',
    p: 2
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold'
  },
  descriptionContainer: {
    width: '100%',
    p: 2
  },
  descriptionText: {
    textAlign: 'center'
  }
}));

const ModalInfo = ({
  isVisible,
  title,
  description,
  onClose = () => {},
  confirm = () => {},
  component
}) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };
  return (
    <ModalTemplateCustomHeader
      show={isVisible}
      onClose={handleClose}
      size="xs"
      label=" "
    >
      <Box sx={{ flex: 1 }}>
        <Paper square elevation={0} className={classes.container}>
          <Typography className={classes.title}>
            <strong>{title}</strong>
          </Typography>
        </Paper>
        <Box className={classes.descriptionContainer}>
          <Typography className={classes.descriptionText}>
            {description}
          </Typography>
        </Box>
        {component}
        <Box display="flex" justifyContent="center" mt={1.2}>
          <Button color="secondary" variant="contained" onClick={confirm}>
            Entendido
          </Button>
        </Box>
      </Box>
    </ModalTemplateCustomHeader>
  );
};

export default React.memo(ModalInfo);
