import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Drawer, Grid, makeStyles, SvgIcon } from '@material-ui/core';
import { Trash as TrashIcon } from 'react-feather';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.bulk
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function BulkOperations({ className, onDelete, open, selected, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Drawer
      anchor="bottom"
      open={open}
      PaperProps={{ elevation: 1 }}
      variant="persistent"
    >
      <div className={clsx(classes.root, className)} {...rest}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item md={12} xs={12}>
            <div className={classes.actions}>
              <Button onClick={() => onDelete(selected)}>
                <SvgIcon fontSize="small" className={classes.actionIcon}>
                  <TrashIcon />
                </SvgIcon>
                {translation('orders.details.new_order.dialog.reject.product')}{' '}
                ({selected.length})
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
}

BulkOperations.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.array.isRequired
};

BulkOperations.defaultProps = {
  className: '',
  onDelete: () => {},
  open: false
};

export default BulkOperations;
