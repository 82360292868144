/* eslint-disable camelcase */
import Client from 'src/dto/client';
import Order from 'src/dto/order';
import OrderProduct from 'src/dto/orderProduct';
import env from 'src/env';
import { DRIVER_STATUS, ORDER_STATUS, XPRESS_ORDER_TYPE } from '../dto/enum';
import calculateDistance from '../utils/helpers/distanceCalculator';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';
import axios from '../utils/axios';

export const searchUser = async (phone, dialCode, reseach = false) => {
  const userSnap = await admin
    .firestore()
    .collection(COLLECTIONS.USER)
    .where('phone_number', '==', phone)
    .get();
  if (userSnap.docs.length === 0 && !reseach) {
    let result = null;
    let find = false;
    if (!find) {
      const removeDial = `+${dialCode}`;
      const newPhoneSearch = phone.split(removeDial).join('');
      result = await searchUser(newPhoneSearch, dialCode, true);
      find = result.length !== 0;
    }
    if (!find) {
      const newPhoneSearch = phone.split('+').join('');
      result = await searchUser(newPhoneSearch, dialCode, true);

      find = result.length !== 0;
    }
    if (find) return result || null;
  }
  return userSnap.docs || null;
};

export const getUserByentregasCode = async entregasCode => {
  if (entregasCode) {
    const userData = await admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('entregas_code', '==', Number(entregasCode))
      .get();
    return userData.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  }
  return [];
};

export const updateUser = async (
  newUser,
  currentAddress,
  listDirection,
  isNew,
  location
) => {
  let findXpress = 1;
  let editUser = { ...newUser };
  const editCurrent = currentAddress;
  const address = [];
  listDirection.forEach(e => {
    if (e.name.includes('XPRESS')) findXpress += 1;
    if (e.isNew !== true) {
      if (e.id === currentAddress.id)
        address.push({
          ...currentAddress,
          location: new admin.firestore.GeoPoint(location.Pc, location.Vc)
        });
      else address.push({ ...e });
    }
  });
  editUser.addresses = address;
  if (currentAddress.isNew) {
    const id = firebaseService.randomId(COLLECTIONS.USER);
    delete editCurrent.isNew;
    editCurrent.id = id;
    editCurrent.name = `XPRESS-${findXpress}`;
    editCurrent.location = new admin.firestore.GeoPoint(
      location.Pc,
      location.Vc
    );
    editUser.addresses.push(editCurrent);
  }
  const userData = (
    await admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .doc(editUser.id)
      .get()
  ).data();
  const newAddresses = {};
  if (userData) {
    // eslint-disable-next-line no-shadow
    userData.addresses.forEach(currentAddress => {
      newAddresses[currentAddress.id] = currentAddress;
    });
  }
  // eslint-disable-next-line no-shadow
  editUser.addresses.forEach(currentAddress => {
    newAddresses[currentAddress.id] = currentAddress;
  });
  editUser.addresses = Object.values(newAddresses);
  const handleInformation = admin.firestore().collection(COLLECTIONS.USER);
  if (!isNew) {
    const { id } = editUser;
    editUser = new Client({ ...editUser, id });
    const dataToSave = {
      ...editUser,
      updated_at: admin.firestore.FieldValue.serverTimestamp(),
      id
    };
    delete dataToSave.created_at;
    await handleInformation.doc(id).update(dataToSave);
  } else {
    const id = firebaseService.randomId(COLLECTIONS.USER);
    editUser = new Client({ ...editUser, id });
    await handleInformation.doc(id).set({
      ...editUser,
      updated_at: admin.firestore.FieldValue.serverTimestamp(),
      created_at: admin.firestore.FieldValue.serverTimestamp(),
      only_xpress_motos: true,
      id
    });
  }
  return { ...editUser };
};

export const filterDealers = (dealers, city, settings) => {
  const [distanceConfiguration] = settings.filter(
    e => e.id === 'distance_configuration'
  );
  const newDealers = [];
  for (let i = 0; i < dealers.length; i++) {
    const d = dealers[i];
    let driverObj = d;
    if (!d.location || !city.location) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (
      d.delivering_status === DRIVER_STATUS.WAITING_FOR_ORDERS ||
      d.delivering_status === DRIVER_STATUS.GOING_TO_PICK ||
      d.delivering_status === DRIVER_STATUS.ON_PLACE ||
      d.delivering_status === DRIVER_STATUS.DELIVERING
    ) {
      const distance = calculateDistance(d, city);
      if (distance <= distanceConfiguration.NEAR_DISTANCE) {
        driverObj = {
          ...d,
          text: `${distance} mt`
        };
        newDealers.push(driverObj);
      }
    }
  }
  return newDealers;
};

export const priceCalculator = async (address, subsidiaryId) => {
  try {
    if (address.location) {
      const destinationMark = {
        lat: address.location.Pc,
        lng: address.location.Vc
      };
      const result = await axios.post(
        `${env.REACT_APP_XPRESS_API_URL}/price/getCalculatePrice`,
        {
          data: {
            lat: destinationMark.lat,
            lng: destinationMark.lng,
            subsidiary_id: subsidiaryId,
            is_xpress_motos_order: true
          }
        }
      );
      return result;
    }
    return 'error';
  } catch (error) {
    console.log(error);
    return 'error';
  }
};

export async function pricesCalculatorMultipleDestinations(
  locations,
  subsidiaryId
) {
  const result = await axios.post(
    `${env.REACT_APP_XPRESS_API_URL}/price/getCalculatePricesMultipleDestinations`,
    {
      data: {
        locations,
        subsidiary_id: subsidiaryId,
        is_xpress_motos_order: true
      }
    }
  );
  return result.data;
}

export const sendNewOrder = async (
  restaurant,
  subsidiary,
  deliveryLocation = {},
  user = {},
  deliveryInformation = {},
  total = 0,
  orderWithDestination = false,
  driverRestaurantNearby = false,
  driverNearby = false
) => {
  try {
    const order_id = firebaseService.randomId(COLLECTIONS.ORDER);
    const totalOrder = total || 0;
    const driver_nearby = driverNearby || false;
    const order = new Order(
      order_id,
      restaurant.id,
      subsidiary.id,
      user.id,
      '',
      totalOrder,
      deliveryInformation.subsidy,
      restaurant.name,
      subsidiary.name,
      subsidiary.city,
      subsidiary.address,
      deliveryLocation || {},
      subsidiary.geohash,
      subsidiary.location,
      deliveryLocation || {},
      deliveryInformation?.price || 0,
      user?.phone_number || '',
      orderWithDestination,
      driverRestaurantNearby,
      driver_nearby
    );

    const orderProduct = new OrderProduct('generic_product', total || 0);
    const call = admin.functions().httpsCallable(`orders/makeOrderXpress`);
    const result = await call({
      order,
      orderProducts: [orderProduct]
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return 'error';
  }
};

export const sendNewOrderMultipleDestinations = async (
  restaurant,
  subsidiary,
  destinationsAddress,
  totalPricesInformation
) => {
  try {
    const order_parent_id = firebaseService.randomId(COLLECTIONS.ORDER);
    const orderParent = {
      id: order_parent_id,
      restaurant_id: restaurant.id,
      subsidiary_id: subsidiary.id,
      xpress_order_type: XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_PARENT,
      status: ORDER_STATUS.ACCEPTED,
      delivery_distance: totalPricesInformation.distance,
      delivery_fee: totalPricesInformation.price,
      subsidy: 0
    };
    const ordersChild = destinationsAddress.map(destination => {
      const order_id = firebaseService.randomId(COLLECTIONS.ORDER);
      const totalOrder = 0;
      const deliveryLocation = {
        address: destination.address,
        city_id: destination.currentCityId,
        city_name: destination.currentCityName,
        location: {
          latitude: destination.currentLocation.Pc,
          longitude: destination.currentLocation.Vc
        }
      };
      const order = new Order(
        order_id,
        restaurant.id,
        subsidiary.id,
        '',
        destination.name,
        totalOrder,
        0,
        restaurant.name,
        subsidiary.name,
        subsidiary.city,
        subsidiary.address,
        deliveryLocation,
        subsidiary.geohash,
        subsidiary.location,
        deliveryLocation || {},
        0,
        destination.phoneNumber || '',
        '',
        '',
        '',
        destination.travelInformation.distance,
        destination.order_delivery_factor,
        order_parent_id,
        XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_CHILD
      );
      const orderProduct = new OrderProduct('generic_product', 0);
      return {
        order,
        orderProducts: [orderProduct]
      };
    });
    const call = admin
      .functions()
      .httpsCallable(`orders/makeOrderMultipleDestinations`);
    const result = await call({
      order_parent_details: orderParent,
      orders: ordersChild
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return 'error';
  }
};

export const sendLimitCall = async subsidiary => {
  try {
    const route = 'users/getLimitCall';
    const subsidiariesFunction = admin.functions().httpsCallable(route);
    const result = await subsidiariesFunction({ subsidiaryId: subsidiary.id });
    return result.data;
  } catch (error) {
    console.log(error);
    return 'error';
  }
};

export const resetLimitCall = async subsidiaryId => {
  admin
    .firestore()
    .collection(COLLECTIONS.SUBSIDIARY)
    .doc(subsidiaryId)
    .update({
      call_xpress: 0,
      updated_at: admin.firestore.FieldValue.serverTimestamp()
    });
};

export const reoderAddress = async (user, deliveryLocation) => {
  const index = user.addresses.findIndex(
    // eslint-disable-next-line camelcase
    item => item.id === deliveryLocation.id
  );
  const newAddresses = user.addresses;
  const aux = newAddresses[0];
  newAddresses[0] = deliveryLocation;
  newAddresses[index] = aux;
  await admin
    .firestore()
    .collection(COLLECTIONS.USER)
    .doc(user.id)
    .update({
      addresses: newAddresses,
      updated_at: admin.firestore.FieldValue.serverTimestamp()
    });
};
