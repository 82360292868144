/* eslint-disable import/prefer-default-export */
import admin from '../config/firebaseConfig';
import { COLLECTIONS, MESSAGE_TYPE } from '../constants';
import FirebaseService from './firebaseService';
import Chat from '../dto/chat';
import Message from '../dto/message';

export const getChat = async chatId => {
  const chatSnap = await admin
    .firestore()
    .collection(COLLECTIONS.CHAT)
    .doc(chatId)
    .get();
  return chatSnap.data() || null;
};

export const createChat = async ({ chat, user, owner, restaurantPhoto }) => {
  const id = FirebaseService.randomId();
  const newChat = new Chat({
    id,
    ...chat,
    receiver_id: [owner.id],
    last_message_viewers: [user.id],
    create_from: 'merchant',
    restaurant_photo: restaurantPhoto
  });

  if (user.id !== owner.id) newChat.receiver_id.push(user.id);

  await admin
    .firestore()
    .collection(COLLECTIONS.CHAT)
    .doc(id)
    .set({ ...newChat });
  return getChat(id);
};

export const createMessage = async (user, message, chatId) => {
  if (!user || !message || !chatId) return false;
  const id = FirebaseService.randomId();
  const newMessage = new Message({
    id,
    chat_id: chatId,
    sender_id: user.id,
    message,
    sender_name: user.first_name,
    sender_profile_photo: user.profile_photo ? user.profile_photo.x40 : '',
    create_from: 'merchant',
    sender_roles: user.roles,
    type: MESSAGE_TYPE.TEXT
  });

  return admin
    .firestore()
    .collection(COLLECTIONS.CHAT)
    .doc(chatId)
    .collection(COLLECTIONS.MESSAGE)
    .doc(id)
    .set({ ...newMessage });
};

export const createImageMessage = async (user, fileImage, chatId) => {
  if (!user || !fileImage || !chatId) return false;
  const id = FirebaseService.randomId();
  const imageRef = admin.storage().ref(`chat_images/${id}`);
  await imageRef.put(fileImage);
  const urlImage = await imageRef.getDownloadURL();
  const newMessage = new Message({
    id,
    chat_id: chatId,
    sender_id: user.id,
    urlImage,
    sender_name: user.first_name,
    sender_profile_photo: user.profile_photo ? user.profile_photo.x40 : '',
    create_from: 'admin',
    sender_roles: user.roles,
    type: MESSAGE_TYPE.IMAGE
  });

  return admin
    .firestore()
    .collection(COLLECTIONS.CHAT)
    .doc(chatId)
    .collection(COLLECTIONS.MESSAGE)
    .doc(id)
    .set({ ...newMessage });
};

export const updateChat = async (id, updateFields) => {
  return admin
    .firestore()
    .collection(COLLECTIONS.CHAT)
    .doc(id)
    .update({
      ...updateFields,
      updated_at: admin.firestore.FieldValue.serverTimestamp()
    });
};

export const lastMessageViewrs = async (id, updateFields) => {
  return admin
    .firestore()
    .collection(COLLECTIONS.CHAT)
    .doc(id)
    .update({
      ...updateFields
    });
};
