import { makeStyles } from '@material-ui/core';

export const useOrdersCockpit = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginRight: '15px',
    color: theme.palette.expressColors.black,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
  sectionIcon: {
    width: 27,
    height: 27,
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20
    }
  },
  listItem: {
    cursor: 'pointer'
  },
  collapsable: {
    padding: '0 16px'
  },
  cockpitContainer: {
    backgroundColor: theme.palette.expressColors.ordersCockpitBackground,
    overflow: 'scroll',
    paddingTop: theme.spacing(2)
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.expressColors.gray
  },
  activeChip: {
    backgroundColor: theme.palette.expressColors.primaryDark,
    fontWeight: 'bold'
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  link: {
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.expressColors.yellowLink
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.expressColors.yellowLink
  }
}));

export const useListOrderCardStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer'
  },
  cardContent: {
    padding: `${theme.spacing(1)}px !important`
  },
  greenText: {
    color: theme.palette.expressColors.green
  },
  redText: {
    color: theme.palette.expressColors.red
  },
  orderText: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
  activeText: {
    color: theme.palette.expressColors.strongGreen
  },
  contentText: {
    fontSize: 14
  },
  activeTextInfo: {
    color: theme.palette.expressColors.white
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  orderType: {
    height: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.expressColors.lightGreen,
    padding: theme.spacing(1),
    minWidth: 50,
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3)
    }
  },
  smallCardsText: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  card: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.expressColors.gray
    }
  },
  activeCard: {
    cursor: 'pointer',
    backgroundColor: theme.palette.expressColors.orderCockpitActiveCard
  },
  playIcon: {
    color: theme.palette.expressColors.orderCockpitActiveCard,
    position: 'absolute',
    height: 60,
    width: 40,
    top: '15%',
    left: '96%',
    [theme.breakpoints.down('sm')]: {
      left: '95%'
    }
  },
  onGoingPlayIcon: {
    top: '23%'
  },
  rigthContainer: {
    display: 'flex',
    marginBottom: '-45px',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column'
  },
  chipsContainer: {
    position: 'absolute',
    display: 'flex',
    right: '2%'
  },
  chispsContainerCompleted: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end'
  },
  timeText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.expressColors.orderCardGreenText
  }
}));
