// eslint-disable-next-line max-classes-per-file
class Exception extends Error {
  constructor(code = '', message = '') {
    super(message);
    this.code = code;
  }
}

export const TimeoutException = class extends Exception {
  constructor() {
    super('TIME_OUT', 'TIME_OUT');
  }
};
