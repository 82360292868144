// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import admin from 'src/config/firebaseConfig';
import { COLLECTIONS } from 'src/constants';

const db = admin.firestore();

const useSettings = () => {
  const [appInfo, setAppInfo] = useState(null);
  useEffect(() => {
    db.collection(COLLECTIONS.SETTINGS)
      .doc('app_info')
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          setAppInfo(data);
        }
      });
  }, []);
  return appInfo;
};

export const useSettingsDocuments = () => {
  const [documents, setDocuments] = useState(null);
  useEffect(() => {
    db.collection(COLLECTIONS.SETTINGS)
      .doc('documents')
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          setDocuments(data);
        }
      });
  }, []);
  return documents;
};

export default useSettings;
