/* eslint-disable no-param-reassign */

const initialState = {
  schedule: {}
};

export default function scheduleReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
