import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-multi-lang/lib';
import { withStyles } from '@material-ui/core/styles';
import useUser from '../../../hooks/useUser';
import OrderExtraInfo from './OrderExtraInfo';
import ProductTable from './Table/ProductTable';
import { PAYMENT_METHOD, ORDER_STATUS } from '../../../dto/enum';
import { usePromotion } from '../../../hooks/Promotions/usePromotions';

import { useOrderProductsTableStyles } from './styles';

const ExpansionPanel = withStyles({
  root: {
    marginTop: -5,
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
})(MuiExpansionPanel);
function OrderProductsTable({
  order,
  showExtraInfo = false,
  canEdit = false,
  headerCaption,
  extraHeader,
  rejectProducts = () => {},
  rejectOrder = () => {},
  children,
  className,
  adminView,
  showExtraHeaderInfo = false,
  displayDescription = true,
  ...rest
}) {
  const classes = useOrderProductsTableStyles();
  const translations = useTranslation();
  const [user] = useUser(order?.user_id);
  const promotion = usePromotion(order?.promotionId);
  const isPaid =
    order.payment_method !== PAYMENT_METHOD.CASH ||
    order.status === ORDER_STATUS.COMPLETED ||
    order.status === ORDER_STATUS.DELIVERING;
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  if (!user && !adminView) {
    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={2} />
      </Card>
    );
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={isXS ? null : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.expansionHeader}
        >
          <Box width="100%">
            <Grid className={classes.headerContainer}>
              <Grid item container>
                {user && (
                  <Typography className={clsx(classes.orderFromText)}>
                    {translations('orders.details.order_table.order_for')}
                    <span>&nbsp;&nbsp;</span>
                    <strong>
                      {user.first_name || '-'} {user.last_name || ''}
                    </strong>
                  </Typography>
                )}
                {!user && (
                  <Typography className={clsx(classes.orderFromText)}>
                    User Deleted
                  </Typography>
                )}
              </Grid>
              <Box className={classes.payment}>
                <Typography className={clsx(classes.paymentCard)}>
                  {translations(
                    `orders_view.card.${
                      isPaid ? 'payment_done' : 'payment_pending'
                    }`
                  )}
                </Typography>
              </Box>
            </Grid>
            <Box width="100%">{headerCaption}</Box>
            {extraHeader}
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <Box width="100%">
            {showExtraHeaderInfo && (
              <Box ml={2}>
                <OrderExtraInfo order={order} />
              </Box>
            )}
            <ProductTable
              order={{ ...order, promotion }}
              canEdit={canEdit}
              rejectOrder={rejectOrder}
              rejectProducts={rejectProducts}
            />
            {displayDescription && order.additional_description && !adminView && (
              <>
                <Box mt={2} mb={2}>
                  <Typography variant="h6" component="h6">
                    {translations('orders.details.additional_reference.title')}
                  </Typography>
                  <Typography variant="body1">
                    {order.additional_description}
                  </Typography>
                </Box>
              </>
            )}
            {children}
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
}

OrderProductsTable.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderProductsTable;
