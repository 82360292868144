import moment from 'moment';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import { getNumberDecimalFixed } from '../utils/numberUtils';
import { ORDER_TYPE } from '../dto/enum';
import { getFromTimeStamp, getToTimeStamp } from './helpers/dateHelpers';
import axios from '../utils/axios';
import env from '../env';

const COLLECTION = COLLECTIONS.ORDER;
const { ORDER_INVITE } = COLLECTIONS;

// eslint-disable-next-line import/prefer-default-export
export const removeOrderInvites = async orderId => {
  const orderInvitesSnap = await admin
    .firestore()
    .collection(ORDER_INVITE)
    .where('order_id', '==', orderId)
    .get();

  // eslint-disable-next-line no-restricted-syntax
  for (const snap of orderInvitesSnap.docs) {
    // eslint-disable-next-line no-await-in-loop
    await snap.ref.delete();
  }
};

export const updateOrder = async (id, updateFields) => {
  const userId = admin.auth().currentUser.uid;
  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .update({
      ...updateFields,
      updated_at: admin.firestore.FieldValue.serverTimestamp(),
      last_modifier_id: userId
    });
};

export const updateOrderModalNotificationDriver = async (id, updateFields) => {
  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .update({
      ...updateFields
    });
};

export const sentInvitesToDrivers = async orderId => {
  const inviteSender = admin
    .functions()
    .httpsCallable('orderInvite/sendBroadcastInvite');
  return inviteSender({ order_id: orderId });
};

export const getGeoPointsByUrl = async url => {
  const inviteSender = admin.functions().httpsCallable('users/getGeoPointsUrl');
  return inviteSender({ urlMap: url });
};

export const getOrderTotal = (products = [], simpleCalculation = false) => {
  let total = 0;
  if (products && products.length) {
    products
      .filter(p => p.available)
      .forEach(p => {
        const quantity = p.quantity ? p.quantity : 1;
        const price = simpleCalculation
          ? p.unit_price
          : p.unit_price * quantity;
        total += price;
      });
  }
  return getNumberDecimalFixed(total, 2);
};

export const getProductSimplePrice = product => {
  if (
    product &&
    product.unit_price &&
    product.selected_options &&
    product.selected_options.length
  ) {
    let price = product.unit_price;
    // eslint-disable-next-line no-unused-vars
    product.selected_options.forEach((o, i) => {
      price -= +o.price;
    });
    return price;
  }
  return product.unit_price;
};

export const roundTwoDecimals = number => {
  if (number) {
    const num = Number(number);
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  return 0;
};
export const roundWithOutDecimals = number => {
  if (number) {
    const num = Number(number);
    return parseInt(num, 10);
  }
  return 0;
};

export const filteredOrders = async (
  {
    city,
    restaurant,
    subsidiary,
    year,
    month,
    promo,
    statusOrder,
    complexDate,
    driver,
    fromDate,
    toDate,
    paid
  },
  listOrder = false
) => {
  let response = {
    error: true,
    message: 'invalid filter',
    payload: []
  };
  let noCall = false;
  if (!city || !restaurant || !year || !month || city === '') return response;
  const fromTimeStamp =
    fromDate !== ''
      ? moment(fromDate)
          .startOf('day')
          .toDate()
      : getFromTimeStamp(year, month, complexDate);
  let plusMonth = `${month * 1 + 1}`;
  if (plusMonth * 1 === 13) {
    // eslint-disable-next-line no-param-reassign
    year += 1;
    plusMonth = 1;
  }
  const toTimestamp =
    toDate !== ''
      ? moment(toDate)
          .endOf('day')
          .toDate()
      : getToTimeStamp(year, plusMonth, complexDate);
  const yesterday = moment()
    .startOf('day')
    .subtract(1, 'days')
    .toDate();
  if (listOrder && fromTimeStamp >= yesterday) noCall = true;
  if (!noCall) {
    let query = admin.firestore().collection(COLLECTIONS.ORDER);
    if (city) query = query.where('subsidiary_city.id', '==', city);

    if (restaurant !== 'all')
      query = query.where('restaurant_id', '==', restaurant);

    if (subsidiary !== 'all')
      query = query.where('subsidiary_id', '==', subsidiary);

    if (promo !== 'all') query = query.where('promotionId', '==', promo);

    if (driver !== '' && driver !== 'all')
      query = query.where('driver_id', '==', driver);

    if (statusOrder && statusOrder !== '')
      query = query.where('status', '==', statusOrder);

    if (paid && paid !== '-') {
      const orderPaid = paid === '1' || false;
      query = query.where('order_paid', '==', orderPaid);
    }

    query = query
      .orderBy('created_at')
      .startAt(new Date(fromTimeStamp))
      .endAt(new Date(toTimestamp));

    const orders = await query.get();

    response = {
      error: false,
      message: 'success',
      payload: orders.docs.map(doc => ({ ...doc.data() }))
    };
    return response;
  }
  // use the snapshoot param  from adminOrders if use today and yesterday only for reports
  let filterList = listOrder.orders;
  if (city)
    filterList = filterList.filter(value => value.subsidiary_city.id === city);

  if (restaurant !== 'all')
    filterList = filterList.filter(value => value.restaurant_id === restaurant);

  if (subsidiary !== 'all')
    filterList = filterList.filter(value => value.subsidiary_id === subsidiary);

  if (promo !== 'all')
    filterList = filterList.filter(value => value.promotionId === promo);

  if (driver !== '' && driver !== 'all')
    filterList = filterList.filter(value => value.driver_id === driver);

  if (statusOrder && statusOrder !== '')
    filterList = filterList.filter(value => value.status === statusOrder);

  if (paid && paid !== '-') {
    const orderPaid = paid === '1' || false;
    filterList = filterList.filter(value => value.orderPaid === orderPaid);
  }

  if (fromTimeStamp !== yesterday)
    filterList = filterList.filter(
      value => new Date(value.created_at.seconds * 1000) >= fromTimeStamp
    );
  filterList = filterList.sort((a, b) => b.created_at - a.created_at);
  response = {
    error: false,
    message: 'success',
    payload: filterList
  };
  return response;
};

export const getOrder = async id => {
  const orderSnap = await admin
    .firestore()
    .collection(COLLECTIONS.ORDER)
    .doc(id)
    .get();
  return orderSnap.data();
};

export const getTodayOrders = async () => {
  let yesterday = moment();
  yesterday = yesterday.subtract(1, 'days');
  return admin
    .firestore()
    .collection(COLLECTION)
    .where('created_at', '>', yesterday.toDate())
    .where('order_type', '==', ORDER_TYPE.DELIVERY)
    .orderBy('created_at', 'desc')
    .get()
    .then(query => {
      const items = [];
      query.forEach(doc => items.push({ ...doc.data(), id: doc.id }));
      return items;
    });
};

export const getNearByDriversByRoute = async subId => {
  const endpoint = `/priceDeliveryCalculator/getNearByDrivers?subsidiaryId=${subId}`;
  const response = await axios.get(endpoint);
  return response;
};

export const getInvoiceOrders = async chargedOrders => {
  const endpoint = 'invoice/getInvoicedOrders';
  const fetchInvoicedOrders = admin.functions().httpsCallable(endpoint);
  const orders = await fetchInvoicedOrders({ chargedOrders });
  return orders.data;
};

export async function getSalesReportsByFilters(filters) {
  const result = await axios.post('reports/getSalesReportsByFilters', {
    data: { filters }
  });
  if (result.data.isSucceeded) {
    return result.data.data;
  }
  return null;
}

export const calculateRoutesMultipleDestinations = async data => {
  const result = await axios.post(env.REACT_APP_VROOM_URL, { ...data });
  if (result.status === 200) {
    const dataSteps = result.data.routes[0].steps;
    dataSteps.pop();
    console.log('stepsVROOM', dataSteps);
    return dataSteps;
  }
  throw new Error('Ocurrio un error con el servicio de calculo de ruta');
};
