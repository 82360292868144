import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { COLORS } from '../../theme/colors';

const TextFieldOrange = withStyles(theme => ({
  root: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: COLORS.primary,
      borderWidth: 1
    },
    '& .MuiInputLabel-formControl': {
      // color: '#eaeaea',
      borderWidth: 1
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.primary,
      borderWidth: 1
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 13,
      '& fieldset': {
        borderColor: COLORS.primary,
        borderWidth: 1
      },
      '&:hover fieldset': {
        borderColor: COLORS.primary,
        borderWidth: 1
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.primary,
        borderWidth: 1
      }
    },
    [theme.breakpoints.down('969')]: {
      '& .MuiOutlinedInput-root': {
        borderRadius: 18
      }
    }
  }
}))(TextField);

export default TextFieldOrange;
