import { useContext } from 'react';
import SettingsContext from 'src/context/SettingsContext';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../constants';

export default function useSettings() {
  const context = useContext(SettingsContext);

  return context;
}

export const useActualSettings = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SETTINGS
    }
  ]);
  const settings = useSelector(state => state.firestore.ordered.settings || []);
  return settings;
};
