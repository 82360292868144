import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-multi-lang/lib';
import { useSubsidiaryId } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { ORDER_TYPE } from '../../../dto/enum';

const OrderExtraInfo = ({ order }) => {
  const translations = useTranslation();
  const subsidiary = useSubsidiaryId(order.subsidiary_id);
  let deliveryText = 'PICK UP';
  if (order.order_type === ORDER_TYPE.DELIVERY) deliveryText = 'DELIVERY';
  if (order.order_type === ORDER_TYPE.XPRESS_MOTOS) deliveryText = 'Entregas';
  return (
    <Box mb={2}>
      {order.restaurant_name && (
        <Box>
          <Typography variant="h6" display="inline">
            {translations('orders.details.order_restaurant')}
            {': '}
          </Typography>
          <Typography variant="body2" display="inline" color="textSecondary">
            {order.restaurant_name}
            {subsidiary && subsidiary.name ? `(${subsidiary.name})` : ''}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant="h6" display="inline">
          {translations('orders.details.type')}
          {': '}
        </Typography>
        <Typography variant="body2" display="inline" color="textSecondary">
          {deliveryText}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderExtraInfo;
