const DevEnv = {
  googleAnalytics: 'UA-236246807-1',
  driverTermsAndConditions: 'https://xpress-dev.web.app/driver-agreement',
  REACT_APP_VROOM_URL: 'https://vroom-2enzqqbxva-uc.a.run.app',
  REACT_APP_XPRESS_API_URL: 'https://xpress-api-2enzqqbxva-uc.a.run.app',
  REACT_APP_WHATSAPP: 'https://wa.me/',
  REACT_APP_XPRESS_SUPPORT: '+59162093356'
};

export default DevEnv;
