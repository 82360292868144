import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { COLORS_ENTREGAS_COM } from 'src/theme/colors';

const BasicTextField = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.expressColors.primaryLight
    },
    '& label.Mui-error': {
      color: theme.palette.expressColors.red
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.expressColors.ligthYellow,
        borderWidth: 2
      }
    }
  }
}))(TextField);

const YellowTextField = props => {
  return <BasicTextField {...props} />;
};

const TextFieldBasic = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: COLORS_ENTREGAS_COM.secondary
    },
    '& label.Mui-error': {
      color: theme.palette.expressColors.red
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS_ENTREGAS_COM.secondary,
        borderWidth: 2
      }
    },
    '& .MuiInput-icon': {
      color: COLORS_ENTREGAS_COM.secondary
    }
  }
}))(TextField);

export const DriverTextField = props => {
  return <TextFieldBasic {...props} />;
};

export const DateInput = props => {
  return (
    <TextFieldBasic
      id="date"
      variant="outlined"
      type="date"
      InputLabelProps={{
        shrink: true
      }}
      {...props}
    />
  );
};
export default YellowTextField;
