// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  SvgIcon,
  IconButton as IconButtonMUI,
  makeStyles
} from '@material-ui/core';
import { COLORS } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.successDark,
    margin: 8,
    borderRadius: 50
  }
}));
const IconButton = ({
  to,
  icon,
  src,
  onClick = () => {},
  size = 20,
  component = RouterLink
}) => {
  const classes = useStyles();
  return (
    <IconButtonMUI
      style={{ width: size, height: size }}
      className={classes.button}
      component={component}
      to={to}
      onClick={onClick}
    >
      {src && (
        <img style={{ width: size, height: size }} src={src} alt="order_icon" />
      )}
      {icon && (
        <SvgIcon style={{ width: size, height: size }} fontSize="small">
          {icon}
        </SvgIcon>
      )}
    </IconButtonMUI>
  );
};
export default IconButton;
