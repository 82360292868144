import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TopBar from './TopBar';
import Sounds from '../../assets/sounds/sounds';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
    /* [theme.breakpoints.up('lg')]: {
      paddingLeft: 280
    } */
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const alert = new Audio(Sounds.alert);
alert.loop = false;
function DocsLayout({ children }) {
  const user = useSelector(state => state.account.user);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar user={user} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

DocsLayout.propTypes = {
  children: PropTypes.any
};

export default DocsLayout;
