import moment from 'moment';

export const getFromTimeStamp = (year, month, complexDate) => {
  if (complexDate === '-' || complexDate === '') {
    const date = new Date(moment(`${year}-${month}`).format('YYYY/MM/DD'));
    return date;
  }

  const days = complexDate * 1;
  if (days)
    return new Date(
      moment()
        .startOf('day')
        .subtract(days, 'days')
        .toDate()
    );
  return new Date(moment().startOf('day'));
};

export const getToTimeStamp = (year, month, complexDate) => {
  if (complexDate === '-' || complexDate === '')
    return new Date(moment(`${year}-${month}`).format('YYYY/MM/DD'));

  const days = complexDate * 1;
  if (days === 1) {
    return new Date(
      moment()
        .endOf('day')
        .subtract(days, 'days')
    );
  }
  return new Date(moment().endOf('day'));
};

export const getDiffDay = summaryInvoice => {
  const today = moment();
  let diferenceMonth = '-';
  let diferenceDays = '-';
  if (summaryInvoice.last_month_paid) {
    let previewDate =
      moment(summaryInvoice.last_month_paid.seconds * 1000).startOf('days') ||
      moment();
    const onDay =
      moment(summaryInvoice.last_limit_day.seconds * 1000).startOf('days') ||
      moment();
    const diffDay = onDay.diff(previewDate, 'month');
    if (diffDay === 1) previewDate = onDay;
    diferenceMonth = today.diff(previewDate, 'month');
    diferenceDays = today.diff(previewDate, 'days');
    if (diffDay === 0) {
      diferenceMonth = 0;
      diferenceDays = 0;
    }
  }

  return { diferenceMonth, diferenceDays };
};
