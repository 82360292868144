/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Dialog,
  makeStyles,
  IconButton,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { COLORS_ENTREGAS_COM } from '../../../theme/colors';
import Icons from '../../../assets/icons';
import FileUtils from '../../../utils/fileUtils';
import { MAX_COMMERCE_IMAGES_SIZE } from '../../../constants';
import { GreenCrossButton } from '../../../components/Inputs/Buttons';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 24px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('969')]: {
      margin: '0 16px 16px 16px'
    }
  },
  containerUpload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    width: 851,
    height: 315,
    backgroundColor: COLORS_ENTREGAS_COM.backgroundLightGray,
    [theme.breakpoints.down('969')]: {
      width: 280,
      height: 120,
      padding: 7
    }
  },
  img: {
    border: `5px solid ${COLORS_ENTREGAS_COM.secondary}`,
    width: 270,
    height: 270,
    borderRadius: '50%',
    [theme.breakpoints.down('969')]: {
      width: 82,
      height: 82
    }
  },
  imgDefault: {
    width: 120,
    height: 120,
    [theme.breakpoints.down('969')]: {
      width: 36,
      height: 36
    }
  },
  button: {
    color: COLORS_ENTREGAS_COM.white,
    background: COLORS_ENTREGAS_COM.primary,
    textTransform: 'none',
    marginTop: 32,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: COLORS_ENTREGAS_COM.primaryLight
    },
    '&:disabled': {
      background: COLORS_ENTREGAS_COM.lightGray
    },
    [theme.breakpoints.down('969')]: {
      marginTop: 24,
      paddingLeft: 24,
      paddingRight: 24
    }
  },
  text: {
    fontSize: 12,
    color: COLORS_ENTREGAS_COM.textLightGray
  },
  input: {
    display: 'none'
  }
}));

const UploadBannerModal = ({
  isOpen,
  matches,
  handleClose,
  imagenSelect,
  maxDimensions,
  validImageExtensions,
  onChange
}) => {
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [dimensionError, setDimensionError] = useState([]);

  const setDataImage = e => {
    setDimensionError([]);
    const messageDimensions = FileUtils.validateImageSizeAndFormat(
      e.target.files[0],
      maxDimensions,
      MAX_COMMERCE_IMAGES_SIZE,
      validImageExtensions
    );
    if (messageDimensions.length === 0) {
      setImage(e.target.files[0]);
    } else {
      setDimensionError(messageDimensions);
    }
  };

  const onPressSavePicture = () => {
    onChange({ image, url: URL.createObjectURL(image) });
    handleClose();
    setImage(null);
  };
  const imagenSelectUrl = imagenSelect || '';

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 20, width: matches ? 900 : 300 }
      }}
    >
      <Box display="flex" justifyContent="right">
        <GreenCrossButton onClick={handleClose} />
      </Box>
      <Box className={classes.container}>
        <input
          accept="image/jpg, image/jpeg"
          className={classes.input}
          id="banner_photo"
          type="file"
          onChange={setDataImage}
        />
        <label htmlFor="banner_photo">
          <Box
            className={classes.containerUpload}
            style={{
              backgroundImage: `url(${
                image ? URL.createObjectURL(image) : imagenSelectUrl
              })`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <Box className={image || imagenSelectUrl ? classes.input : null}>
              <IconButton
                className={classes.img}
                component="span"
                variant="text"
              >
                <img
                  src={Icons.addPhoto}
                  className={classes.imgDefault}
                  alt="upload_banner"
                />
              </IconButton>
            </Box>
          </Box>
        </label>
        <Box mt={2}>
          <Typography className={classes.text}>
            Tamaño ideal : 810x315 px / max 10mb
          </Typography>
        </Box>
        {dimensionError.length > 0 && (
          <Box m={1}>
            <Alert severity="error">
              Por favor verificar :
              <ul>
                {dimensionError.map(e => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </Alert>
          </Box>
        )}
        <Box>
          <Button
            className={classes.button}
            disabled={!image || dimensionError.length > 0}
            onClick={onPressSavePicture}
          >
            Aceptar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UploadBannerModal;
