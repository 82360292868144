import React, { useState } from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import OrderProductsTable from './OrderProductsTable';
import Header from './Header';
import { ORDER_STATUS } from '../../../dto/enum';
import TimeSetter from './TimeSetter';
import Facturation from './Facturation';
import DriverInformation from './DriverInformation';
import {
  COLLECTIONS,
  REJECT_ORDER_REASON,
  REJECT_PRODUCT_REASON
} from '../../../constants';
import WithLoading from '../../../components/WithLoading';
import AlertDialog from '../../../components/Dialog/AlertDialog';
import OneButtonDialog from '../../../components/Dialog/OneButtonDIalong';
import { getOrderNumber } from '../../../hooks/Order/useOrders';
import useProductsPerOrder from '../../../hooks/Order/useProductsPerOrder';
import { getOrderTotal, updateOrder } from '../../../services/orderServices';
import Description from './Description';
import NewOrderCockpit from './cockpit/NewOrder';
import { getNumberDecimalFixed } from '../../../utils/numberUtils';

const useStyles = makeStyles(theme => ({
  orderContainer: {
    backgroundColor: theme.palette.expressColors.white,
    padding: '15px 25px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  tableContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important'
    }
  }
}));

const BoxLoading = WithLoading(Box);

const NewOrder = ({ order, modal = false, isNotification, handleClose }) => {
  const history = useHistory();
  const classes = useStyles();
  const firestore = useFirestore();
  const products = useProductsPerOrder(order.id);
  const [loading, setLoading] = useState(false);
  const [preparingTime, setPreparingTime] = useState(null);
  const [confirmation, setConfirmation] = useState('');
  const [canceledProducts, setCanceledProducts] = useState([]);
  const [rejectProductOpen, setRejectProductOpen] = useState(false);
  const [rejectProductReason, setRejectProductReason] = useState('');
  const [confirmationOrder, setConfirmationOrder] = useState('');
  const [rejectOrderOpen, setRejectOrderOpen] = useState(false);
  const [rejectOrderReason, setRejectOrderReason] = useState('');
  const translation = useTranslation();

  const orderInProcess = async () => {
    setLoading(true);
    const deliveryTime = moment(new Date());
    deliveryTime.add(preparingTime, 'm');
    const { discount } = order;
    const total =
      getOrderTotal(products, order.simple_calculation || false) -
      (discount || 0);
    const newTotal =
      total <= 0
        ? getNumberDecimalFixed(order.total, 1)
        : getNumberDecimalFixed(total, 1);
    await updateOrder(order.id, {
      status: ORDER_STATUS.ACCEPTED,
      accepted_time: new Date(),
      delivery_time: deliveryTime.toDate(),
      cooking_time: preparingTime || 10,
      total: newTotal
    });
    if (modal) history.push(`/app/orders/${order.id}`);
    setLoading(false);
  };

  const rejectProductsConfirm = items => {
    setConfirmation(translation('orders.details.new_order.first_reject'));
    setCanceledProducts(items);
  };

  const rejectProdutReason = () => {
    setRejectProductOpen(true);
    setConfirmation('');
  };

  const rejectProduct = async () => {
    setRejectProductOpen(false);
    setLoading(true);
    for (let i = 0; i < canceledProducts.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await firestore
        .collection(COLLECTIONS.ORDER)
        .doc(order.id)
        .collection(COLLECTIONS.ORDER_PRODUCT)
        .doc(canceledProducts[i].id)
        .update({
          available: false,
          canceled_reason: rejectProductReason
        });
    }
    await firestore
      .collection(COLLECTIONS.ORDER)
      .doc(order.id)
      .update({
        status: ORDER_STATUS.NEED_CHANGES
      });
    setCanceledProducts([]);
    setRejectProductReason('');
    setLoading(false);
  };

  const rejectOrderConfim = () => {
    setCanceledProducts([]);
    setConfirmationOrder(translation('orders.details.new_order.reject_quest'));
  };

  const rejectOrderReasonFunc = () => {
    setConfirmationOrder('');
    setRejectOrderOpen(true);
  };

  const rejectOrder = async () => {
    setRejectOrderOpen(false);
    setLoading(true);
    await updateOrder(order.id, {
      status: ORDER_STATUS.CANCELED,
      canceled_reason: rejectOrderReason
    });
    setRejectOrderReason('');
    history.push('/app/orders');
    setLoading(false);
  };

  return (
    <Box className={classes.orderContainer}>
      <Header
        modal
        order={order}
        orderInProcess={orderInProcess}
        isNotification={isNotification}
      />
      <BoxLoading isLoading={loading} mt={2}>
        <Grid container spacing={3}>
          <Grid
            item
            md={8}
            xl={8}
            xs={12}
            sm={8}
            className={classes.tableContainer}
          >
            <OrderProductsTable
              modal
              rejectProducts={rejectProductsConfirm}
              rejectOrder={rejectOrderConfim}
              order={order}
              displayDescription={false}
            />
            {order.additional_description && (
              <Box mt={3}>
                <Description order={order} />
              </Box>
            )}
          </Grid>
          <Grid item md={4} xl={4} xs={12} sm={4}>
            {!isNotification && (
              <TimeSetter
                order={order}
                setTime={setPreparingTime}
                preparingTime={preparingTime}
              />
            )}
            {isNotification && <DriverInformation order={order} />}
            <Box mt={3}>
              <Facturation order={order} />
            </Box>
          </Grid>
          {!isNotification && (
            <Grid
              item
              container
              justify="center"
              md={12}
              xl={12}
              xs={12}
              sm={12}
            >
              <NewOrderCockpit
                rejectOrderConfim={rejectOrderConfim}
                orderInProcess={orderInProcess}
                order={order}
              />
            </Grid>
          )}
          {isNotification && (
            <Grid
              item
              container
              justify="center"
              md={12}
              xl={12}
              xs={12}
              sm={12}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                <Typography className={classes.btnText}>OK</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </BoxLoading>
      <AlertDialog
        open={!!confirmation}
        question={confirmation}
        handleCancel={() => {
          setConfirmation('');
          setCanceledProducts([]);
        }}
        handleAccept={rejectProdutReason}
        acceptLabel={translation('orders.details.new_order.alert_reject')}
        cancelLabel={translation('close')}
      >
        {canceledProducts.length && (
          <List>
            {canceledProducts.map(cp => (
              <ListItem key={cp.id}>
                <Typography variant="h6">{cp.product_name}</Typography>
              </ListItem>
            ))}
          </List>
        )}
      </AlertDialog>
      <OneButtonDialog
        open={rejectProductOpen}
        handleAction={rejectProduct}
        handleClose={() => {
          setConfirmation('');
          setCanceledProducts([]);
          setRejectProductOpen(false);
        }}
        title={translation('orders.details.new_order.order_dialog.title')}
        buttonLabel={
          canceledProducts.length === 1
            ? translation('orders.details.new_order.dialog.reject.product')
            : translation('orders.details.new_order.dialog.reject.products')
        }
        disableButton={rejectProductReason === ''}
      >
        <Box m={1}>
          {REJECT_PRODUCT_REASON.map(rp => (
            <Chip
              label={rp}
              key={rp}
              onClick={() => setRejectProductReason(rp)}
              color={rejectProductReason === rp ? 'primary' : 'default'}
            />
          ))}
        </Box>
      </OneButtonDialog>
      <AlertDialog
        open={!!confirmationOrder}
        question={confirmationOrder}
        handleCancel={() => {
          setConfirmationOrder('');
          setCanceledProducts([]);
        }}
        handleAccept={rejectOrderReasonFunc}
        acceptLabel={translation('orders.details.new_order.reject')}
        cancelLabel={translation('close')}
      >
        <Box m={1}>
          <Typography variant="h5">
            {translation('orders.details.order')} {getOrderNumber(order)}
          </Typography>
        </Box>
      </AlertDialog>
      <OneButtonDialog
        open={rejectOrderOpen}
        handleAction={rejectOrder}
        handleClose={() => {
          setCanceledProducts([]);
          setRejectOrderOpen(false);
        }}
        title={translation('orders.details.new_order.order_dialog.title')}
        buttonLabel={translation(
          'orders.details.new_order.order_dialog.reject'
        )}
        disableButton={rejectOrderReason === ''}
      >
        <Box m={1}>
          {REJECT_ORDER_REASON.map(ro => (
            <Chip
              label={ro}
              key={ro}
              onClick={() => setRejectOrderReason(ro)}
              color={rejectOrderReason === ro ? 'primary' : 'default'}
            />
          ))}
        </Box>
      </OneButtonDialog>
    </Box>
  );
};

export default NewOrder;
