import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore'; // <- needed if using firestore
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import scheduleReducer from './scheduleReducer';
import filterReducer from './filterReducer';
import paramsReducer from './paramsReducers';
import filterHistoryReducer from './filterHistoryReducer';
import backdropLoader from './backdropLoader';
import modalChat from './modalChat';

const rootReducer = combineReducers({
  account: accountReducer,
  schedule: scheduleReducer,
  notifications: notificationsReducer,
  chats: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  form: formReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  filter: filterReducer,
  paramsOrder: paramsReducer,
  filterHistory: filterHistoryReducer,
  backdropLoader,
  modalChat
});

export default rootReducer;
