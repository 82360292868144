export const STRING_CONSTANTS = {
  EMPTY_STRING: '',
  BLANK_SPACE: ' '
};
export const capitalizeAllWord = (text = '') =>
  text
    ?.trim()
    ?.toLowerCase()
    ?.split(STRING_CONSTANTS.BLANK_SPACE)
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(STRING_CONSTANTS.BLANK_SPACE) || '';
