import axios from 'src/utils/axios';

class DocumentsService {
  createDocument = (id, data) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/restaurant/createDocument/${id}`, data)
        // eslint-disable-next-line no-shadow
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error.message);
        });
    });
}

const documentsService = new DocumentsService();

export default documentsService;
