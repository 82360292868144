/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  makeStyles,
  IconButton,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { COLORS_ENTREGAS_COM } from '../../../theme/colors';
import Icons from '../../../assets/icons';
import FileUtils from '../../../utils/fileUtils';
import {
  MAX_COMMERCE_IMAGES_SIZE,
  VALID_iMAGE_EXTENSIONS
} from '../../../constants';
import { GreenCrossButton } from '../../../components/Inputs/Buttons';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    margin: '0 24px 24px 24px',
    width: 900,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 360,
    minHeight: 360,
    backgroundColor: COLORS_ENTREGAS_COM.backgroundLightGray,
    boxShadow: '5px 5px 15px rgba(47, 58, 58, 0.08)',
    borderRadius: 20
  },
  img: {
    border: `5px solid ${COLORS_ENTREGAS_COM.secondary}`,
    borderRadius: '50%',
    objectFit: 'cover',
    width: 270,
    height: 270
  },
  imgDefault: {
    width: 120,
    height: 120
  },
  button: {
    color: COLORS_ENTREGAS_COM.white,
    background: COLORS_ENTREGAS_COM.primary,
    textTransform: 'none',
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: COLORS_ENTREGAS_COM.primaryLight
    },
    '&:disabled': {
      background: COLORS_ENTREGAS_COM.lightGray
    }
  },
  text: {
    fontSize: 12,
    color: COLORS_ENTREGAS_COM.textLightGray
  },
  textIcon: {
    fontSize: 20,
    color: COLORS_ENTREGAS_COM.textLightGray
  },
  textfile: {
    fontSize: 15,
    color: COLORS_ENTREGAS_COM.textLightGray
  },
  input: {
    display: 'none'
  }
}));

const UploadCiFullModal = ({ isOpen, handleClose, imageSelect, onChange }) => {
  const classes = useStyles();
  const [imageCiFront, setImageCiFront] = useState(null);
  const [imageCiBack, setImageCiBack] = useState(null);
  const [dimensionErrorCiFront, setDimensionErrorCiFront] = useState([]);
  const [dimensionErrorCiBack, setDimensionErrorCiBack] = useState([]);
  const [urlImageSelectCiFront, setUrlImageSelectCiFront] = useState(null);
  const [urlImageSelectCiBack, setUrlImageSelectCiBack] = useState(null);

  useEffect(() => {
    if (imageSelect?.ciFront) {
      setImageCiFront(imageSelect.ciFront);
      setImageCiBack(imageSelect.ciBack);
      setUrlImageSelectCiFront(imageSelect.ciFrontUrl);
      setUrlImageSelectCiBack(imageSelect.ciBackUrl);
    }
  }, [imageSelect]);

  const setDataFileCiFront = e => {
    setDimensionErrorCiFront([]);
    const messageDimensions = FileUtils.validateImageSizeAndFormat(
      e.target.files[0],
      null,
      MAX_COMMERCE_IMAGES_SIZE,
      VALID_iMAGE_EXTENSIONS
    );
    if (messageDimensions.length === 0) {
      setUrlImageSelectCiFront(null);
      setImageCiFront(e.target.files[0]);
    } else {
      setDimensionErrorCiFront(messageDimensions);
    }
  };

  const setDataFileCiBack = e => {
    setDimensionErrorCiBack([]);
    const messageDimensions = FileUtils.validateImageSizeAndFormat(
      e.target.files[0],
      null,
      MAX_COMMERCE_IMAGES_SIZE,
      VALID_iMAGE_EXTENSIONS
    );
    if (messageDimensions.length === 0) {
      setUrlImageSelectCiBack(null);
      setImageCiBack(e.target.files[0]);
    } else {
      setDimensionErrorCiBack(messageDimensions);
    }
  };

  const onPressSavePicture = () => {
    onChange({
      ciFront: imageCiFront,
      ciFrontUrl: URL.createObjectURL(imageCiFront),
      ciBack: imageCiBack,
      ciBackUrl: URL.createObjectURL(imageCiBack)
    });
    handleClose();
    setImageCiFront(null);
    setImageCiBack(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >
      <Box display="flex" justifyContent="right">
        <GreenCrossButton onClick={handleClose} />
      </Box>
      <Box className={classes.container}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box item className={classes.containerIcon}>
            <Box>
              <input
                accept="image/*"
                className={classes.input}
                id="ci_front"
                type="file"
                onChange={setDataFileCiFront}
              />
              {imageCiFront && dimensionErrorCiFront.length === 0 ? (
                <label htmlFor="ci_front">
                  <IconButton component="span" variant="text">
                    <img
                      src={
                        urlImageSelectCiFront ||
                        URL.createObjectURL(imageCiFront)
                      }
                      className={classes.img}
                      alt="upload_ci_front_image"
                    />
                  </IconButton>
                </label>
              ) : (
                <>
                  {imageSelect.ciFrontUrl ? (
                    <label htmlFor="ci_front">
                      <IconButton component="span" variant="text">
                        <img
                          src={imageSelect.ciFrontUrl}
                          className={classes.img}
                          alt="upload_ci_front_select"
                        />
                      </IconButton>
                    </label>
                  ) : (
                    <label htmlFor="ci_front">
                      <IconButton
                        className={classes.img}
                        component="span"
                        variant="text"
                      >
                        <img
                          src={Icons.addPhoto}
                          className={classes.imgDefault}
                          alt="upload_ci_front"
                        />
                      </IconButton>
                    </label>
                  )}
                </>
              )}
            </Box>
            <Typography className={classes.textIcon}>Anverso</Typography>
            <Typography className={classes.text}>max 10mb</Typography>
            {dimensionErrorCiFront.length > 0 && (
              <Box m={1}>
                <Alert severity="error">
                  Por favor verificar :
                  <ul>
                    {dimensionErrorCiFront.map(e => (
                      <li key={e}>{e}</li>
                    ))}
                  </ul>
                </Alert>
              </Box>
            )}
          </Box>
          <Box item className={classes.containerIcon}>
            <Box>
              <input
                accept="image/*"
                className={classes.input}
                id="ci_back"
                type="file"
                onChange={setDataFileCiBack}
              />
              {imageCiBack && dimensionErrorCiBack.length === 0 ? (
                <label htmlFor="ci_back">
                  <IconButton component="span" variant="text">
                    <img
                      src={
                        urlImageSelectCiBack || URL.createObjectURL(imageCiBack)
                      }
                      className={classes.img}
                      alt="upload_ci_back_image"
                    />
                  </IconButton>
                </label>
              ) : (
                <>
                  {imageSelect.ciBackUrl ? (
                    <label htmlFor="ci_back">
                      <IconButton component="span" variant="text">
                        <img
                          src={imageSelect.ciBackUrl}
                          className={classes.img}
                          alt="upload_ci_back_select"
                        />
                      </IconButton>
                    </label>
                  ) : (
                    <label htmlFor="ci_back">
                      <IconButton
                        className={classes.img}
                        component="span"
                        variant="text"
                      >
                        <img
                          src={Icons.addPhoto}
                          className={classes.imgDefault}
                          alt="upload_ci_back"
                        />
                      </IconButton>
                    </label>
                  )}
                </>
              )}
            </Box>
            <Typography className={classes.textIcon}>Reverso</Typography>
            <Typography className={classes.text}>max 10mb</Typography>
            {dimensionErrorCiBack.length > 0 && (
              <Box m={1}>
                <Alert severity="error">
                  Por favor verificar :
                  <ul>
                    {dimensionErrorCiBack.map(e => (
                      <li key={`${e}_ci_back`}>{e}</li>
                    ))}
                  </ul>
                </Alert>
              </Box>
            )}
          </Box>
        </Box>
        <Box mt={4}>
          <Button
            className={classes.button}
            disabled={
              !imageCiFront ||
              !imageCiBack ||
              dimensionErrorCiFront.length > 0 ||
              dimensionErrorCiBack.length > 0
            }
            onClick={onPressSavePicture}
          >
            Aceptar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UploadCiFullModal;
