import Base from './base';
import { registerStatuses } from './enum';
import { RESTAURANT_DEFAULT_SETTINGS } from '../constants';
/* eslint camelcase: 0 */
export default class Restaurant extends Base {
  constructor({
    id,
    enabled = true,
    block_cashier = false,
    created_at,
    updated_at,
    name,
    logo_photo,
    banner_photo,
    nit_photo,
    menu_file,
    city,
    nit,
    accepted_terms = false,
    owner_id,
    register_status = registerStatuses.pending,
    register_basic_info = {
      computer_disponibility: false,
      internet_disponibility: false,
      personal_disponibility: false,
      experience_disponibility: false
    },
    menu_section = [],
    is_montly_paid = true,
    main_category,
    subsidiary_amount = 0,
    setting = RESTAURANT_DEFAULT_SETTINGS,
    charge = {
      pickup: 0,
      delivery: 0
    },
    order_services = [],
    is_restaurant_invoiced = true,
    denied_merch = true,
    xpress_motos = true,
    enable_multiple_destinations = false
  } = {}) {
    super(id, enabled, created_at, updated_at);
    this.name = name;
    this.block_cashier = block_cashier;
    this.logo_photo = logo_photo;
    this.banner_photo = banner_photo;
    this.nit_photo = nit_photo;
    this.menu_file = menu_file;
    this.city = city;
    this.nit = nit;
    this.accepted_terms = accepted_terms;
    this.owner_id = owner_id;
    this.register_status = register_status;
    this.register_basic_info = register_basic_info;
    this.is_montly_paid = is_montly_paid;
    this.main_category = main_category;
    this.menu_section = menu_section;
    this.subsidiary_amount = subsidiary_amount;
    this.setting = setting;
    this.charge = charge;
    this.order_services = order_services;
    this.is_restaurant_invoiced = is_restaurant_invoiced;
    this.denied_merch = denied_merch;
    this.xpress_motos = xpress_motos;
    this.enable_multiple_destinations = enable_multiple_destinations;
  }
}
