// eslint-disable-next-line func-names
export const byProperty = function(prop, isReversed = false) {
  let multiplier = 1;
  if (isReversed) {
    multiplier = -1;
  }

  // eslint-disable-next-line func-names
  return function(a, b) {
    if (typeof a[prop] === 'number') {
      return (a[prop] - b[prop]) * multiplier;
    }
    return a[prop] < b[prop]
      ? -1 * multiplier
      : (a[prop] > b[prop] ? 1 : 0) * multiplier;
  };
};

export default function sortBy(property, reverse = false) {
  return function sort(itemA, itemB) {
    const isHigher = itemA[property] > itemB[property];
    const isLower = itemA[property] > itemB[property];

    let sortOrder = 0;
    if (isHigher) {
      sortOrder = 1;
    }
    if (isLower) {
      sortOrder = -1;
    }
    if (reverse) {
      sortOrder *= -1;
    }
    return sortOrder;
  };
}

export function orderByAsc(array = [], func = () => null) {
  return array.sort((firstElement, secondElement) => {
    const firstValue = func(firstElement);
    const secondValue = func(secondElement);
    return firstValue > secondValue ? 1 : -1;
  });
}
export function orderByDesc(array = [], func = () => null) {
  return array.sort((firstElement, secondElement) => {
    const firstValue = func(firstElement);
    const secondValue = func(secondElement);
    return firstValue > secondValue ? -1 : 1;
  });
}

export function getBoundaries(array, predicate) {
  const data = array.map(item => predicate(item));
  const ordered = orderByAsc(data, predicate);
  if (ordered.length > 0) {
    return { min: ordered[0], max: ordered[ordered.length - 1] };
  }
  return null;
}

export function splitArray(array, quantity) {
  const size = quantity;
  const arrayOfArrays = [];
  for (let i = 0; i < array.length; i += size) {
    arrayOfArrays.push(array.slice(i, i + size));
  }
  return arrayOfArrays;
}

export function ArraySet(array) {
  const set = new Set(array);
  return Array.from(set);
}
export const ArrayIntAverage = arrayData => {
  if (!arrayData?.length) return 0;
  const totalData = arrayData?.reduce(
    (accumulator, curr) => accumulator + curr
  );
  return totalData / arrayData?.length;
};
