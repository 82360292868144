import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: 45,
    height: 45
  },
  percentage: {
    position: 'absolute',
    top: 6,
    right: '8%',
    textAlign: 'center',
    width: '100%'
  },
  danger: {
    color: theme.palette.expressColors.red
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.expressColors.primary
  },
  label: {
    fontSize: '9px',
    fontWeight: 'bold'
  }
}));

const ProgressCircleTimer = ({
  showMinutes,
  acceptedTime = moment(),
  isDone,
  cookingTime
}) => {
  const classes = useStyles();
  const [progressState, setProgressState] = useState({
    minutes: cookingTime,
    progress: 100
  });

  useEffect(() => {
    const adjustTimeConstant = 2;
    const minutesToAdd = cookingTime + adjustTimeConstant;
    const acceptedPlusCookingTime = moment(acceptedTime.toDate()).add(
      minutesToAdd,
      'm'
    );
    const getMinutes = () => {
      const nowMoment = moment();
      const remTime = acceptedPlusCookingTime.diff(nowMoment, 'minutes');
      if (remTime <= 0) return 0;
      return remTime - 1;
    };

    const interval = setInterval(() => {
      setProgressState(oldState => {
        if (oldState.done) {
          isDone();
          clearInterval(interval);
        }
        return {
          minutes: getMinutes(),
          progress: (oldState.minutes * 100) / cookingTime,
          done: oldState.minutes <= 0
        };
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [acceptedTime, cookingTime, isDone]);

  return (
    <Box className={classes.root}>
      {showMinutes && (
        <Box className={classes.percentage}>
          <Typography align="center" className={classes.label}>
            {parseInt(progressState.minutes, 10)}
          </Typography>
          <Typography align="center" className={classes.label}>
            min
          </Typography>
        </Box>
      )}
      <CircularProgress
        thickness={6}
        size={38}
        variant="static"
        value={progressState.progress}
        className={clsx({
          [classes.danger]: progressState.minutes <= 5,
          [classes.progress]: progressState.minutes > 5
        })}
      />
    </Box>
  );
};

export default ProgressCircleTimer;
