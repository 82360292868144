/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { useConversationStyles } from './chatStyles';
import { MESSAGE_TYPE } from '../../../constants';

const ConversationMessages = ({ m, user, chat }) => {
  const classes = useConversationStyles();
  const roles = m.sender_roles;
  let initial = '';
  if (roles.OWNER) initial = 'O-';
  if (roles.SUPERVISOR) initial = 'S-';
  if (roles.CASHIER) initial = 'C-';
  return (
    <Box
      key={m.id}
      className={clsx(classes.messageBox, {
        [classes.ownMessage]: m.create_from === 'merchant'
      })}
    >
      <Box className={clsx(classes.message)}>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="baseline"
        >
          <Grid item className={clsx(classes.messageTitle)}>
            <Typography>
              {m.create_from !== 'merchant'
                ? `XPRESS - ${m.sender_name}`
                : `${initial}${chat.restaurant_name}-${chat.subsidiary_name}`}
            </Typography>
          </Grid>
          <Grid item>
            {m.type === MESSAGE_TYPE.TEXT && (
              <Typography className={clsx(classes.messageText)}>
                {m.message}
              </Typography>
            )}

            {m.type === MESSAGE_TYPE.IMAGE && (
              <img
                alt="alt"
                src={m.urlImage}
                style={{ maxWidth: '30vh', maxHeight: '30vh' }}
                onClick={() => window.open(m.urlImage, '_blank')}
              />
            )}
          </Grid>
          <Grid container item direction="column" alignItems="flex-end">
            <Typography className={clsx(classes.messagetime)}>
              {moment(m.created_at ? m.created_at.toDate() : moment()).format(
                'h:mm a'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ConversationMessages;
