import { useDispatch } from 'react-redux';
import { setModalChat } from '../../reducers/modalChat';

export default function useModalChat() {
  const dispatch = useDispatch();
  const showModalChat = () => {
    dispatch(setModalChat({ open: true }));
  };
  const hideModalChat = () => {
    dispatch(setModalChat({ open: false }));
  };
  return [showModalChat, hideModalChat];
}
