const ProductionEnv = {
  googleAnalytics: 'UA-237277188-2',
  driverTermsAndConditions: 'https://xpressbolivia.com/driver-agreement',
  REACT_APP_VROOM_URL: 'https://vroom-nnzydfbpnq-uc.a.run.app',
  REACT_APP_XPRESS_API_URL: 'https://xpress-api-nnzydfbpnq-uc.a.run.app',
  REACT_APP_WHATSAPP: 'https://wa.me/',
  REACT_APP_XPRESS_SUPPORT: '+59162093356'
};

export default ProductionEnv;
