import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loadingWrapper: {
    background: theme.palette.background.defaultTransparent,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10
  },
  wrapper: {
    position: 'relative'
  }
}));

function WithLoading(Component) {
  return function WihLoadingComponent({ isLoading, ...props }) {
    const classes = useStyles();
    return (
      <Box className={classes.wrapper}>
        {isLoading && (
          <Box className={classes.loadingWrapper}>
            <CircularProgress />
          </Box>
        )}
        <Component {...props} />
      </Box>
    );
  };
}
export default WithLoading;
