/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_PARAMS } from '../actions/paramsActions';

const initialState = { id: '/admin/orders' };

const paramsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARAMS: {
      const { fields } = action.payload;
      return produce(state, draft => {
        draft.id = fields.id || state.id;
      });
    }

    default:
      return state;
  }
};

export default paramsReducer;
