import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const Comfirmation = ({ handleConfirm, handleClose, message }) => {
  const classes = useStyles();
  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader className={classes.Header} title={message.header} />
        <Divider />
        <CardContent>
          <Grid container justify="space-between" alignItems="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleConfirm}
            >
              {message.btnLeft}
            </Button>
            <Button color="secondary" variant="contained" onClick={handleClose}>
              {message.btnRight}
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Comfirmation;
