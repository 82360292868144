/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import firebase from 'firebase/app';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box,
  LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Icons from '../../assets/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const UploadFile = ({ path, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const document = useRef(null);
  const [docName, setDocName] = useState('');
  const [documentFile, setDocumentFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [urlObject, setUrlObject] = useState('');

  const onButtonClick = () => {
    document.current.click();
  };

  const saveFile = event => {
    setDocumentFile(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setUrlObject(URL.createObjectURL(event.target.files[0]));
    }
  };

  const upload = async () => {
    setUploading(true);
    const storageRef = firebase.storage().ref(`documents/${path}/${docName}`);
    const uploadTask = storageRef.put(documentFile);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
      },
      error => {
        console.log(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        handleConfirm(url, docName);
      }
    );
  };

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('modals.uploadFile.title')}
        />
        <Divider />
        <CardContent>
          <Grid item container>
            <Grid item container>
              <Grid item container xs={9} md={9}>
                <Box pb={3} pr={3} width="100%">
                  <TextField
                    style={{ marginTop: urlObject ? 0 : 10 }}
                    fullWidth
                    label={translation('modals.uploadFile.doc_name')}
                    name="name"
                    onChange={e => setDocName(e.target.value)}
                    value={docName}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item container xs={3} md={3}>
                <input
                  type="file"
                  id="bannerFile"
                  accept="image/*"
                  ref={document}
                  style={{ display: 'none' }}
                  onChange={event => saveFile(event)}
                />
                <img
                  alt="Nit"
                  onClick={() => onButtonClick()}
                  style={{
                    minWidth: 100,
                    minHeight: 100,
                    maxWidth: 200,
                    maxHeight: 200,
                    cursor: 'pointer'
                  }}
                  src={urlObject || Icons.uploadImage}
                />
              </Grid>
              {uploading && (
                <Box width="100%" my={3}>
                  <LinearProgress />
                </Box>
              )}
            </Grid>
            {!uploading && (
              <Box mt={3} width="100%">
                <Grid item container justify="center">
                  <Box mr={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={upload}
                    >
                      {translation('modals.uploadFile.btn_save')}
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleClose}
                    >
                      {translation('cancel')}
                    </Button>
                  </Box>
                </Grid>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UploadFile;
