const ACTIONS = {
  SET_BACKDROP_LOADER: 'SET_BACKDROP_LOADER'
};

const initial_state = {
  open: false,
  text: ''
};

export function setBackdropLoader({ open, text }) {
  return { type: ACTIONS.SET_BACKDROP_LOADER, data: { open, text } };
}

export default function backdropLoader(state = initial_state, { type, data }) {
  switch (type) {
    case ACTIONS.SET_BACKDROP_LOADER:
      return { ...state, ...data };
    default:
      return { ...state };
  }
}
