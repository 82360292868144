/* eslint-disable global-require */
const Icons = {
  order: require('./order.png'),
  edit: require('./Edit.png'),
  close: require('./Cross.png'),
  usePicture: require('./userPicture.png'),
  uploadImage: require('./uploadImage.png'),
  back: require('./Back.png'),
  productDish: require('./ProductDish.png'),
  productImage: require('./ProductImage.jpg'),
  userPhoto: require('./userPhoto.png'),
  userPersonalCard: require('./personalCard.png'),
  userLicensePlate: require('./licensePlate.png'),
  userSoat: require('./soat.png'),
  userRuat: require('./ruat.png'),
  motorcycleIcon: require('./motorcycle.png'),
  carIcon: require('./car.png'),
  homeIcon: require('./homeImage.png'),
  homeSocialMediaIcon: require('./landscape.png'),
  android: require('./android.png'),
  ios: require('./ios.png'),
  driver: require('./driver.png'),
  statusSuccess: require('./status_success.png'),
  succeedRegister: require('./succeed.png'),
  upload: require('./upload.png'),
  addPhoto: require('./add_a_photo.png'),
  addFile: require('./add_file.png'),
  delivered: require('./delivered.png'),
  logoEntregas: require('./logoEntregas.png'),
  logoXpressDriver: require('./logoXpressDriver.png'),
  logoWallet: require('./wallet.png'),
  logoLike: require('./like.png'),
  logoDislike: require('./dislike.png'),
  ghost: require('./ghost.png'),
  warning: require('./warning.png'),
  searchColor: require('./IconColorSearch.png'),
  plusIcon: require('./plusIcon.png')
};
export default Icons;
