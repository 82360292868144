/* eslint-disable camelcase */
import Base from './base';
import admin from '../config/firebaseConfig';

import {
  ORDER_TYPE,
  DELIVERY_STATUS,
  ORDER_STATUS,
  PAYMENT_METHOD,
  TICKET_STATUS,
  XPRESS_ORDER_TYPE
} from './enum';

export default class Order extends Base {
  constructor(
    id = '',
    restaurant_id = '',
    subsidiary_id = '',
    user_id = '',
    user_full_name = '',
    total = 0,
    subsidy = 0,
    restaurant_name = '',
    subsidiary_name = '',
    subsidiary_city = {},
    subsidiary_address = '',
    location = {},
    subsidiary_geohash = '',
    subsidiary_location = {},
    delivery_location = {},
    delivery_fee = -1,
    user_phone = '',
    order_with_destination = false,
    driver_restaurant_nearby = false,
    driver_nearby = false,
    delivery_distance = 0,
    order_delivery_factor = 0,
    multiple_destination_parent_id = '',
    xpress_order_type = XPRESS_ORDER_TYPE.NORMAL,
    delivery_status = DELIVERY_STATUS.UNASSIGNED,
    driver = {},
    driver_id = '',
    ticket = '',
    ticket_status = TICKET_STATUS.WAITING,
    status = ORDER_STATUS.ACCEPTED,
    payment_method = PAYMENT_METHOD.CASH,
    delivery_time = admin.firestore.FieldValue.serverTimestamp(),
    expiration_ticket = admin.firestore.FieldValue.serverTimestamp(),
    updated_at = admin.firestore.FieldValue.serverTimestamp(),
    last_modifier_id = '',
    simple_calculation = true,
    xpressMotos = true,
    discount = 0,
    products_available_number = 1
  ) {
    super(id, updated_at);
    this.total = parseFloat(total);
    this.restaurant_id = restaurant_id;
    this.subsidiary_id = subsidiary_id;
    this.user_id = user_id;
    this.status = status;
    this.delivery_status = delivery_status;
    this.driver = driver;
    this.delivery_fee = parseFloat(delivery_fee);
    this.driver_id = driver_id;
    if (delivery_location && delivery_location?.location) {
      this.delivery_location = {
        ...delivery_location,
        location: {
          ...new admin.firestore.GeoPoint(
            delivery_location?.location?.latitude || '',
            delivery_location?.location?.longitude || ''
          ),
          latitude: delivery_location?.location?.latitude || '',
          longitude: delivery_location?.location?.longitude || ''
        }
      };
    } else {
      this.delivery_location = delivery_location;
    }
    this.delivery_distance = delivery_distance;
    this.order_delivery_factor = order_delivery_factor;
    this.multiple_destination_parent_id = multiple_destination_parent_id;
    this.xpress_order_type = xpress_order_type;
    this.invoice_name = user_full_name;
    this.invoice_nit = 0;
    this.additional_description = '';
    this.delivery_time = delivery_time;
    this.ticket_status = ticket_status;
    this.ticket = ticket;
    this.subsidy = subsidy;
    this.expiration_ticket = expiration_ticket;
    this.payment_method = payment_method;
    this.user_full_name = user_full_name;
    this.restaurant_name = restaurant_name;
    this.subsidiary_name = subsidiary_name;
    if (subsidiary_city) {
      this.subsidiary_city = {
        ...subsidiary_city,
        location: {
          ...new admin.firestore.GeoPoint(
            subsidiary_city.location.latitude,
            subsidiary_city.location.longitude
          ),
          latitude: subsidiary_city.location.latitude,
          longitude: subsidiary_city.location.longitude
        }
      };
    } else {
      this.subsidiary_city = subsidiary_city;
    }
    this.subsidiary_geohash = subsidiary_geohash;
    this.order_type = ORDER_TYPE.DELIVERY;
    this.discount = discount;
    this.promotionId = '';
    this.promotionTitle = '';
    this.user_phone = user_phone;
    this.order_with_destination = order_with_destination;
    this.driver_restaurant_nearby = driver_restaurant_nearby;
    this.driver_nearby = driver_nearby;
    this.last_modifier_id = last_modifier_id;
    if (location && delivery_location?.location) {
      this.location = {
        ...new admin.firestore.GeoPoint(
          delivery_location?.location?.latitude,
          delivery_location?.location?.longitude
        ),
        latitude: delivery_location?.location?.latitude,
        longitude: delivery_location?.location?.longitude
      };
    } else {
      this.location = location;
    }
    this.geoHash = location.geoHash || '';
    if (subsidiary_location) {
      this.subsidiary_location = {
        ...new admin.firestore.GeoPoint(
          subsidiary_location.latitude,
          subsidiary_location.longitude
        ),
        latitude: subsidiary_location.latitude,
        longitude: subsidiary_location.longitude
      };
    }
    this.simple_calculation = simple_calculation;
    this.subsidiary_address = subsidiary_address;
    this.xpressMotos = xpressMotos;
    this.products_available_number = products_available_number;
  }
}
