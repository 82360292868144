import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  isDriver,
  isDriverAccepted,
  isDriverInProcess,
  isDriverPending,
  isDriverRejected,
  isMerchant
} from '../utils/helpers/userHelper';
import { ROUTES_PATHS } from '../constants';
import { useFirebaseUser } from '../hooks/useUser';

function AdminGuard({ children }) {
  const currentUser = useFirebaseUser();
  const { user } = useSelector(state => state.account);
  // const currentUser = useFirebaseUser();
  if (user) {
    if (isDriver(user)) {
      if (isDriverAccepted(user) || isDriverRejected(user)) {
        return <Redirect to={ROUTES_PATHS.DRIVER_DASHBOARD} />;
      }

      if (isDriverPending(user)) {
        return (
          <Redirect to={`${ROUTES_PATHS.DRIVER_REGISTER}/${currentUser.uid}`} />
        );
      }
      if (isDriverInProcess(user)) {
        return (
          <Redirect
            to={`${ROUTES_PATHS.DRIVER_REGISTER}/${currentUser.uid}/${currentUser.phoneNumber}`}
          />
        );
      }
    }
    /* if (isDriverRegistered(user)) {
      return <Redirect to={ROUTES_PATHS.DRIVER_DASHBOARD} />;
    }
    if (isDriverInProcess(user)) {
      return (
        <Redirect
          to={`${ROUTES_PATHS.DRIVER_REGISTER}/${currentUser.uid}/${currentUser.phoneNumber}`}
        />
      );
    } */
    if (isMerchant(user)) {
      return <Redirect to="/app/orders" />;
    }
    return children;
  }
  return <Redirect to="/login" />;
}

AdminGuard.propTypes = {
  children: PropTypes.any
};

export default AdminGuard;
