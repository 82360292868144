/* eslint-disable import/prefer-default-export */
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { COLLECTIONS } from '../../constants';

export const useSubsidiaryChat = () => {
  const previousDate = moment()
    .startOf('day')
    .subtract(1, 'days')
    .valueOf();
  const subsidiaryId = useSelector(
    state => state.account.currentSubsidiary?.id
  );
  let query = null;
  if (subsidiaryId) {
    query = {
      collection: COLLECTIONS.CHAT,
      where: [
        ['subsidiary_id', '==', subsidiaryId],
        ['is_active', '==', true],
        ['updated_at', '>', new Date(previousDate)]
      ],
      orderBy: ['updated_at', 'desc'],
      storeAs: 'subsidiary_chats'
    };
  }
  useFirestoreConnect(query);

  const chats = useSelector(state => state.firestore.ordered.subsidiary_chats);
  return chats || [];
};
export const useSubsidiaryActiveChats = (limitDay = 0) => {
  const previousDate = moment()
    .startOf('day')
    .subtract(limitDay, 'days')
    .valueOf();
  const subsidiaryId = useSelector(state => state.account.currentSubsidiary.id);
  const where = [
    ['subsidiary_id', '==', subsidiaryId],
    ['is_active', '==', true],
    ['updated_at', '>', new Date(previousDate)]
  ];
  if (limitDay <= 7) where.push(['is_active', '==', true]);

  useFirestoreConnect({
    collection: COLLECTIONS.CHAT,
    where,
    orderBy: ['updated_at', 'desc'],
    storeAs: 'subsidiary_active_chats'
  });
  const chats = useSelector(
    state => state.firestore.ordered.subsidiary_active_chats
  );
  return chats || [];
};

export const useChatMesseges = chatId => {
  useFirestoreConnect({
    collection: COLLECTIONS.CHAT,
    doc: chatId,
    subcollections: [{ collection: COLLECTIONS.MESSAGE }],
    orderBy: ['created_at', 'asc'],
    storeAs: 'chat_messages'
  });

  const messages = useSelector(state => state.firestore.ordered.chat_messages);
  return messages || [];
};

export const useChat = chatId => {
  useFirestoreConnect({
    collection: COLLECTIONS.CHAT,
    where: [['id', '==', chatId]],
    storeAs: `chat_${chatId}`
  });

  const chat = useSelector(state => state.firestore.ordered[`chat_${chatId}`]);
  return chat || [];
};

export const useChatById = chatId => {
  useFirestoreConnect({
    collection: COLLECTIONS.CHAT,
    where: [
      ['id', '==', chatId],
      ['is_active', '==', true]
    ],
    storeAs: `last_chat_${chatId}`
  });

  const chat = useSelector(
    state => state.firestore.ordered[`last_chat_${chatId}`]
  );
  return chat || [];
};
export const useNewMessageChat = () => {
  useFirestoreConnect({
    collectionGroup: COLLECTIONS.MESSAGE,
    where: [['create_from', '==', 'admin']],
    orderBy: ['created_at', 'desc'],
    limit: 1,
    storeAs: 'last_message_chat'
  });
  const newMessageChat = useSelector(
    state => state.firestore.ordered.last_message_chat
  );

  const newChat = useChatById(
    newMessageChat?.length > 0 ? newMessageChat[0].chat_id : ''
  );

  return {
    message: newMessageChat ? newMessageChat[0] : {},
    chat: newChat ? newChat[0] : {}
  };
};
