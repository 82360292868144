import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { useParams, useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import {
  makeStyles,
  Container,
  Grid,
  Box,
  Typography,
  IconButton,
  Button
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icons from '../../assets/icons';
import TextFieldOrange from '../../components/Inputs/TextFieldOrange';
import { OrangeButtonCommerce } from '../../components/Inputs/Buttons';
import commerceRegisterStyles from './commerceRegisterStyles';
import UploadLogoModal from './Modal/UploadLogo';
import UploadBannerModal from './Modal/UploadBanner';
import UploadAgreementModal from './Modal/UploadAgreement';
import UploadCiFullModal from './Modal/UploadCiFull';
import UploadCiFrontModal from './Modal/UploadCiFront';
import UploadCiBackModal from './Modal/UploadCiBack';
import useBackdropLoader from '../../hooks/backdropLoader';
import { saveFileToStorage } from '../../services/uploadToStorage';
import documentsService from '../../services/documentsService';
import { useSettingsDocuments } from '../../hooks/SettingsHooks';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('969')]: {
      paddingTop: theme.spacing(10)
    }
  },
  link: {
    fontSize: '20px',
    textDecoration: 'none',
    textTransform: 'none',
    color: theme.palette.expressColors.orange,
    borderBottom: `1px solid ${theme.palette.expressColors.orange}`
  },
  marginTextField: {
    marginBottom: '25px',
    [theme.breakpoints.down('969')]: {
      marginBottom: '5px'
    }
  }
}));
const CompleteRegisterDocuments = () => {
  const classes = useStyles();
  const classesContainer = commerceRegisterStyles();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { commerceId } = useParams();
  const documents = useSettingsDocuments();
  // matches === true is web
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 969px)').matches
  );
  const [isModalLogoOpen, setIsModalLogoOpen] = useState(false);
  const [isModalBannerOpen, setIsModalBannerOpen] = useState(false);
  const [isModalAgreementOpen, setIsModalAgreementOpen] = useState(false);
  const [isModalCiFullOpen, setIsModalCiFullOpen] = useState(false);
  const [isModalCiFrontOpen, setIsModalCiFrontOpen] = useState(false);
  const [isModalCiBackOpen, setIsModalCiBackOpen] = useState(false);

  const [logoPhotoImage, setLogoPhotoImage] = useState(null);
  const [bannerPhotoImage, setBannerPhotoImage] = useState(null);
  const [agreementFile, setAgreementFile] = useState(null);
  const [ciFile, setCiFile] = useState({ ciFront: null, ciBack: null });

  const [showBackdrop, hideBackdrop] = useBackdropLoader();

  useEffect(() => {
    window
      .matchMedia('(min-width: 969px)')
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  const showBackdropUpload = (text, percentage) => {
    showBackdrop(`${text} ${percentage}%`);
  };

  const saveToStorage = async () => {
    const imageUrlLogo = await saveFileToStorage(
      `restaurants/logo/${commerceId}`,
      logoPhotoImage.image,
      ({ percentage }) =>
        showBackdropUpload('Subiendo el Logo del Comercio', percentage)
    );
    const imageUrlBanner = await saveFileToStorage(
      `restaurants/banner/${commerceId}`,
      bannerPhotoImage.image,
      ({ percentage }) =>
        showBackdropUpload('Subiendo el Banner del Comercio', percentage)
    );
    const fileUrlAgreement = await saveFileToStorage(
      `documents/restaurants/${commerceId}/signed_agreement`,
      agreementFile.file,
      ({ percentage }) =>
        showBackdropUpload('Subiendo el Acuerdo firmado', percentage),
      true
    );
    const fileUrlCiFront = await saveFileToStorage(
      `documents/restaurants/${commerceId}/ci_front`,
      ciFile.ciFront,
      ({ percentage }) =>
        showBackdropUpload(
          'Subiendo el Anverso del Carnet de Identidad',
          percentage
        ),
      true
    );
    const fileUrlCiBack = await saveFileToStorage(
      `documents/restaurants/${commerceId}/ci_back`,
      ciFile.ciBack,
      ({ percentage }) =>
        showBackdropUpload(
          'Subiendo el Reverso del Carnet de Identidad',
          percentage
        ),
      true
    );
    if (
      imageUrlLogo &&
      imageUrlBanner &&
      fileUrlAgreement &&
      fileUrlCiFront &&
      fileUrlCiBack
    ) {
      return {
        imageUrlLogo,
        imageUrlBanner,
        fileUrlAgreement,
        fileUrlCiFront,
        fileUrlCiBack
      };
    }
    return null;
  };

  const saveDocumentsToDB = async urls => {
    await Promise.all([
      await documentsService.createDocument(commerceId, {
        name: 'Acuerdo firmado',
        name_file: agreementFile.file.name,
        type: 'signed_agreement',
        url: urls.fileUrlAgreement
      }),
      await documentsService.createDocument(commerceId, {
        name: 'Ci Anverso',
        name_file: ciFile.ciFront.name,
        type: 'ci_front',
        url: urls.fileUrlCiFront
      }),
      await documentsService.createDocument(commerceId, {
        name: 'Ci Reverso',
        name_file: ciFile.ciBack.name,
        type: 'ci_back',
        url: urls.fileUrlCiBack
      })
    ])
      .then(() => {
        history.push('/commerce_register/successful_registration');
        hideBackdrop();
      })
      .catch(error => {
        console.error(error);
        hideBackdrop();
        snackbar.enqueueSnackbar('Ocurrio un Error al guardar los Documentos', {
          variant: 'error'
        });
      });
  };

  const saveFiles = async () => {
    const urls = await saveToStorage();
    if (urls) {
      showBackdrop('Guardando información de los documentos');
      await saveDocumentsToDB(urls);
      hideBackdrop();
    } else {
      hideBackdrop();
      snackbar.enqueueSnackbar('Ocurrio un Error al subir los Docuemntos', {
        variant: 'error'
      });
    }
  };
  const validImageExtensions = ['image/jpg', 'image/jpeg'];

  return (
    <Page title="Registro de comercios" className={classes.root}>
      {!matches && (
        <Grid className={classesContainer.containerTitle}>
          <Box display="flex" justifyContent="center">
            <Typography className={classesContainer.textTitleResponsive}>
              Carga de documentos
            </Typography>
          </Box>
        </Grid>
      )}
      <Container className={clsx(classesContainer.root)}>
        <Grid
          className={clsx(
            classesContainer.containerForm,
            classesContainer.containerFormResponsive
          )}
        >
          {matches && (
            <Grid className={classesContainer.containerTitle}>
              <Box display="flex" justifyContent="center">
                <Typography className={classesContainer.textTitle}>
                  Carga de documentos
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid>
            <Grid
              container
              style={{
                width: '100%',
                flexDirection: matches ? 'row' : 'column'
              }}
              spacing={matches ? 3 : 0}
            >
              <Grid item>
                <TextFieldOrange
                  style={matches ? { width: '350px' } : null}
                  className={clsx(classes.marginTextField)}
                  fullWidth
                  label="Añadir Logotipo"
                  margin="normal"
                  name="logo"
                  type="text"
                  value={logoPhotoImage ? logoPhotoImage.image.name : ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setIsModalLogoOpen(true)}>
                          <img src={Icons.upload} alt="upload_icon" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Añadir Logotipo"
                />
              </Grid>
              <Grid item>
                <TextFieldOrange
                  style={matches ? { width: '350px' } : null}
                  className={clsx(classes.marginTextField)}
                  fullWidth
                  label="Añadir Banner"
                  margin="normal"
                  name="banner"
                  type="text"
                  variant="outlined"
                  value={bannerPhotoImage ? bannerPhotoImage.image.name : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setIsModalBannerOpen(true)}>
                          <img src={Icons.upload} alt="upload_icon" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Añadir Banner"
                />
              </Grid>
              <Grid item>
                <TextFieldOrange
                  style={matches ? { width: '350px' } : null}
                  className={clsx(classes.marginTextField)}
                  fullWidth
                  label="Acuerdo firmado"
                  margin="normal"
                  name="signed_agreement"
                  type="text"
                  variant="outlined"
                  agreementFile
                  value={agreementFile ? agreementFile.file.name : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setIsModalAgreementOpen(true)}
                        >
                          <img src={Icons.upload} alt="upload_icon" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Acuerdo firmado"
                />
              </Grid>
              <Grid item>
                <TextFieldOrange
                  style={matches ? { width: '350px' } : null}
                  className={clsx(classes.marginTextField)}
                  fullWidth
                  label="Foto carnet de identidad"
                  margin="normal"
                  name="ci_photo"
                  type="text"
                  variant="outlined"
                  value={
                    ciFile?.ciFront && ciFile?.ciBack
                      ? `${ciFile.ciFront.name} ${ciFile.ciBack.name}`
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            return matches
                              ? setIsModalCiFullOpen(true)
                              : setIsModalCiFrontOpen(true);
                          }}
                        >
                          <img src={Icons.upload} alt="upload_icon" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Foto carnet de identidad"
                />
              </Grid>
            </Grid>
            {documents && (
              <Grid>
                <Box mt={2} display="flex" justifyContent="center">
                  <Button>
                    <RouterLink
                      className={classes.link}
                      to={{ pathname: `${documents.legal_agreement_entregas}` }}
                      target="_blank"
                    >
                      Descargar acuerdo
                    </RouterLink>
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid className={classesContainer.containerNextButton}>
              <Box mt={2}>
                <OrangeButtonCommerce
                  disabled={
                    !logoPhotoImage ||
                    !bannerPhotoImage ||
                    !agreementFile ||
                    !ciFile.ciFront ||
                    !ciFile.ciBack
                  }
                  onClick={saveFiles}
                >
                  Siguiente
                </OrangeButtonCommerce>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <UploadLogoModal
          isOpen={isModalLogoOpen}
          matches={matches}
          handleClose={() => setIsModalLogoOpen(false)}
          imagenSelect={logoPhotoImage ? logoPhotoImage.url : null}
          maxDimensions={{
            minHeight: 400,
            minWidth: 400
          }}
          validImageExtensions={validImageExtensions}
          onChange={image => setLogoPhotoImage(image)}
        />
        <UploadBannerModal
          isOpen={isModalBannerOpen}
          matches={matches}
          handleClose={() => setIsModalBannerOpen(false)}
          imagenSelect={bannerPhotoImage ? bannerPhotoImage.url : null}
          maxDimensions={{
            minHeight: 810,
            minWidth: 315
          }}
          validImageExtensions={validImageExtensions}
          onChange={image => setBannerPhotoImage(image)}
        />
        <UploadAgreementModal
          isOpen={isModalAgreementOpen}
          matches={matches}
          handleClose={() => setIsModalAgreementOpen(false)}
          fileSelect={agreementFile ? agreementFile.name : ''}
          onChange={file => setAgreementFile(file)}
        />
        {matches ? (
          <UploadCiFullModal
            isOpen={isModalCiFullOpen}
            handleClose={() => setIsModalCiFullOpen(false)}
            imageSelect={ciFile || null}
            onChange={file => setCiFile({ ...ciFile, ...file })}
          />
        ) : (
          <>
            <UploadCiFrontModal
              isOpen={isModalCiFrontOpen}
              textIcon="Anverso"
              imageSelect={ciFile || null}
              handleClose={() => setIsModalCiFrontOpen(false)}
              nextModalOpen={() => setIsModalCiBackOpen(true)}
              onChange={file => setCiFile({ ...ciFile, ...file })}
            />
            <UploadCiBackModal
              isOpen={isModalCiBackOpen}
              textIcon="Reverso"
              imageSelect={ciFile || null}
              handleClose={() => setIsModalCiBackOpen(false)}
              onChange={file => setCiFile({ ...ciFile, ...file })}
            />
          </>
        )}
      </Container>
    </Page>
  );
};

export default CompleteRegisterDocuments;
