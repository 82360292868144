import React, { useState } from 'react';
import { Button, Card, Typography, Box, Badge } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useCockpitStyles } from './styles';
import XpressModal from '../../../components/Modal';
import ChatLayout from './modal/ChatLayout';
import useModalChat from '../../../hooks/modalChat';
import { useCurrentSubsidiary } from '../../../hooks/useRestaurant';
import ModalRequestDriver from './modalRequestDriver';
import Icons from '../../../assets/icons';
import ErrorModal from '../../../components/Modal/ErrrorModal';
import { useLastChargeHistory } from '../../../hooks/useChargesHistory';

const Cockpit = ({ chatNewMessagesAmount, isEnableChat }) => {
  const classes = useCockpitStyles();
  const { open } = useSelector(store => store.modalChat);
  const [showModalChat, hideModalChat] = useModalChat();
  const [showModalMoto, setShowModalMoto] = useState(false);
  const currentSubsidiary = useCurrentSubsidiary();
  const lastChargeHistory = useLastChargeHistory(
    currentSubsidiary.restaurant_id
  );
  const [modalChargesHistory, setModalChargesHistory] = useState(false);

  const handleClose = () => {
    hideModalChat(false);
  };

  const handleCloseModalForDriver = () => {
    setShowModalMoto(false);
  };

  return (
    <Box className={clsx(classes.container)}>
      <Card className={clsx(classes.card)}>
        <Button
          onClick={() => {
            if (!lastChargeHistory) setModalChargesHistory(true);
            else setShowModalMoto(true);
          }}
        >
          <Typography className={classes.text}>Pedir Moto</Typography>
        </Button>
      </Card>
      {isEnableChat && (
        <Badge
          color="primary"
          className={clsx(classes.chip)}
          badgeContent={chatNewMessagesAmount}
        >
          <Card className={clsx(classes.card)}>
            <Button onClick={() => showModalChat()}>
              <Typography className={classes.text}>Chat</Typography>
            </Button>
          </Card>
        </Badge>
      )}
      {open && (
        <XpressModal handleClose={handleClose}>
          <ChatLayout handleClose={handleClose} />
        </XpressModal>
      )}
      {showModalMoto && (
        <XpressModal handleClose={handleCloseModalForDriver}>
          <ModalRequestDriver
            handleClose={handleCloseModalForDriver}
            subsidiary={currentSubsidiary}
          />
        </XpressModal>
      )}
      <ErrorModal
        isOpen={modalChargesHistory}
        title="Falta configurar los cobros de comisiones contáctese con el administrador"
        icon={Icons.ghost}
        buttonText="Aceptar"
        confirm={() => {
          setModalChargesHistory(false);
        }}
      />
    </Box>
  );
};

export default Cockpit;
