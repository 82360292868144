export const SET_FILTER = '@filter/set';
export const DEFAULT_STATE = '@filter/setDefaultState';

export const setFilter = fields => dispath =>
  dispath({
    type: SET_FILTER,
    payload: { fields }
  });

export const setDefaultFields = () => dispath => {
  dispath({
    type: DEFAULT_STATE
  });
};
