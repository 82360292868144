import { useEffect, useState } from 'react';

let timer = null;

// eslint-disable-next-line import/prefer-default-export
export function useUserIsInactive(userInactiveTime) {
  const [result, setResult] = useState(false);
  const resetUserActivityTimeout = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setResult(true);
    }, userInactiveTime);
    setResult(false);
  };
  useEffect(() => {
    resetUserActivityTimeout();
    window.addEventListener('mousemove', resetUserActivityTimeout);
    window.addEventListener('scroll', resetUserActivityTimeout);
    window.addEventListener('keydown', resetUserActivityTimeout);
    window.addEventListener('resize', resetUserActivityTimeout);
    return () => {
      window.removeEventListener('mousemove', resetUserActivityTimeout);
      window.removeEventListener('scroll', resetUserActivityTimeout);
      window.removeEventListener('keydown', resetUserActivityTimeout);
      window.removeEventListener('resize', resetUserActivityTimeout);
    };
    // eslint-disable-next-line
  }, [userInactiveTime]);
  return result;
}

export function useWebView(minSize = 969) {
  const [webView, setWebView] = useState(
    window.matchMedia(`(min-width: ${minSize}px)`).matches
  );
  useEffect(() => {
    window
      .matchMedia(`(min-width: ${minSize}px)`)
      .addEventListener('change', e => setWebView(e.matches));
  }, [webView, minSize]);
  return webView;
}
