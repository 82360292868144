/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const useChatViewStyles = makeStyles(theme => ({
  launcherContainer: {
    maxWidth: 300
  },
  detailContainer: {
    maxWidth: 700,
    marginLeft: theme.spacing(2)
  },
  conversationContainer: {
    maxWidth: 900,
    minWidth: 500,
    marginLeft: theme.spacing(2)
  },
  chatCard: {
    position: 'absolute',
    width: 488,
    height: '75vh',
    display: 'flex',
    right: '30px',
    bottom: '30px',
    borderLeft: `5px solid ${theme.palette.expressColors.green}`
  },
  minimizedChard: {
    height: 55
  }
}));

export const useLauncherStyles = makeStyles(theme => ({
  chatsContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    overflowY: 'auto',
    backgroundColor: theme.palette.expressColors.greenCake
  },
  chat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.expressColors.gray
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  activeChat: {
    backgroundColor: theme.palette.expressColors.strongGreen
  },
  inactiveChat: {
    paddingLeft: theme.spacing(1)
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  header: {
    display: 'flex'
  },
  newMessageIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.expressColors.lightRed
  }
}));

export const useConversationStyles = makeStyles(theme => ({
  conversationContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '100%'
  },
  container: {
    position: 'relative',
    top: '-16px',
    left: 0,
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '16px',
    width: 488
  },
  messagesContainer: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  cockpitContainer: {
    display: 'flex',
    marginTop: theme.spacing(3)
  },
  messageBox: {
    padding: `0  100px ${theme.spacing(1)}px 0`,
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    backgroundColor: theme.palette.expressColors.lowLightGreen,
    borderRadius: '7px',
    padding: `8px 8px 8px 8px`
  },
  messageTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: '8px',
    color: theme.palette.expressColors.strongGreen
  },
  messageText: {
    fontWeight: 'normal'
  },
  messagetime: {
    align: 'right',
    marginRight: 0,
    fontWeight: 300,
    fontSize: 12
  },
  messageData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'upperCase',
    '& .MuiTypography-body1': {
      color: theme.palette.expressColors.strongGreen,
      fontSize: 14,
      fontWeight: 'bold',
      backgroundColor: theme.palette.expressColors.lowLightYellow,
      borderRadius: 6,
      padding: 1
    },
    margin: `${theme.spacing(1)}px 0`
  },
  avatar: {
    margin: `0 ${theme.spacing(1)}px`
  },
  ownMessage: {
    display: 'flex',
    flexDirection: 'row-reverse',
    borderRadius: 10,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px 100px`,
    marginBottom: theme.spacing(1)
  },
  chatTitleContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    height: 91,
    margin: '-16px -16px 0',
    padding: '16px 16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 6px #00000029',
    borderBottom: `1px solid ${theme.palette.expressColors.green}`,
    background: '#FFFFFF'
  },
  chatTitle: {
    '& .MuiTypography-body1': {
      fontWeight: 600,
      fontSize: '20px'
    },
    display: 'flex',
    alignItems: 'center'
  },
  iconAddCircle: {
    '& svg': {
      fontSize: 20
    },
    color: theme.palette.expressColors.green,
    stroke: theme.palette.expressColors.white,
    strokeWidth: 1.5
  },
  expressColorGreen: {
    color: theme.palette.expressColors.green
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  badge: {
    marginBottom: 5,
    marginLeft: '-3px'
  },
  iconSend: {
    marginBottom: '-3px'
  },
  minimizedContainer: {
    width: 488,
    display: 'flex',
    justifyContent: 'space-between'
  },
  minimizedIconsContainer: {
    display: 'flex',
    alignItems: 'baseline'
  },
  icon: {
    cursor: 'pointer',
    color: theme.palette.expressColors.green,
    marginTop: '25px'
  }
}));

export const useDetailsStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(3)
  }
}));
