/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { updateOrder } from '../../../services/orderServices';
import {
  ORDER_TYPE,
  ORDER_STATUS,
  DELIVERY_STATUS,
  TICKET_STATUS
} from '../../../dto/enum';
import ProgressCircleTimer from './ProgressCircleTimer';
import { COLORTYPES } from './constants';
import { useListOrderCardStyles } from './styles';
import { createMomentOnBolivianTime } from '../../../utils/date';

const ListOrderCard = props => {
  const {
    orderId,
    activeOrder,
    orderStatus,
    orderType,
    orderCode,
    orderCookingTime,
    acceptedTime,
    userFullName,
    createdAt,
    onClickedCard,
    driverId,
    driverName,
    deliveryStatus,
    amountOfProducts,
    total,
    deliveryTime
  } = props;
  const classes = useListOrderCardStyles();
  const translation = useTranslation();
  const isDelivery = orderType === ORDER_TYPE.DELIVERY;
  const name = driverName || userFullName;
  const isActive = orderId === activeOrder;
  const inProcess =
    orderStatus === ORDER_STATUS.ACCEPTED ||
    orderStatus === ORDER_STATUS.READY_TO_PICK ||
    orderStatus === ORDER_STATUS.ACCEPTED.READY_TO_CAHIER;
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  if (
    deliveryStatus === DELIVERY_STATUS.ON_PLACE &&
    orderStatus !== ORDER_STATUS.READY_TO_CASHIER
  ) {
    updateOrder(orderId, {
      user_nearby: true,
      ticket_status: TICKET_STATUS.ON_GOING,
      status: ORDER_STATUS.READY_TO_CASHIER
    });
  }

  const getDeliveryLabel = () => {
    if (!driverId)
      return {
        type: COLORTYPES.WARNING,
        label: translation('orders_view.card.driver_unassigned')
      };

    switch (deliveryStatus) {
      case DELIVERY_STATUS.UNASSIGNED:
        return {
          type: COLORTYPES.WARNING,
          label: translation('orders_view.card.driver_unassigned')
        };
      case DELIVERY_STATUS.REQUESTED:
        return {
          type: COLORTYPES.WARNING,
          label: `${translation('orders_view.card.driver_requested')}`
        };
      case DELIVERY_STATUS.GOING_TO_PICK:
        return {
          type: COLORTYPES.REGULAR,
          label: `${name} ${translation(
            'orders_view.card.driver_going_to_pick'
          )}`
        };
      case DELIVERY_STATUS.ON_PLACE:
        return {
          type: COLORTYPES.REGULAR,
          label: `${name} ${translation('orders_view.card.driver_on_place')}`
        };
      case DELIVERY_STATUS.DELIVERING:
        return {
          type: COLORTYPES.REGULAR,
          label: `${name} ${translation('orders_view.card.driver_delivering')}`
        };
      case DELIVERY_STATUS.COMPLETED:
        return {
          type: COLORTYPES.REGULAR,
          label: `${name} ${translation('orders_view.card.driver_completed')}`
        };
      default:
        return {
          type: COLORTYPES.REGULAR,
          label: translation('orders_view.card.driver_unhadled')
        };
    }
  };

  const getCardContent = (label = '') => {
    let labelObject = { type: COLORTYPES.REGULAR, label };
    if (isDelivery) labelObject = getDeliveryLabel();
    return (
      <Typography
        className={clsx({
          [classes.greenText]: labelObject.type === COLORTYPES.REGULAR,
          [classes.redText]: labelObject.type === COLORTYPES.WARNING,
          [classes.activeTextInfo]: isActive
        })}
        variant="h6"
        style={{ fontWeight: 'bold' }}
      >
        {labelObject.label}{' '}
        {isDelivery ? (
          <span role="img" aria-label="bike">
            🛵
          </span>
        ) : (
          <span role="img" aria-label="running">
            🏃
          </span>
        )}
      </Typography>
    );
  };

  const getText = () => {
    if (orderStatus === ORDER_STATUS.IN_PROCESS)
      return (
        <Typography style={{ fontWeight: 'bold' }} variant="h6">
          {`
        ${translation(
          'orders_view.card.received'
        )} ${createMomentOnBolivianTime(createdAt.toDate()).format('LT')}
      `}
        </Typography>
      );
    switch (orderStatus) {
      case ORDER_STATUS.ACCEPTED:
        return getCardContent(
          `${name} ${translation('orders_view.card.pickup_arriving')}`
        );
      case ORDER_STATUS.READY_TO_PICK:
        return getCardContent(
          `${name} ${translation('orders_view.card.pickup_arriving')}`
        );
      case ORDER_STATUS.READY_TO_CASHIER:
        return getCardContent(
          `${name} ${translation('orders_view.card.pickup_here')}`
        );
      case ORDER_STATUS.DELIVERING:
        return getCardContent();
      default:
        return false;
    }
  };

  const changeOrderToReadyToPickup = async () => {
    if (
      orderStatus === ORDER_STATUS.READY_TO_PICK ||
      orderType === ORDER_TYPE.DELIVERY
    )
      return null;
    const finishTime = createMomentOnBolivianTime().toDate();
    await updateOrder(orderId, {
      status: ORDER_STATUS.READY_TO_PICK,
      delivery_time: finishTime
    });
    return true;
  };

  const getStyles = () => {
    if (isActive) return clsx(classes.activeCard);
    return clsx(classes.card);
  };

  return (
    <Box width="100%" style={{ position: 'relative' }}>
      <Card className={getStyles()}>
        <CardContent className={clsx(classes.cardContent)}>
          <Grid
            item
            container
            className={classes.container}
            onClick={() => onClickedCard(orderId)}
          >
            <Box
              className={clsx(classes.chipsContainer, {
                [classes.chispsContainerCompleted]: !inProcess
              })}
            >
              {orderStatus !== ORDER_STATUS.DELIVERING && (
                <Box className={clsx(classes.orderType)}>
                  <Typography className={clsx(classes.smallCardsText)}>
                    {translation(
                      `${
                        orderType === ORDER_TYPE.PICK_UP
                          ? 'orders_view.card.to_pickup'
                          : 'orders_view.card.to_delivery'
                      }`
                    )}
                  </Typography>
                </Box>
              )}
            </Box>

            <Grid
              item
              container
              xs={orderStatus === ORDER_STATUS.ACCEPTED ? 9 : 12}
              lg={orderStatus === ORDER_STATUS.ACCEPTED ? 9 : 12}
            >
              <Box width="100%" className={clsx(classes.textContainer)}>
                <Typography
                  className={clsx(classes.orderText, {
                    [classes.activeText]: isActive
                  })}
                >
                  <strong>
                    {`#${orderCode ||
                      translation('orders_view.card.processing')}`}
                  </strong>
                  <span>&nbsp;&nbsp;</span>
                </Typography>
              </Box>
              <Box width="100%" className={clsx(classes.textContainer)}>
                <Typography
                  className={clsx(classes.contentText, {
                    [classes.activeText]: isActive
                  })}
                >
                  {`${amountOfProducts} ${translation(
                    'orders_view.card.items'
                  )}`}
                  <strong>({total} Bs.)</strong>
                </Typography>
              </Box>
              {!inProcess && (
                <Box width="100%" className={clsx(classes.textContainer)}>
                  <Typography
                    className={clsx({
                      [classes.timeText]: !isActive,
                      [classes.activeTextInfo]: isActive
                    })}
                  >
                    {`${translation('orders_view.card.delivery_time')} 
                    ${createMomentOnBolivianTime(deliveryTime.toDate()).format(
                      'HH:mm'
                    )}`}
                  </Typography>
                </Box>
              )}
              <Box width="100%">{getText()}</Box>
            </Grid>
            {orderStatus === ORDER_STATUS.ACCEPTED && (
              <Grid
                className={classes.rigthContainer}
                item
                container
                xs={3}
                sm={3}
                lg={3}
              >
                <ProgressCircleTimer
                  cookingTime={orderCookingTime}
                  acceptedTime={acceptedTime}
                  showMinutes
                  isDone={changeOrderToReadyToPickup}
                />
              </Grid>
            )}
          </Grid>
          {!isXS && isActive && (
            <PlayArrowIcon
              className={clsx(classes.playIcon, {
                [classes.onGoingPlayIcon]:
                  orderStatus === ORDER_STATUS.DELIVERING
              })}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListOrderCard;
