import authService from 'src/services/authService';
import firebaseService from 'src/services/firebaseService';
import Restaurant from 'src/dto/restaurant';
import Subsidiary from 'src/dto/subsidiary';
import admin from 'src/config/firebaseConfig';
import {
  isSuperAdmin,
  isATC,
  isMarketingUser,
  isDriver
} from '../utils/helpers/userHelper';
import { REGISTER_STATUS } from '../dto/enum';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_SUBSIDIARY = '@account/update-subisdiary';
export const UPDATE_SCHEDULE = '@account/update-schedule';

export function login(email, password, loginAdmin) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      let user = '';
      if (email && password)
        user = await authService.loginWithEmailAndPassword(
          email,
          password,
          loginAdmin
        );
      // if (!user.restaurant_id) {
      //   throw new Error("This user doesn't belong to any restaurant");
      // }
      if (email === 'google') user = await authService.loginWithGoogle();
      if (email === 'facebook') user = await authService.loginWithFacebook();
      // Token safeguard
      try {
        const token = await admin.messaging().getToken();
        await firebaseService.setDoc(`users/${user.id || user.uid}`, {
          ...user,
          device_id: admin.firestore.FieldValue.arrayUnion(token)
        });
        user.token = token;
      } catch (error) {
        user.token = null;
      }
      const payload = { user };
      if (
        isATC(user) ||
        isSuperAdmin(user) ||
        isMarketingUser(user) ||
        isDriver(user)
      ) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload
        });
        return '';
      }
      const rest = await firebaseService.getDbRestaurant(user.restaurant_id);
      if (rest?.xpress_motos === true) {
        let restaurant = await firebaseService.getDocumentWithJoin(
          'restaurants',
          user.restaurant_id,
          'subsidiary',
          'restaurant_id',
          user.restaurant_id
        );
        let subsidiaries = [];
        if (restaurant.register_status === REGISTER_STATUS.ACTIVE) {
          subsidiaries = restaurant.subsidiary.filter(
            s => s.register_status === REGISTER_STATUS.ACTIVE
          );
        }
        if (restaurant.register_status === REGISTER_STATUS.SUSPENDED) {
          subsidiaries = restaurant.subsidiary.filter(
            s => s.register_status === REGISTER_STATUS.SUSPENDED
          );
        }
        restaurant = new Restaurant(restaurant);
        subsidiaries = subsidiaries.map(
          subsidiary => new Subsidiary(subsidiary)
        );
        restaurant.subsidiary = subsidiaries;
        payload.restaurant = restaurant;
        // console.log(user, restaurant, subsidiaries);
        dispatch({
          type: LOGIN_SUCCESS,
          payload
        });
        return '';
      }
      authService.logout();

      dispatch({
        type: LOGOUT
      });
      return 'xpress_motos';
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user, restaurant) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
        restaurant
      }
    });
}

export function logout() {
  return async dispatch => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(user) {
  return dispatch =>
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user
      }
    });
}
