import Log, { LogTypes } from './log';
import LoggerApp from './config';
import { ENABLE_LOGS, LOG_COLLECTION } from './env';

const Logger = {
  error({ error = {}, data }) {
    if (ENABLE_LOGS && LOG_COLLECTION) {
      const timestamp = new Date().getTime();
      LoggerApp.firestore()
        .collection(LOG_COLLECTION)
        .doc(`${timestamp}`)
        .set({
          ...new Log({
            error: JSON.parse(
              JSON.stringify(error, Object.getOwnPropertyNames(error))
            ),
            data,
            type: LogTypes.ERROR
          })
        })
        .then(r => r)
        .catch(r => r);
    }
  }
};

export default Logger;
