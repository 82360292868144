import { makeStyles } from '@material-ui/core';
import { COLORS_ENTREGAS_COM } from '../../theme/colors';

const commerceRegisterStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '46px'
  },
  containerForm: {
    border: `1px solid ${COLORS_ENTREGAS_COM.primary}`,
    borderRadius: '10px',
    width: '880px',
    height: '822',
    padding: '20px 63px'
  },
  containerNextButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '30px'
  },
  textTitle: {
    fontSize: '28px',
    color: COLORS_ENTREGAS_COM.secondary,
    fontWeight: 'normal',
    padding: '0px !important',
    marginBottom: '20px',
    marginTop: '20px'
  },
  [theme.breakpoints.down('969')]: {
    textTitleResponsive: {
      fontSize: '28px',
      color: COLORS_ENTREGAS_COM.secondary,
      textAlign: 'center',
      fontWeight: 'normal',
      padding: '0px !important',
      marginBottom: '20px',
      marginTop: '80px'
    },
    containerFormResponsive: {
      padding: '20px 14px'
    }
  }
}));

export default commerceRegisterStyles;
