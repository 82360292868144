/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import ConfirmationDialog from 'src/components/Dialog/ConfirmationDialog';

const initialState = {
  title: '',
  description: ''
};

export const ConfirmationContext = React.createContext(initialState);

export default function ConfirmationProvider({ children }) {
  const [confirmationState, setConfirmationState] = useState(null);

  const awaitingConfirmation = React.useRef();

  function confirm(options = {}) {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      // save the promise result to the ref
      awaitingConfirmation.current = { resolve, reject };
    });
  }

  const handleClose = () => {
    // Mostly always you don't need to handle canceling of alert dialog
    // So shutting up the unhandledPromiseRejection errors
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(false);
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(true);
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationContext.Provider value={confirm}>
        {children}
      </ConfirmationContext.Provider>
      <ConfirmationDialog
        open={Boolean(confirmationState)}
        handleAccept={handleSubmit}
        handleCancel={handleClose}
        question={confirmationState?.title}
        {...confirmationState}
      >
        {confirmationState?.description}
      </ConfirmationDialog>
    </>
  );
}
