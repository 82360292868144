/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import NavItem from './NavItem';
import {
  isCashier,
  isSupervisor,
  isOwner
} from '../../../utils/helpers/userHelper';
import RestaurantOptions from './RestaurantOptions';
import { shouldActiveOnRoutes } from '../../../utils/helpers/RouterHelper';
import { owner, cashier, supervisor } from './roleNavbar';

function renderNavItems({
  items,
  chatNewMessagesAmount,
  classToApply,
  ...rest
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            chatNewMessagesAmount,
            classToApply,
            item,
            ...rest
          }),
        []
      )}
    </List>
  );
}
function reduceChildRoutes({
  acc,
  classToApply,
  pathname,
  item,
  depth = 0,
  chatNewMessagesAmount
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        href={item.href}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        caption={item.caption}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key + Math.random()}
        info={item.info}
        title={item.title}
        caption={item.caption}
        id={item.id}
        item={item}
        templatem={item.template}
        className={clsx({
          [classToApply]: item.href === '/app/orders'
        })}
        chatAlert={item.chatAlert}
        chatNewMessagesAmount={chatNewMessagesAmount}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 280,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  activeSection: {
    background: theme.palette.expressColors.activeGreen,
    borderTop: `1px solid ${theme.palette.expressColors.activeGreen}`,
    borderBottom: `1px solid ${theme.palette.expressColors.activeGreen}`
  },
  section: {
    background: theme.palette.expressColors.greenCake,
    '&:hover': {
      background: theme.palette.expressColors.hoverGreen
    }
  }
}));

const NavBar = ({
  openMobile,
  onMobileClose,
  chatNewMessagesAmount,
  handleOpenNav
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:430px)');
  const { user } = useSelector(state => state.account);
  const translation = useTranslation();
  const [navConfig, setNavConfig] = useState([]);
  const putTranslation = array => {
    const navbar = array.map(item => ({
      ...item,
      subheader: translation(item.subheader)
    }));
    navbar.forEach(item => {
      const newItem = item;
      newItem.items = item.items.map(subItem => {
        const newSubItem = subItem;
        if (_.has(newSubItem, 'items')) {
          newSubItem.items = subItem.items.map(i => ({
            ...i,
            title: translation(i.title)
          }));
        }
        return {
          ...subItem,
          title: translation(subItem.title)
        };
      });
    });
    return navbar;
  };

  if (isCashier(user) && !navConfig.length) {
    const cashierNavbar = putTranslation(cashier);
    setNavConfig(cashierNavbar);
  }
  if (isSupervisor(user) && !navConfig.length) {
    const supervisorNavbar = putTranslation(supervisor);
    setNavConfig(supervisorNavbar);
  }

  if (isOwner(user) && !navConfig.length) {
    const ownerNavbar = putTranslation(owner);
    setNavConfig(ownerNavbar);
  }

  // useSetNavBarOrder(setNavConfig, navConfig);

  useEffect(() => {
    if (!isMobile && openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isMobile]);

  useEffect(() => {
    if (isMobile) {
      handleOpenNav(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <RestaurantOptions />
        <Divider />
        <Box>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              className={clsx({
                [classes.activeSection]: shouldActiveOnRoutes(
                  config.activeClass,
                  location.pathname
                )
              })}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  <Box ml={2}>{config.subheader}</Box>
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                chatNewMessagesAmount,
                pathname: location.pathname,
                classToApply: classes.section
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      {/* <Hidden lgUp> */}
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
      {/* </Hidden> */}
      {/* <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
