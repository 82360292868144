/* eslint-disable no-async-promise-executor */
import admin, { STORAGE_TIMEOUT } from 'src/config/firebaseConfig';
import { TimeoutException } from '../models/exceptions/exception';

export const saveFileToStorage = async (
  path,
  fileData,
  onUpload = () => {},
  withExtension = false
) => {
  return new Promise(async (resolve, reject) => {
    const storageRef = admin.storage().ref();
    const extension = fileData.name.split('.').pop();
    const reference = withExtension ? `${path}.${extension}` : `${path}`;
    const fileRef = storageRef.child(reference);
    const uploadTask = fileRef.put(fileData);
    let timeout = null;
    uploadTask.on(admin.storage.TaskEvent.STATE_CHANGED, data => {
      const percentage = Math.floor(
        (data.bytesTransferred * 100) / data.totalBytes
      );
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        uploadTask.cancel();
        reject(new TimeoutException());
      }, STORAGE_TIMEOUT);
      onUpload({ percentage });
    });
    await uploadTask;
    clearTimeout(timeout);
    const urlImage = await await uploadTask.snapshot.ref.getDownloadURL();
    resolve(urlImage);
  });
};
