import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-multi-lang/lib';

const AlertDialog = ({
  open,
  handleCancel,
  handleAccept,
  question,
  children,
  acceptLabel,
  cancelLabel,
  disabledButton = false,
  maxWidth = 'md'
}) => {
  const translation = useTranslation();
  // eslint-disable-next-line no-param-reassign
  if (!acceptLabel) acceptLabel = translation('accept');
  // eslint-disable-next-line no-param-reassign
  if (!cancelLabel) cancelLabel = translation('cancel');

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{question}</DialogTitle>

      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelLabel}
        </Button>
        <Button
          onClick={handleAccept}
          color="primary"
          autoFocus
          disabled={disabledButton}
        >
          {acceptLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
