/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function PrintItem({ value, label, separator = ': ', floatingLabel = '' }) {
  return (
    <section style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <label>{label}</label>
        {separator}
        <label>{value}</label>
      </div>
      <div>
        <label>{floatingLabel}</label>
      </div>
    </section>
  );
}

export default PrintItem;
