import firebase from 'firebase';

export const LogTypes = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARN: 'WARN'
};

export default class Log {
  constructor({
    createdAt = firebase.firestore.FieldValue.serverTimestamp(),
    error = null,
    data = null,
    type = LogTypes.INFO
  }) {
    this.createdAt = createdAt;
    this.error = error;
    this.data = data;
    this.type = type;
  }
}
