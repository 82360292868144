import React from 'react';
import { Card, Grid } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-multi-lang/lib';
import { useInformationDriverStyles } from './styles';

const Facturation = ({ order }) => {
  const classes = useInformationDriverStyles();
  const translation = useTranslation();

  return (
    <Card className={clsx(classes.root)}>
      <Box className={classes.header}>
        <Typography className={clsx(classes.text, classes.noMarging)}>
          <strong>{translation('orders.details.driver_card.title')}</strong>
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.text}>
          <strong>{translation('orders.details.driver_card.full_name')}</strong>
          <span>&nbsp;&nbsp;</span>
          {`${order.driver.first_name} ${order.driver.last_name}`}
        </Typography>

        <Grid container>
          <Typography className={classes.text}>
            <strong>
              {translation('orders.details.driver_card.driver_phone')}
            </strong>
            <span>&nbsp;&nbsp;</span>
            {order.driver.phone_number
              ? order.driver.phone_number.substr(4)
              : '-'}
          </Typography>
        </Grid>
      </Box>
    </Card>
  );
};

export default Facturation;
