export const SET_FILTER_HISTORY = '@filterHistoryl/set';
export const DEFAULT_STATE_HISTORY = '@filter/setDefaultStateHistory';

export const setFilterHistory = fields => dispath =>
  dispath({
    type: SET_FILTER_HISTORY,
    payload: { fields }
  });

export const setDefaultFields = () => dispath => {
  dispath({
    type: DEFAULT_STATE_HISTORY
  });
};
