// eslint-disable-next-line import/prefer-default-export
export function getNumberDecimalFixed(number, digits) {
  return parseFloat(parseFloat(number).toFixed(digits));
}

export const nextFibonacci = number =>
  Math.round((number * (1 + Math.sqrt(5))) / 2.0);

export const getPriceNumber = value =>
  (Math.round(value * 100) / 100).toFixed(2);
