const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

export default function calculateDistance(driver, sub) {
  const lat1 = driver.location.Pc;
  const lat2 = sub.location.Pc;
  const lon1 = driver.location.Vc;
  const lon2 = sub.location.Vc;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d * 1000;
}
