import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import {
  Card,
  Box,
  useTheme,
  useMediaQuery,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useDescriptionStyles } from './styles';

const Description = ({ order, onlyContent = false }) => {
  const classes = useDescriptionStyles();
  const translation = useTranslation();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  if (!order || !order.additional_description) {
    return null;
  }

  if (onlyContent) {
    return (
      <>
        <Box mt={2} mb={2}>
          <Typography variant="body1">
            {order.additional_description}
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <Card>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={isXS ? null : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.header}
        >
          <Box className={classes.textContainer}>
            <Typography className={classes.text}>
              <strong>
                {translation('orders.details.additional_reference.title')}
              </strong>
            </Typography>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box mt={2} mb={2}>
            <Typography className={classes.text}>
              {order.additional_description}
            </Typography>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
};

export default Description;
