import React from 'react';
import LoginTemplate from '../../auth/LoginView/LoginTemplate';
import { ROUTES_PATHS } from '../../../constants';

const DriverLogin = () => {
  return (
    <LoginTemplate
      withSocialNetwork={false}
      loginAdmin={false}
      registerPath={ROUTES_PATHS.DRIVER_REGISTER}
    />
  );
};

export default DriverLogin;
