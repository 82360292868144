import React from 'react';
import { Grid, Typography, makeStyles, Dialog } from '@material-ui/core';
import { SimpleOrangeButton } from '../Inputs/Buttons';

const styles = {
  backDrop: {
    background: '#833C25',
    opacity: 0.7
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: 50,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1
  },
  buttonContainer: {
    justifyContent: 'center',
    gap: '10px'
  },
  textTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    paddingTop: 50,
    paddingBottom: 40
  },
  buttonConfirm: {
    fontSize: 16,
    height: 46,
    width: 141,
    padding: '5px 15px',
    color: 'white'
  },
  icon: {
    width: 164,
    height: 163
  },
  paper: {
    width: '95%',
    maxWidth: 700,
    maxHeight: 535
  }
}));

const ErrorModal = React.memo(
  ({
    isOpen = false,
    confirm = () => {},
    title = null,
    buttonText = 'Aceptar',
    icon
  }) => {
    const classes = useStyles();
    return (
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{
          style: styles.backDrop
        }}
      >
        <Grid
          className={classes.container}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <img src={icon} alt="ghost icon" className={classes.icon} />
          <Grid item>
            <Typography className={classes.textTitle}>{title}</Typography>
          </Grid>

          <Grid
            item
            className={classes.buttonContainer}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            container
          >
            <Grid item>
              <SimpleOrangeButton
                className={classes.buttonConfirm}
                fullWidth
                onClick={confirm}
              >
                {buttonText}
              </SimpleOrangeButton>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
);

export default ErrorModal;
