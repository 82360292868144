export const registerStatuses = {
  pending: 'pending',
  rejected: 'rejected',
  accepted: 'accepted'
};

export const ORDER_STATUS = {
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  IN_PROCESS: 'IN_PROCESS',
  NEED_CHANGES: 'NEED_CHANGES',
  ACCEPTED: 'ACCEPTED',
  ON_CART: 'ON_CART',
  READY_TO_PICK: 'READY_TO_PICK',
  CHANGES_ACCEPTED: 'CHANGES_ACCEPTED',
  READY_TO_CASHIER: 'READY_TO_CASHIER',
  DELIVERING: 'DELIVERING',
  SCHEDULE: 'SCHEDULE',
  MAKE_ORDER: 'MAKE_ORDER',
  MAKE_MULTIPLE_DESTINATIONS: 'MAKE_MULTIPLE_DESTINATIONS'
};

export const ORDER_TYPE = {
  PICK_UP: 'PICK_UP',
  DELIVERY: 'DELIVERY',
  XPRESS_MOTOS: 'XPRESS_MOTOS'
};
export const XPRESS_ORDER_TYPE = {
  NORMAL: 'NORMAL',
  MULTIPLE_DESTINATIONS_PARENT: 'MULTIPLE_DESTINATIONS_PARENT',
  MULTIPLE_DESTINATIONS_CHILD: 'MULTIPLE_DESTINATIONS_CHILD'
};

export const DELIVERY_STATUS = {
  UNASSIGNED: 'UNASSIGNED',
  REQUESTED: 'REQUESTED',
  GOING_TO_PICK: 'GOING_TO_PICK',
  ON_PLACE: 'ON_PLACE',
  DELIVERING: 'DELIVERING',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED'
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  ARCHIVED: 'ARCHIVED'
};

export const TICKET_STATUS = {
  WAITING: 'WAITING',
  ON_GOING: 'ON_GOING',
  PASSED: 'PASSED'
};

export const USER_ROLES = {
  OWNER: 'OWNER',
  CASHIER: 'CASHIER',
  SUPERVISOR: 'SUPERVISOR',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ACCOUNTANT: 'ACCOUNTANT',
  CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
  CUSTOMER_SERVICE_ADMIN: 'CUSTOMER_SERVICE_ADMIN',
  MARKETING: 'MARKETING'
};

export const categoryOptions = [
  { value: 'MEAT', label: 'Carne' },
  { value: 'SOUPS', label: 'Sopas' },
  { value: 'SANDWICH', label: 'Sandwiches' },
  { value: 'PIZZA', label: 'Pizza' },
  { value: 'SUSHI', label: 'Sushi' },
  { value: 'PASTA', label: 'Pasta' },
  { value: 'WRAPS', label: 'Wraps' },
  { value: 'SEAFOOD', label: 'Comida de Mar' },
  { value: 'CHICKEN', label: 'Pollo' },
  { value: 'SALAD', label: 'Ensalada' },
  { value: 'VEGETARIAN_FOOD', label: 'Comida Vegetariana' },
  { value: 'DESSERTS_AND_SWEETS', label: 'Postres y Dulces' }
];

export const docStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
};

export const INVOICE_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  REJECTED: 'REJECTED',
  INVALID: 'INVALID',
  PAST: 'PAST'
};

export const TYPE_OF_DISCOUNT = {
  PERCENTAGE: 'PERCENTAGE',
  CASH: 'CASH'
};

export const DRIVER_STATUS = {
  UNAVAILABLE: 'UNAVAILABLE',
  WAITING_FOR_ORDERS: 'WAITING_FOR_ORDERS',
  GOING_TO_PICK: 'GOING_TO_PICK',
  ON_PLACE: 'ON_PLACE',
  DELIVERING: 'DELIVERING'
};

export const PAYMENT_METHOD = {
  ONLINE_CARD: 'ONLINE_CARD',
  ONLINE_QR: 'ONLINE_QR',
  CASH: 'CASH',
  LIBELULA: 'LIBELULA',
  QR: 'QR'
};

export const REGISTER_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  SUSPENDED: 'SUSPENDED',
  SUBSCRIPTION_EXPIRED: 'SUBSCRIPTION_EXPIRED'
};

export const DRIVER_APPLICATION_STATUS = {
  APPLIED: 'APPLIED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED',
  EXPIRED_LICENSE: 'EXPIRED_LICENSE',
  EXPIRED_SOAT: 'EXPIRED_SOAT',
  IN_PROCESS: 'IN_PROCESS'
};

export const TRANSPORTATION_TYPE = {
  CAR: 'CAR',
  MOTO: 'MOTO',
  BICYCLE: 'BICYCLE'
};

export const PROMO_BANNER_TYPE = {
  RESTAURANT: 'RESTAURANT',
  CATEGORY: 'CATEGORY',
  PRODUCT: 'PRODUCT',
  INFO: 'INFO'
};

export const UNVERIFIED_REASONS = {
  HIGH_COST: 'HIGH_COST',
  DRIVER_ORDER: 'DRIVER_ORDER',
  FIRST_ORDER: 'FIRST_ORDER',
  OTHER: 'OTHER'
};

export const ORDER_SERVICES = {
  PICKUP: 'PICK_UP',
  DELIVERY: 'DELIVERY'
};

export const LOG_SOURCE = {
  ADMIN: 'ADMIN',
  DRIVER: 'DRIVER',
  USER: 'USER',
  MERCHANT: 'MERCHANT',
  BOT: 'BOT'
};

export const LOG_ACTION = {
  STATUS_CHANGED: 'STATUS_CHANGED',
  DRIVER_CHANGED: 'DRIVER_CHANGED',
  VERIFIED: 'VERIFIED',
  NOTIFY_CLIENT: 'NOTIFY_CLIENT'
};

export const TYPE_CHARGE = {
  COMMISSION: 'COMMISSION',
  SUBSCRIPTION: 'SUBSCRIPTION'
};

export const TYPE_CODE_VALIDATION = {
  IS_NEW_USER: 'IS_NEW_USER',
  EXIST_WITHOUT_EMAIL_AUTH: 'EXIST_WITHOUT_EMAIL_AUTH',
  EXIST_WITH_EMAIL_AUTH: 'EXIST_WITH_EMAIL_AUTH',
  CREDENTIALS_DONT_MATCH: 'CREDENTIALS_DONT_MATCH',
  UNIQUE_USER: 'USER_EXIST'
};

export const DRIVER_LEVELS = {
  INTERMEDIATE: 'INTERMEDIATE',
  ADVANCED: 'ADVANCED',
  EXPERT: 'EXPERT',
  NOVICE: 'NOVICE'
};
