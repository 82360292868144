// eslint-disable-next-line import/prefer-default-export
export const COLORS = {
  white: '#FFFFFF',
  danger: '#C23F25',
  dangerDark: '#F75B3B',
  successDark: '#56B19E',
  success: '#4E9788',
  successLight: '#B2E7D8',
  defaultPage: '#F2F2F2',
  primary: '#FF935b',
  primaryDark: '#CC7548',
  primaryLight: '#FFa87b',
  disabledGray: '#F2F2F2',
  lightGray: '#797979',
  red: '#C23F25',
  lightRed: '#F75B3B',
  lightYellow: '#FFEA85',
  green: '#56B19E',
  greenLight: '#56B19E12',
  lightGreen: '#EBF9F5',
  greenDark: '#637A75',
  strongGreen: '#1F6456',
  greenCake: '#EAF8F4',
  redCake: '#FFEEEB',
  yellowCake: '#FFF3BB',
  ordersCockpitBackground: '#A2E1D9',
  orderCockpitActiveCard: '#7BCCBB',
  orderCardGreenText: '#55B19B',
  topbarButons: '#FFE255',
  lightBlue: '#1DA1F2',
  activeGreen: '#232323',
  hoverGreen: '#C7F3E7',
  yellowLink: '#E3BF12',
  lowLightGreen: '#B2E7D833',
  lowLightYellow: '#FFEA8580',
  redLight: '#EE675C',
  black: '#000000',
  rateQualifGray: '#71807d',
  rateQualifiGrayDark: '#f0f6f6',
  rateQualifGreen: '#b1e7d9',
  rateQualifGreenDark: '#6ca999',
  rateQualifRed: '#f57150',
  rateQualifRedDark: '#cd4629',
  rateLikedUncheck: '#F0F6F5',
  rateDislikedCheck: '#F87052',
  rateBorderGray: '#E6E3E8',
  rateTextLikedCheck: '#4E9788',
  rateTextDislikedCheck: '#A8331C',
  greenLightButton: '#6BBAA7',
  lineBar: '#CDCDCD',
  dot: '#A9A9A9',
  trackingLine: '#E7E7E7',
  orange: '#F7955E',
  orangeDark: '#f4782d',
  blackLight: '#3E3E3E',
  secondaryGreen: '#18BAA7'
};
export const COLORS_ENTREGAS_COM = {
  white: '#FFFFFF',
  black: '#000',
  lightGray: '#CDCDCD',
  gray: '#A098AE',
  orange: '#F7955E',
  green: '#02B2A4',
  yellow: '#F8B602',
  primary: '#F47F61',
  primaryLight: '#F58960',
  primaryDark: '#F1656A',
  secondary: '#00B2A4',
  textDark: '#232323',
  textLight: '#FFFFFF',
  textLightGray: '#A098AE',
  backgroundLightGray: '#F3F3F3',
  textGray: '#949B9F',
  textGrayDark: '#3E3E3E',
  textOrange: '#F9B28E',
  textOrangeDark: '#DF6265',
  primaryGradiant: `radial-gradient(41.48% 11.48% at 2.38% 8.52%, #F47F61 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, #F58960 0%, #F1656A 100%)`,
  backGroundGradient:
    'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,234,222,1) 100%)',
  transparent: 'rgba(255, 255, 255, 0.14)',
  red: '#F44336',
  lightRed: '#EB5757',
  lightGrayColor: '#D8D8D8'
};
