const ACTIONS = {
  SET_MODAL_CHAT: 'SET_MODAL_CHAT'
};

const initial_state = {
  open: false
};

export function setModalChat({ open }) {
  return { type: ACTIONS.SET_MODAL_CHAT, data: { open } };
}

export default function modalChat(state = initial_state, { type, data }) {
  switch (type) {
    case ACTIONS.SET_MODAL_CHAT:
      return { ...state, ...data };
    default:
      return { ...state };
  }
}
