import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { ORDER_STATUS, ORDER_TYPE, XPRESS_ORDER_TYPE } from '../../../dto/enum';
import { getDate, createMomentOnBolivianTime } from '../../../utils/date';
import OrderPrinting from '../OrderPrinting/OrderPrinting';
import PrintButton from './PrintButton';
import { usePromotion } from '../../../hooks/Promotions/usePromotions';
import { useHeaderStyles } from './styles';

function Header({ order, orderInProcess, modal, isNotification }) {
  const classes = useHeaderStyles();
  const translation = useTranslation();
  const dataOrder = { ...order };
  const promotion = usePromotion(order?.promotionId);

  const getOrder = () => {
    if (!order.order_code) return translation('orders_view.card.processing');
    const word = translation('orders_view.card.order');
    if (
      order.xpress_order_type === XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_PARENT
    )
      return `${word} #${order.order_code}`;
    return `${word} #${order.order_code.split('-')[0]}-${
      order.order_code.split('-')[1]
    }`;
  };

  if (dataOrder.delivery_time) {
    dataOrder.delivery_time = createMomentOnBolivianTime(
      getDate(dataOrder.delivery_time)
    );
  }

  let deliveryText = 'pickup';
  if (order.order_type === ORDER_TYPE.DELIVERY) {
    if (
      order.xpress_order_type === XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_PARENT
    ) {
      deliveryText = 'multiple_destinations';
    } else deliveryText = 'delivery';
  }
  if (order.order_type === ORDER_TYPE.XPRESS_MOTOS)
    deliveryText = 'xpress_motos';

  const hourCard = () => (
    <Box className={clsx(classes.hourContainer, classes.yellowBackground)}>
      <Typography variant="h4" color="textPrimary" className={classes.timeText}>
        {`${
          order.status !== ORDER_STATUS.IN_PROCESS
            ? translation('orders.details.header.time_delay')
            : translation('orders.details.header.order_time')
        } `}
        {createMomentOnBolivianTime(dataOrder.delivery_time).format('HH:mm')}
      </Typography>
    </Box>
  );

  return (
    <Grid container className={clsx(classes.root, { [classes.modal]: modal })}>
      <Grid
        item
        container
        xs={12}
        sm={6}
        md={9}
        wrap="nowrap"
        className={classes.firstBlock}
      >
        <Grid item container className={classes.orderContainer}>
          <Box
            className={clsx(classes.orderContainer, classes.greenBackground)}
          >
            <Typography className={classes.orderText}>{getOrder()}</Typography>
          </Box>
        </Grid>

        <Grid
          item
          container
          className={clsx(classes.orderContainer, classes.threeItemsMargin)}
        >
          <Box
            className={clsx(classes.orderContainer, classes.yellowBackground)}
          >
            <Typography className={classes.deliveryText}>
              {translation(`orders_view.header.${deliveryText}`)}
            </Typography>
          </Box>
        </Grid>
        {order.xpress_order_type !==
          XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_PARENT && (
          <Hidden smDown>
            <Grid item container>
              {hourCard()}
            </Grid>
          </Hidden>
        )}
      </Grid>
      {order.xpress_order_type !==
        XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_PARENT && (
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          className={classes.secondBlock}
        >
          <Hidden mdUp>{hourCard()}</Hidden>
          {!isNotification && (
            <Box onClick={orderInProcess}>
              <OrderPrinting order={{ ...order, promotion }} />
              <PrintButton
                text={
                  order.status === ORDER_STATUS.IN_PROCESS
                    ? translation('orders_view.cockpit.buttons.accept_print')
                    : translation('orders_view.cockpit.buttons.print')
                }
                propsClasses={clsx(classes.button, {
                  [classes.buttonsWithWidth]:
                    order.status !== ORDER_STATUS.IN_PROCESS
                })}
              />
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
}

Header.propTypes = {
  order: PropTypes.object.isRequired
};

export default Header;
