import { useFirestoreConnect } from 'react-redux-firebase';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ORDER_STATUS, ORDER_TYPE, XPRESS_ORDER_TYPE } from '../../dto/enum';
import { useRestaurant } from '../useRestaurant';
import { COLLECTIONS } from '../../constants';
import admin from '../../config/firebaseConfig';

export const getOrderNumber = order => {
  if (order.order_code) return order.order_code;
  return false;
};

export function useSubsidiaryOrders() {
  const subsidiary = useRestaurant();
  useFirestoreConnect({
    collection: COLLECTIONS.ORDER,
    where: ['restaurant_id', '==', subsidiary.id],
    storeAs: 'all_orders'
  });
  const all_orders = useSelector(
    state => state.firestore.ordered.all_orders || []
  );
  return all_orders;
}

export const useOrderBeenUse = orderId => {
  useFirestoreConnect({
    collection: COLLECTIONS.ORDER,
    where: ['id', '==', orderId],
    storeAs: 'order_been_use'
  });
  const orderBeenUse = useSelector(
    state => state.firestore.ordered.order_been_use
  );
  if (orderBeenUse) return orderBeenUse[0];
  return {};
};

export const useNewOrders = (limit = 0, lastOne = false) => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  const newOrders = Orders?.filter(
    value => value.status === ORDER_STATUS.IN_PROCESS && value.verified === true
  );
  if (newOrders && newOrders.length > 0) {
    const newDelivery = newOrders.filter(
      o => o.driver_id && o.order_type === ORDER_TYPE.DELIVERY
    );
    const newPickup = newOrders.filter(
      o => o.order_type === ORDER_TYPE.PICK_UP
    );
    let concatOrders = newDelivery.concat(newPickup);
    if (limit) {
      const showDay = moment()
        .startOf('day')
        .subtract(limit, 'days')
        .toDate();
      concatOrders = concatOrders.filter(
        value => showDay <= new Date(value.created_at.seconds * 1000)
      );
    }
    if (lastOne) return concatOrders[0];

    return concatOrders;
  }
  return [];
};

export const useOrdersInProcess = limit => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  let orderProcess = Orders?.filter(
    value =>
      (value.status === ORDER_STATUS.ACCEPTED ||
        value.status === ORDER_STATUS.READY_TO_PICK ||
        value.status === ORDER_STATUS.READY_TO_CASHIER) &&
      value.xpress_order_type !== XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_CHILD
  );
  if (orderProcess) {
    if (limit) {
      const showDay = moment()
        .startOf('day')
        .subtract(limit, 'days')
        .toDate();
      orderProcess = orderProcess.filter(
        value => showDay <= new Date(value.created_at.seconds * 1000)
      );
    }
    return orderProcess;
  }
  return [];
};

export const useOrdersNoCompleted = changedDriver => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  let orderProcess = Orders?.filter(
    value => value.status !== ORDER_STATUS.COMPLETED
  );
  if (orderProcess) {
    const showDay = moment()
      .startOf('day')
      .subtract(1, 'days')
      .toDate();
    orderProcess = orderProcess.filter(
      value => showDay <= new Date(value.created_at.seconds * 1000)
    );

    if (changedDriver) {
      const filterOrder = Orders?.filter(
        value =>
          value.status !== ORDER_STATUS.COMPLETED &&
          value.driver_id !== '' &&
          !value.check_from_motos
      );
      if (filterOrder.length === 0) return false;
      return filterOrder[0];
    }
    return orderProcess || [];
  }
  return [];
};

export const useOrdersBeenDelivery = limit => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  let orderProcess = Orders?.filter(
    value =>
      value.status === ORDER_STATUS.DELIVERING &&
      value.xpress_order_type !== XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_CHILD
  );
  if (orderProcess) {
    if (limit) {
      const showDay = moment()
        .startOf('day')
        .subtract(limit, 'days')
        .toDate();
      orderProcess = orderProcess.filter(
        value => showDay <= new Date(value.created_at.seconds * 1000)
      );
    }
    return orderProcess;
  }
  return [];
};

export const useOrdersCompleted = (limit, sort = false) => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  let orderProcess = Orders?.filter(
    value =>
      value.status === ORDER_STATUS.COMPLETED &&
      value.xpress_order_type !== XPRESS_ORDER_TYPE.MULTIPLE_DESTINATIONS_CHILD
  );
  if (orderProcess) {
    if (limit || limit === 0) {
      const showDay = moment()
        .startOf('day')
        .subtract(limit, 'days')
        .toDate();
      orderProcess = orderProcess.filter(
        value => showDay <= new Date(value.created_at.seconds * 1000)
      );
    }
    if (sort && orderProcess.length > 1)
      orderProcess = orderProcess
        .slice()
        .sort((a, b) => b.updated_at - a.updated_at);
    return orderProcess;
  }
  return [];
};

export const useOrdersCanceled = (limit, sort = false) => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  let orderProcess = Orders?.filter(
    value => value.status === ORDER_STATUS.CANCELED
  );
  if (orderProcess) {
    if (limit || limit === 0) {
      const showDay = moment()
        .startOf('day')
        .subtract(limit, 'days')
        .toDate();
      orderProcess = orderProcess.filter(
        value => showDay <= new Date(value.created_at.seconds * 1000)
      );
    }
    if (sort && orderProcess.length > 1)
      orderProcess = orderProcess
        .slice()
        .sort((a, b) => b.updated_at - a.updated_at);
    return orderProcess;
  }
  return [];
};

export const appFilterReport = async (status, time, month, year, user) => {
  let startDate;
  let endDate;
  const getMoth = new Date(month).getMonth() + 1;
  switch (time) {
    case 'week':
      startDate = moment()
        .startOf('day')
        .subtract(6, 'days')
        .toDate();
      endDate = moment()
        .endOf('day')
        .toDate();
      break;
    case 'month':
      startDate = moment(`${year}-${getMoth}`)
        .startOf('months')
        .toDate();
      endDate = moment(startDate)
        .endOf('months')
        .toDate();
      break;
    default:
      startDate = moment(`${year + 1}`)
        .startOf('year')
        .toDate();
      endDate = moment(startDate)
        .endOf('year')
        .toDate();
  }
  let query = admin
    .firestore()
    .collection(COLLECTIONS.ORDER)
    .where('xpressMotos', '==', true);
  if (status === 'completed')
    query = query.where('status', '==', ORDER_STATUS.COMPLETED);
  if (status === 'canceled')
    query = query.where('status', '==', ORDER_STATUS.CANCELED);
  if (user) {
    if (user.currentSubsidiary && user.currentSubsidiary.id)
      query = query.where('subsidiary_id', '==', user.currentSubsidiary.id);
    if (user.restaurant && user.restaurant.id)
      query = query.where('restaurant_id', '==', user.restaurant.id);
  }
  query = query
    .orderBy('created_at')
    .startAt(new Date(startDate))
    .endAt(new Date(endDate));
  let orders = await query.get();
  orders = orders.docs.map(doc => ({ ...doc.data() }));
  orders = orders.filter(
    item =>
      item.status === ORDER_STATUS.CANCELED ||
      item.status === ORDER_STATUS.COMPLETED
  );
  if (status === 'completed')
    orders = orders.filter(item => item.status === ORDER_STATUS.COMPLETED);
  if (status === 'canceled')
    query = orders.filter(item => item.status === ORDER_STATUS.CANCELED);
  return orders || [];
};

export const useOrderShortInfo = () => {
  const orders = useSelector(state => state.firestore.ordered.order);
  if (!orders) return [];
  return orders.map(o => ({
    id: o.id,
    order_code: o.order_code
  }));
};

export const getArrayOrders = arrayId => {
  return admin
    .firestore()
    .collection(COLLECTIONS.ORDER)
    .where('xpressMotos', '==', true)
    .where('id', 'in', arrayId);
};
