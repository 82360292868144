import Base from './base';
import { MESSAGE_TYPE } from '../constants';
/* eslint camelcase: 0 */

export default class Message extends Base {
  constructor({
    id,
    enable = true,
    created_at,
    updated_at,
    chat_id = '',
    sender_id = '',
    message = '',
    sender_name = '',
    sender_profile_photo = '',
    create_from = '',
    sender_roles = {},
    urlImage = '',
    type = MESSAGE_TYPE.TEXT
  } = {}) {
    super(id, enable, created_at, updated_at);
    this.chat_id = chat_id;
    this.sender_id = sender_id;
    this.message = message;
    this.sender_name = sender_name;
    this.sender_profile_photo = sender_profile_photo;
    this.create_from = create_from;
    this.sender_roles = sender_roles;
    this.urlImage = urlImage;
    this.type = type;
  }
}
