import React from 'react';
import {
  TrendingUp as TrendingUpIcon,
  Users as UsersIcon,
  Home as HomeIcon,
  BarChart
} from 'react-feather';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import ImageIcon from '../../../components/ImageIcon';

export const owner = [
  {
    subheader: 'menu.sections.overview',
    id: 'editor',
    href: '/app/editor',
    items: [
      {
        title: 'Dashboard',
        icon: TrendingUpIcon,
        href: '/app/management/dashboard'
      },
      {
        title: 'menu.sections.orders',
        icon: () => <ImageIcon src="/static/merchant/orders/bag.png" />,
        href: '/app/orders'
      }
    ]
  },
  {
    subheader: 'menu.sections.admin',
    id: 'administration',
    items: [
      {
        title: 'personal.personal',
        icon: UsersIcon,
        href: '/app/members'
      },
      {
        id: 'configuration',
        title: 'menu.sections.restaurantProfile',
        icon: HomeIcon,
        href: '/app/configuration'
      }
    ]
  },
  {
    subheader: 'earning.header.performance',
    id: 'performance',
    items: [
      /* {
        title: 'earning.header.reports',
        icon: DollarSign,
        href: '/app/performance/earnings'
      }, */
      {
        title: 'earning.header.sales_report',
        icon: BarChart,
        href: '/app/performance/salesReport'
      }
    ]
  },
  {
    subheader: 'payment.header.payment_history',
    id: 'payment',
    items: [
      {
        title: 'payment.header.payment_information',
        icon: FaFileInvoiceDollar,
        href: '/app/centralPayments'
      }
    ]
  },
  {
    subheader: 'menu.sections.customerHistory',
    id: 'editor',
    items: [
      {
        title: 'menu.sections.history',
        icon: () => (
          <ImageIcon src="/static/merchant/costumerHistorial/motoGreen.png" />
        ),
        href: '/app/history'
      }
    ]
  }
];

export const cashier = [
  {
    subheader: 'menu.sections.overview',
    id: 'editor',
    href: '/app/editor',
    items: [
      {
        title: 'menu.sections.orders',
        icon: () => <ImageIcon src="/static/merchant/orders/bag.png" />,
        href: '/app/orders'
      }
    ]
  },
  {
    subheader: 'menu.sections.customerHistory',
    id: 'editor',
    items: [
      {
        title: 'menu.sections.history',
        icon: () => (
          <ImageIcon src="/static/merchant/costumerHistorial/motoGreen.png" />
        ),
        href: '/app/history'
      }
    ]
  }
];

export const supervisor = [
  {
    subheader: 'menu.sections.overview',
    id: 'editor',
    href: '/app/editor',
    items: [
      {
        title: 'menu.sections.orders',
        icon: () => <ImageIcon src="/static/merchant/orders/bag.png" />,
        href: '/app/orders'
      }
    ]
  },
  {
    subheader: 'menu.sections.admin',
    id: 'administration',
    items: [
      {
        title: 'personal.personal',
        icon: UsersIcon,
        href: '/app/members'
      },
      {
        id: 'configuration',
        title: 'menu.sections.restaurantProfile',
        icon: HomeIcon,
        href: '/app/configuration'
      }
    ]
  },
  {
    subheader: 'menu.sections.customerHistory',
    id: 'editor',
    items: [
      {
        title: 'menu.sections.history',
        icon: () => (
          <ImageIcon src="/static/merchant/costumerHistorial/motoGreen.png" />
        ),
        href: '/app/history'
      }
    ]
  }
];
