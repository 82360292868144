import Base from './base';

/* eslint camelcase: 0 */
export default class Client extends Base {
  constructor({
    id,
    first_name = '',
    last_name = '',
    phone_number = '',
    email = '',
    ci = '',
    profile_photo,
    favorite_restaurants = [],
    orders_completed = 0,
    total_purchases = 0,
    topic_subscriptions = [],
    blocked_list = {},
    addresses = [],
    enable = true
  }) {
    super(id);
    this.first_name = first_name;
    this.last_name = last_name;
    this.phone_number = phone_number;
    this.email = email;
    this.ci = ci;
    if (profile_photo) this.profile_photo = profile_photo;
    this.favorite_restaurants = favorite_restaurants;
    this.orders_completed = orders_completed;
    this.total_purchases = total_purchases;
    this.blocked_list = blocked_list;
    this.topic_subscriptions = topic_subscriptions;
    this.addresses = addresses;
    this.enable = enable;
  }
}
