import React from 'react';
import { Grid, Card, CardContent, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import _ from 'lodash';
import NewOrder from '../NewOrder';
import useWindowDimensions from '../../../../hooks/useWindowDimension';
import { useOrderBeenUse } from '../../../../hooks/Order/useOrders';
import ImageIcon from '../../../../components/ImageIcon';
import { useBrowserModal } from './modalStyles';

const bottomSizeConst = 64;

const NewOrderModal = props => {
  const { order } = props;
  const classes = useBrowserModal();
  const translation = useTranslation();
  const dimensions = useWindowDimensions();
  const orderBeenUsed = useOrderBeenUse(order.id);

  if (_.isEmpty(orderBeenUsed)) return <></>;

  return (
    <Grid className={classes.content}>
      <Card
        className={classes.cardContainer}
        style={{ maxHeight: dimensions.height - bottomSizeConst }}
      >
        <Box className={classes.annoyCashier}>
          <Grid item container justify="space-between" wrap="nowrap">
            <Grid item container justify="center" alignItems="center">
              <Typography className={classes.headerText}>
                {`${translation('orders_view.modal.exclamation')}`}
              </Typography>
              <ImageIcon
                styles={classes.icon}
                src="/static/merchant/orders/newOrdersWhite.png"
              />
              <Typography className={classes.headerText}>
                {`${translation('orders_view.modal.title')}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <CardContent className={classes.cardContent}>
          <NewOrder order={orderBeenUsed} modal />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NewOrderModal;
