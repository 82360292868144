import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import getUserRoles from '../utils/helpers/userHelper';

function RoleGuard({ children, blockProfiles }) {
  const account = useSelector(state => state.account);

  const profiles = getUserRoles(account.user, false);
  let deny = false;

  profiles.forEach(p => {
    deny = blockProfiles.includes(p);
  });

  if (deny) {
    return <Redirect to="/app" />;
  }

  return children;
}

RoleGuard.propTypes = {
  children: PropTypes.any
};

export default RoleGuard;
