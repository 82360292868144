import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import {
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Grid,
  Badge
} from '@material-ui/core';
import { Minimize, Maximize } from '@material-ui/icons';
import clsx from 'clsx';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { FaFileImage } from 'react-icons/fa';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { setChats } from '../../../actions/chatActions';
import YellowTextField from '../../../components/Inputs/TextField';
import { GreenCrossButton } from '../../../components/Inputs/Buttons';
import { useConversationStyles } from './chatStyles';
import {
  createImageMessage,
  createMessage,
  lastMessageViewrs
} from '../../../services/chatService';
import { useChatMesseges, useChat } from '../../../hooks/Chat/useChat';
import { SUBJECT_OPTIONS } from '../constants';
import ConversationMessages from './ConversationMessages';
import SelectImageMessage from './selectImageMessage';

const Conversation = ({ chatId, isCollapsed, setIsCollapsed }) => {
  const translation = useTranslation();
  const [message, setMessage] = useState();
  const classes = useConversationStyles();
  const user = useSelector(state => state.account.user);
  const messages = useChatMesseges(chatId);
  const messageBox = useRef(null);
  const [chat] = useChat(chatId || '');
  let restaurantPhoto = '';
  const dispatch = useDispatch();
  if (chat) restaurantPhoto = chat.admin_photo || '';
  const scrollDown = () => {
    messageBox.current.scrollIntoView({ behavior: 'smooth' });
  };
  console.log('sdasd');
  useEffect(() => {
    if (chat && messages && !isCollapsed) scrollDown();
    // eslint-disable-next-line
  }, [messages]);

  const lastMessageViewers = async () => {
    if (!chat.last_message_viewers.includes(user.id)) {
      const lasMessageViewrs = [...chat.last_message_viewers];
      lasMessageViewrs.push(user.id);
      await lastMessageViewrs(chat.id, {
        last_message_viewers: [...lasMessageViewrs]
      });
    }
  };

  useEffect(() => {
    if (chat) {
      if (chat.is_active === false) dispatch(setChats([]));
      else dispatch(setChats([chat]));
    }
    if (!chat) setIsCollapsed(false);
    if (chat && !isCollapsed) lastMessageViewers();
    // eslint-disable-next-line
  }, [chat, isCollapsed]);

  const renderMessages = () => {
    if (!messages.length) return <></>;
    if (messages[0]?.chat_id !== chatId)
      return (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      );
    const dates = [];
    return messages.map((m, i) => {
      let isShowDate = false;
      let dateNew = '';
      if (i === 0) {
        dateNew = m.created_at ? m.created_at.toDate() : new Date();
        dates.push(dateNew);
        isShowDate = true;
      }
      if (i > 0) {
        const dateOld = dates[i - 1];
        dateNew = moment(
          m.created_at ? m.created_at.toDate() : new Date(),
          'YYYY-MM-DD'
        );
        if (!moment(dateNew).isSame(dateOld, 'day')) isShowDate = true;
        dates.push(dateNew);
      }
      return (
        <>
          <Box className={clsx(classes.messageData)}>
            {isShowDate && (
              <Typography>{moment(dateNew).format('DD/MM/YYYY')}</Typography>
            )}
          </Box>
          <ConversationMessages m={m} user={user} chat={chat || {}} />
        </>
      );
    });
  };

  const onPressEnter = event => {
    let messageSend = '';
    if (event.key === 'Enter') event.preventDefault();
    if (typeof message === 'string') messageSend = message.trim();
    if (event.key === 'Enter' && messageSend !== '') {
      createMessage(user, message, chatId);
      setMessage('');
    }
  };

  const getTitle = () => {
    const [option] = SUBJECT_OPTIONS.filter(so => so.value === chat.subject);
    switch (chat.subject) {
      case 'ORDER':
        return (
          <Typography>
            {`${translation(option.label)} - ${chat.order_summary.order_code}`}
          </Typography>
        );
      case 'OTHER':
        return (
          <Typography>
            {`${translation(option.label)} - ${chat.other_text}`}
          </Typography>
        );
      default:
        return <Typography>{`${translation(option.label)}`}</Typography>;
    }
  };

  async function sendImageMessage(file) {
    await createImageMessage(user, file, chatId);
  }

  if (isCollapsed)
    return (
      <Box className={clsx(classes.minimizedContainer)}>
        <Box className={clsx(classes.chatTitle)}>
          <Avatar
            className={classes.avatar}
            alt="avatar"
            src={restaurantPhoto}
          />
          {chat && getTitle()}
        </Box>
        <Box className={clsx(classes.minimizedIconsContainer)}>
          <Maximize
            className={clsx(classes.icon)}
            onClick={() => setIsCollapsed(false)}
          />
          <GreenCrossButton
            onClick={() => {
              dispatch(setChats([]));
              setIsCollapsed(false);
            }}
          />
        </Box>
      </Box>
    );

  if (!chat)
    return (
      <Box m="auto" pt={9} className={clsx(classes.conversationContainer)}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box className={clsx(classes.conversationContainer)}>
      <Box className={clsx(classes.chatTitleContainer)}>
        <Box className={clsx(classes.chatTitle)}>
          <Avatar
            className={classes.avatar}
            alt="avatar"
            src={restaurantPhoto}
          />
          {chat && getTitle()}
        </Box>
        <Box>
          <Minimize
            className={clsx(classes.icon)}
            onClick={() => setIsCollapsed(true)}
          />
          <GreenCrossButton onClick={() => dispatch(setChats([]))} />
        </Box>
      </Box>
      <Box className={clsx(classes.container)}>
        <Box className={clsx(classes.messagesContainer)}>
          {messages && (
            <Box>
              {renderMessages()}
              <div ref={messageBox} />
            </Box>
          )}
        </Box>
        <Box className={clsx(classes.cockpitContainer)}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item xs={1}>
              <SelectImageMessage
                onAccept={sendImageMessage}
                compressed
                icon={
                  <Badge
                    overlap="circle"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    className={clsx(classes.cursorPointer, classes.badge)}
                    badgeContent={
                      <AddCircleIcon className={clsx(classes.iconAddCircle)} />
                    }
                  >
                    <FaFileImage
                      fontSize="inherit"
                      style={{ fontSize: '32px' }}
                      className={clsx(classes.expressColorGreen)}
                    />
                  </Badge>
                }
              />
            </Grid>
            <Grid item xs={10}>
              <YellowTextField
                fullWidth
                multiline
                name="message"
                onChange={e => setMessage(e.target.value)}
                onKeyDown={onPressEnter}
                value={message}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={1}>
              <RiSendPlane2Fill
                fontSize="inherit"
                style={{ fontSize: '38px' }}
                className={clsx(
                  classes.expressColorGreen,
                  classes.cursorPointer,
                  classes.iconSend
                )}
                onClick={() => {
                  let messageSend = '';
                  if (typeof message === 'string') messageSend = message.trim();
                  if (messageSend !== '') {
                    createMessage(user, message, chatId);
                    setMessage('');
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Conversation;
