import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, makeStyles, Toolbar } from '@material-ui/core';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.EXPRESS
      ? {
          backgroundColor: theme.palette.background.primary
        }
      : {})
  },
  toolbar: {
    minHeight: 64,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(8)
    }
  },
  logo: {
    maxHeight: 62,
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(5),
      paddingLeft: theme.spacing(1)
    }
  }
}));

function TopBar({ className, user, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/admin/orders">
          <Logo className={classes.logo} />
        </RouterLink>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
