import React, { useEffect } from 'react';
import { Card, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { Minus as MinusIcon, Plus as PlusIcon } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-multi-lang/lib';
import useProductsPerOrder from '../../../hooks/Order/useProductsPerOrder';
import { useTimeSetterStyles } from './styles';

const TimeSetter = ({ order, setTime, preparingTime = null }) => {
  const classes = useTimeSetterStyles();
  const translation = useTranslation();
  const products = useProductsPerOrder(order.id);
  useEffect(() => {
    if (products && products.length && preparingTime === null) {
      const maxCook = products.reduce((max, p) => {
        // eslint-disable-next-line no-param-reassign
        if (p.cooking_time > max) max = p.cooking_time;
        return max;
      }, 0);
      setTime(maxCook);
    }
    // eslint-disable-next-line
  }, [products]);

  return (
    <Card className={clsx(classes.root)}>
      <Box className={classes.header}>
        <Typography
          className={classes.headerText}
          variant="h4"
          style={{ fontWeight: 'bold' }}
        >
          {translation('orders.details.time_setter.title')}
        </Typography>
      </Box>
      <Box width="100%">
        <Box className={clsx(classes.timerBox)}>
          <IconButton
            className={classes.button}
            color="secondary"
            onClick={() => {
              let minusTime = preparingTime - 5;
              if (minusTime <= 0) minusTime = 0;
              setTime(minusTime);
            }}
          >
            <MinusIcon className={classes.icon} />
          </IconButton>
          <Typography className={clsx(classes.minutes)}>
            {preparingTime} min
          </Typography>
          <IconButton
            className={classes.button}
            color="secondary"
            onClick={() => setTime(preparingTime + 5)}
          >
            <PlusIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

export default TimeSetter;
