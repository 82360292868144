import moment from 'moment';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  EXPRESS: 'EXPRESS'
};

export const COLLECTIONS = {
  ORDER: 'order',
  ORDER_INVITE: 'order_invite',
  ORDER_PRODUCT: 'order_product',
  USER: 'users',
  SUBSIDIARY: 'subsidiary',
  PRODUCT: 'product',
  CATEGORIES: 'categories',
  CATEGORIES_ENTREGAS: 'categories_entregas',
  MENU_ADDITIONS: 'menu_additions',
  ADDITION: 'additions',
  RESTAURANT: 'restaurants',
  CITY: 'city',
  DOCUMENT: 'document',
  INVOICE: 'invoice',
  NOTIFICATIONS: 'notifications',
  PROMOS: 'promo',
  PROMOS_BANNER: 'promo_banner',
  BANNER_ITEM: 'banner_item',
  AREAS: 'price_area',
  CAMPAINGS_IMAGE: 'campaings_image',
  HISTORY: 'history',
  CHAT: 'chat',
  MESSAGE: 'message',
  SUMMARY: 'summary',
  SETTINGS: 'settings',
  RATE_QUALIFICATON_OPTIONS: 'rate_qualification_options',
  PAYMENT_HISTORY: 'payment_history',
  RESTAURANT_SUMMARY: 'restaurants_summary',
  CHARGES_HISTORY: 'charges_history'
};

export const DEFAULT_HOUR = '2000 01 00:00';
export const DEFAULT_DATE_FORMAT = 'YYYY MM HH:mm';
export const DEFAULT_HOUR_DATE = moment(
  DEFAULT_HOUR,
  DEFAULT_DATE_FORMAT
).toDate();

export const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const REJECT_PRODUCT_REASON = [
  'Producto agotado',
  'Artículo con alta demanda',
  'Ingredientes incompletos',
  'No podemos acomodarnos a su requerimiento especial o de alergias',
  'Otro'
];
export const REJECT_ORDER_REASON = [
  'Alta demanda de órdenes',
  'Falta de personal de atención',
  'Sin Servicios básicos',
  'Establecimiento cerrado',
  'Problemas con computadora/Internet/App',
  'No podemos acomodarnos a su requerimiento especial o de alergias',
  'Otro'
];
export const ADDITIONAL_MINUTES = [10, 20, 30];
export const READY_TO_PICK_WAITING_MINUTES = 5;
export const EXPIRATION_TICKET_TIME = 3;

export const commerceType = {
  SUBSIDIARY: 'subsidiary',
  RESTAURANT: 'restaurant'
};

export const DOCUMENT_TYPE = {
  SUBSIDIARY: 'subsidiary',
  RESTAURANT: 'restaurant',
  INVOICE: 'invoice'
};

export const registerStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  SUSPENDED: 'SUSPENDED'
};

export const documentsStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
};

export const bannerColors = {
  suspColor: '#fefbd8',
  archColor: '#eca1a6'
};

export const IMAGE_SIZES = {
  BANNER_MIN_WIDTH: '800',
  BANNER_MIN_HEIGHT: '400',
  LOGO_MIN_WIDTH: '400',
  LOGO_MIN_HEIGHT: '400',
  PRODUCT_MIN_WIDTH: '400',
  PRODUCT_MIN_HEIGHT: '400'
};

export const COLORS = {
  red: '#C23F25',
  green: '#56B19E',
  disabledGray: '#F2F2F2',
  ligthRed: '#F75B3B',
  ligthYellow: '#FFEA85',
  ligthGreen: '#EAF8F4',
  backgroundGreenLight: '#A8DFD180',
  borderTableGray: '#C2C2C2'
};

export const INACTIVITY_TIME = 60000;

export const RESTAURANT_DEFAULT_SETTINGS = {
  askBeforeDelivery: true,
  sharedMenuEditBySupervisor: true
};

export const dynamicLinkConfiguration = {
  firebaseApi: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  firebaseDynamicLinkUrl:
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=',
  template: {
    dynamicLinkInfo: {
      domainUriPrefix: `${process.env.REACT_APP_DYNAMIC_LINK_DOMAIN_URI}`,
      link: 'https://xpressbolivia.com',
      androidInfo: {
        androidPackageName: 'com.believesol.xpress'
      },
      navigationInfo: {
        enableForcedRedirect: true
      },
      iosInfo: {
        iosBundleId: 'com.believesol.xpress'
        // TODO: uncomment when in production
        // iosAppStoreId: '1507805959'
      },
      socialMetaTagInfo: {
        socialTitle: '',
        socialDescription: '',
        socialImageLink:
          'https://firebasestorage.googleapis.com/v0/b/xpress-production.appspot.com/o/app%2Flogo.png?alt=media&token=300ecd9f-1ee2-4049-a450-09c7dfea3f20'
      }
    },
    suffix: {
      option: 'SHORT'
    }
  }
};

export const expressDriverLinkConfiguration = {
  firebaseApi: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  firebaseDynamicLinkUrl:
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=',
  template: {
    dynamicLinkInfo: {
      domainUriPrefix: `${process.env.REACT_APP_DYNAMIC_LINK_DOMAIN_URI}`,
      link: 'https://xpressdriverbolivia.com',
      androidInfo: {
        androidPackageName: 'com.believesol.xpressdriver'
      },
      navigationInfo: {
        enableForcedRedirect: true
      },
      iosInfo: {
        iosBundleId: 'com.believesol.xpressdriver'
        // TODO: uncomment when in production
        // iosAppStoreId: '1507805959'
      },
      socialMetaTagInfo: {
        socialTitle: '',
        socialDescription: '',
        socialImageLink:
          'https://firebasestorage.googleapis.com/v0/b/xpress-production.appspot.com/o/app%2Flogo.png?alt=media&token=300ecd9f-1ee2-4049-a450-09c7dfea3f20'
      }
    },
    suffix: {
      option: 'SHORT'
    }
  }
};

export const LEVELS = [
  {
    id: 'NOVATO',
    range: {
      min: 0,
      max: 0.25
    }
  },
  {
    id: 'INTERMEDIO',
    range: {
      min: 0.26,
      max: 0.5
    }
  },
  {
    id: 'AVANZADO',
    range: {
      min: 0.51,
      max: 0.75
    }
  },
  {
    id: 'EXPERTO',
    range: {
      min: 0.76,
      max: 1
    }
  }
];

export const START_YEAR = 2020;

export const DATE_FILTERS = [
  { value: '-', label: 'admin_dealers.tabs.filters.select' },
  { value: '0', label: 'admin_dealers.tabs.filters.today' },
  { value: '1', label: 'admin_dealers.tabs.filters.yesterday' },
  { value: '7', label: 'admin_dealers.tabs.filters.past7' }
];

export const MONTHS = [
  { value: '-', label: 'months.select' },
  { value: '01', label: 'months.january' },
  { value: '02', label: 'months.february' },
  { value: '03', label: 'months.march' },
  { value: '04', label: 'months.april' },
  { value: '05', label: 'months.may' },
  { value: '06', label: 'months.june' },
  { value: '07', label: 'months.july' },
  { value: '08', label: 'months.august' },
  { value: '09', label: 'months.september' },
  { value: '10', label: 'months.october' },
  { value: '11', label: 'months.november' },
  { value: '12', label: 'months.december' }
];

export const PAID_FILTER = [
  { value: '-', label: 'admin_dealers.tabs.filters.all' },
  { value: '1', label: 'admin_dealers.tabs.filters.paidOut' },
  { value: '0', label: 'admin_dealers.tabs.filters.notPayed' }
];

export const FILTER_DEFAULT = {
  city: '',
  restaurant: '',
  subsidiary: '',
  year: '',
  month: '',
  status: '',
  promo: '',
  statusOrder: '',
  complexDate: '',
  driver: '',
  fromDate: '',
  toDate: '',
  paid: ''
};

export const FILTER_DEFAULT_HISTORY = {
  statusOrder: '',
  fromDate: '',
  toDate: '',
  type: '',
  subsidiaryId: '',
  restaurantId: '',
  userId: ''
};

export const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
};
export const MAX_DRIVER_IMAGES_SIZE = 5e6;

export const ROUTES_PATHS = {
  DRIVER_REGISTER: '/driver_register',
  DRIVER_LOGIN: '/login-driver',
  DRIVER: '/driver',
  DRIVER_DASHBOARD: '/driver/dashboard',
  DRIVER_ORDERS: '/driver/orders',
  DRIVER_QUALIFICATION: '/driver/qualification',
  DRIVER_SETTINGS: '/driver/settings',
  WELCOME_COMMERCE: '/home'
};

export const INTERVAL_HISTORY = {
  TODAY: 'TODAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH'
};

export const BANK_ACCOUNT_TYPE = {
  SAVINGS_ACCOUNT: 'SAVINGS_ACCOUNT',
  CURRENT_ACCOUNT: 'CURRENT_ACCOUNT',
  UNKNOWN: 'UNKNOWN'
};

export const TYPE = {
  UNKNOWN: 'UNKNOWN'
};
export const MAX_COMMERCE_IMAGES_SIZE = 10 * 1024 * 1024; // 10mb
export const VALID_iMAGE_EXTENSIONS = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/webp'
];

export const TYPE_HOME = {
  HOUSE: 'HOUSE',
  JOB: 'JOB',
  OTHER: 'OTHER'
};

export const ACTION_ADDRESS = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  ADD: 'ADD'
};

export const borderDecoration =
  "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23FF935bFF' stroke-width='1' stroke-dasharray='7%2c 7' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e";

export const BANKS = [
  { label: 'Banco BISA S.A.', value: 'Banco BISA S.A.' },
  {
    label: 'Banco de Crédito de Bolivia S.A.',
    value: 'Banco de Crédito de Bolivia S.A.'
  },
  {
    label: 'Banco de la Nación Argentina S. A.',
    value: 'Banco de la Nación Argentina S. A.'
  },
  { label: 'Banco Económico S.A.', value: 'Banco Económico S.A.' },
  { label: 'Banco Fassil S.A.', value: 'Banco Fassil S.A.' },
  { label: 'Banco Fie S.A.', value: 'Banco Fie S.A.' },
  { label: 'Banco Fortaleza S.A.', value: 'Banco Fortaleza S.A.' },
  { label: 'Banco Ganadero S.A.', value: 'Banco Ganadero S.A.' },
  {
    label: 'Banco Mercantil Santa Cruz S.A.',
    value: 'Banco Mercantil Santa Cruz S.A.'
  },
  {
    label: 'Banco Nacional de Bolivia S.A.',
    value: 'Banco Nacional de Bolivia S.A.'
  },
  {
    label: 'Banco para el Fomento a Iniciativas Económicas S.A.',
    value: 'Banco para el Fomento a Iniciativas Económicas S.A.'
  },
  { label: 'Banco Prodem S.A.', value: 'Banco Prodem S.A.' },
  { label: 'Banco Solidario S.A.', value: 'Banco Solidario S.A.' },
  { label: 'Banco Unión S.A.', value: 'Banco Unión S.A.' },
  {
    label: 'Banco PYME de la Comunidad S.A.',
    value: 'Banco PYME de la Comunidad S.A.'
  },
  { label: 'Banco PYME Ecofuturo S.A.', value: 'Banco PYME Ecofuturo S.A.' },
  { label: 'Cooperativa Jesús Nazareno', value: 'Cooperativa Jesús Nazareno' }
];
export const BANKS_ACCOUNT_TYPE = [
  { label: 'CUENTA CORRIENTE', value: 'SAVINGS_ACCOUNT' },
  { label: 'CUENTA DE AHORRO', value: 'CURRENT_ACCOUNT' }
];
