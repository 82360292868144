import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import firebaseService from 'src/services/firebaseService';
import Restaurant from 'src/dto/restaurant';
import Subsidiary from 'src/dto/subsidiary';
import admin, { fbMessaging } from 'src/config/firebaseConfig';
import { isAdmin, isDriver } from '../utils/helpers/userHelper';
import { REGISTER_STATUS } from '../dto/enum';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });
      try {
        const user = await firebaseService.isUserLoggedIn();

        // Token Verification
        try {
          if (fbMessaging) {
            const token = await admin.messaging().getToken();

            await firebaseService.setDoc(`users/${user.id || user.uid}`, {
              ...user,
              device_id: admin.firestore.FieldValue.arrayUnion(token)
            });
            user.token = token;
          }
        } catch (error) {
          // console.log(error);
          user.token = null;
        }
        if (isAdmin(user) || isDriver(user)) {
          await dispatch(setUserData(user));
        } else {
          let restaurant = await firebaseService.getDocumentWithJoin(
            'restaurants',
            user.restaurant_id,
            'subsidiary',
            'restaurant_id',
            user.restaurant_id
          );
          let subsidiaries = [];
          if (restaurant.register_status === REGISTER_STATUS.ACTIVE) {
            subsidiaries = restaurant.subsidiary.filter(
              s => s.register_status === REGISTER_STATUS.ACTIVE
            );
          }
          if (restaurant.register_status === REGISTER_STATUS.SUSPENDED) {
            subsidiaries = restaurant.subsidiary.filter(
              s => s.register_status === REGISTER_STATUS.SUSPENDED
            );
          }
          restaurant = new Restaurant(restaurant);
          subsidiaries = subsidiaries.map(
            subsidiary => new Subsidiary(subsidiary)
          );
          restaurant.subsidiary = subsidiaries;
          await dispatch(setUserData(user, restaurant));
        }
        setLoading(false);
      } catch (error) {
        // console.log(error); // TODO: Set into a analytics tool
        setLoading(false);
      }
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
