const TestEnv = {
  googleAnalytics: 'UA-236246807-2',
  driverTermsAndConditions: 'https://xpress-test-env.web.app/driver-agreement',
  REACT_APP_VROOM_URL: 'https://vroom-ucccw7ir7q-uc.a.run.app',
  REACT_APP_XPRESS_API_URL: 'https://xpress-api-ucccw7ir7q-uc.a.run.app',
  REACT_APP_WHATSAPP: 'https://wa.me/',
  REACT_APP_XPRESS_SUPPORT: '+59162093356'
};

export default TestEnv;
