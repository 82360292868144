import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isAdmin } from '../utils/helpers/userHelper';

function GuestGuard({ children }) {
  const account = useSelector(state => state.account);
  if (account.user) {
    if (isAdmin(account.user)) {
      return <Redirect to="/admin/orders" />;
    }
    if (_.isEmpty(account.subsidiaries)) {
      return <Redirect to="/app/account" />;
    }
    return <Redirect to="/app/orders" />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
