/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_FILTER, DEFAULT_STATE } from '../actions/filterActions';
import { FILTER_DEFAULT } from '../constants';

const initialState = FILTER_DEFAULT;

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER: {
      const { fields } = action.payload;
      return produce(state, draft => {
        draft.city = fields.city || state.city;
        draft.restaurant = fields.restaurant || state.restaurant;
        draft.subsidiary = fields.subsidiary || state.subsidiary;
        draft.year = fields.year || state.year;
        draft.month = fields.month || state.month;
        draft.status = fields.status || state.status;
        draft.promo = fields.promo || state.promo;
        draft.statusOrder = fields.statusOrder || state.statusOrder;
        draft.complexDate = fields.complexDate || state.complexDate;
        draft.driver = fields.driver || state.driver;
        draft.fromDate = fields.fromDate || state.fromDate;
        draft.toDate = fields.toDate || state.toDate;
        draft.paid = fields.paid || state.paid;
      });
    }

    case DEFAULT_STATE: {
      return produce(state, draft => {
        draft.city = FILTER_DEFAULT.city;
        draft.restaurant = FILTER_DEFAULT.restaurant;
        draft.subsidiary = FILTER_DEFAULT.subsidiary;
        draft.year = FILTER_DEFAULT.year;
        draft.month = FILTER_DEFAULT.month;
        draft.status = FILTER_DEFAULT.status;
        draft.promo = FILTER_DEFAULT.promo;
        draft.statusOrder = FILTER_DEFAULT.statusOrder;
        draft.complexDate = FILTER_DEFAULT.complexDate;
        draft.driver = FILTER_DEFAULT.driver;
        draft.fromDate = FILTER_DEFAULT.fromDate;
        draft.toDate = FILTER_DEFAULT.toDate;
        draft.paid = FILTER_DEFAULT.paid;
      });
    }

    default:
      return state;
  }
};

export default filterReducer;
