/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Dialog,
  makeStyles,
  IconButton,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { COLORS_ENTREGAS_COM } from '../../../theme/colors';
import Icons from '../../../assets/icons';
import FileUtils from '../../../utils/fileUtils';
import { MAX_COMMERCE_IMAGES_SIZE } from '../../../constants';
import { GreenCrossButton } from '../../../components/Inputs/Buttons';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '-16px 24px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('969')]: {
      margin: '-8px 16px 16px 16px'
    }
  },
  img: {
    border: `5px solid ${COLORS_ENTREGAS_COM.secondary}`,
    borderRadius: '50%',
    width: 270,
    height: 270,
    [theme.breakpoints.down('969')]: {
      width: 200,
      height: 200
    }
  },
  imgDefault: {
    width: 88,
    height: 110,
    [theme.breakpoints.down('969')]: {
      width: 86,
      height: 99
    }
  },
  button: {
    color: COLORS_ENTREGAS_COM.white,
    background: COLORS_ENTREGAS_COM.primary,
    textTransform: 'none',
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: COLORS_ENTREGAS_COM.primaryLight
    },
    '&:disabled': {
      background: COLORS_ENTREGAS_COM.lightGray
    },
    [theme.breakpoints.down('969')]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  },
  text: {
    fontSize: 15,
    color: COLORS_ENTREGAS_COM.textLightGray
  },
  input: {
    display: 'none'
  }
}));

const UploadAgreementModal = ({
  isOpen,
  matches,
  handleClose,
  fileSelect,
  onChange
}) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [dimensionError, setDimensionError] = useState([]);

  const setDataFile = e => {
    setDimensionError([]);
    const messageDimensions = FileUtils.validateFileSize(
      e.target.files[0],
      MAX_COMMERCE_IMAGES_SIZE
    );
    if (messageDimensions.length === 0) {
      setFile(e.target.files[0]);
    } else {
      setDimensionError(messageDimensions);
    }
  };

  const onPressSavePicture = () => {
    onChange({ file, name: file.name });
    handleClose();
    setFile(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 20, width: matches ? 350 : 300 }
      }}
    >
      <Box display="flex" justifyContent="right">
        <GreenCrossButton onClick={handleClose} />
      </Box>
      <Box className={classes.container}>
        <Box>
          <input
            className={classes.input}
            id="agreement_file"
            type="file"
            onChange={setDataFile}
          />
          <label htmlFor="agreement_file">
            <IconButton className={classes.img} component="span" variant="text">
              <Box>
                <img
                  src={Icons.addFile}
                  className={classes.imgDefault}
                  alt="upload_agreement"
                />
                <Typography className={classes.text}>
                  {file ? file.name : fileSelect}
                </Typography>
              </Box>
            </IconButton>
          </label>
        </Box>
        <Box mt={2}>
          <Typography className={classes.text}>max 10mb</Typography>
        </Box>
        {dimensionError.length > 0 && (
          <Box m={1}>
            <Alert severity="error">
              Por favor verificar :
              <ul>
                {dimensionError.map(e => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </Alert>
          </Box>
        )}
        <Box mt={2}>
          <Button
            className={classes.button}
            disabled={!file || dimensionError.length > 0}
            onClick={onPressSavePicture}
          >
            Aceptar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UploadAgreementModal;
