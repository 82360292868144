import admin from '../config/firebaseConfig';
/* eslint camelcase: 0 */
export default class Base {
  constructor(id, enable, created_at, updated_at) {
    this.id = id;
    this.enable = enable;
    this.created_at =
      created_at || admin.firestore.FieldValue.serverTimestamp();
    this.updated_at =
      updated_at || admin.firestore.FieldValue.serverTimestamp();
  }
}
