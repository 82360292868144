/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_FILTER_HISTORY,
  DEFAULT_STATE_HISTORY
} from '../actions/filterHistoryActions';
import { FILTER_DEFAULT_HISTORY } from '../constants';

const initialState = FILTER_DEFAULT_HISTORY;

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_HISTORY: {
      const { fields } = action.payload;
      return produce(state, draft => {
        draft.statusOrder = fields.statusOrder || state.statusOrder;
        draft.fromDate = fields.fromDate || state.fromDate;
        draft.toDate = fields.toDate || state.toDate;
        draft.type = fields.type || state.type;
        draft.subsidiaryId = fields.subsidiaryId || state.subsidiaryId;
        draft.restaurantId = fields.restaurantId || state.restaurantId;
        draft.userId = fields.userId || state.userId;
      });
    }

    case DEFAULT_STATE_HISTORY: {
      return produce(state, draft => {
        draft.statusOrder = FILTER_DEFAULT_HISTORY.statusOrder;
        draft.fromDate = FILTER_DEFAULT_HISTORY.fromDate;
        draft.toDate = FILTER_DEFAULT_HISTORY.toDate;
        draft.type = FILTER_DEFAULT_HISTORY.type;
        draft.subsidiaryId =
          FILTER_DEFAULT_HISTORY.subsidiaryId || state.subsidiaryId;
        draft.restaurantId =
          FILTER_DEFAULT_HISTORY.restaurantId || state.restaurantId;
        draft.userId = FILTER_DEFAULT_HISTORY.userId || state.userId;
      });
    }

    default:
      return state;
  }
};

export default filterReducer;
