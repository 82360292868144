import Base from './base';
/* eslint camelcase: 0 */

export default class Chat extends Base {
  constructor({
    id,
    enable = true,
    created_at,
    updated_at,
    subsidiary_id = '',
    restaurant_id = '',
    restaurant_name = '',
    subsidiary_name = '',
    last_message_id = '',
    is_active = true,
    tags = [],
    receiver_id = [],
    last_message_viewers = [],
    subject = '',
    order_summary = {},
    other_text = '',
    atc_assigned = [],
    create_from = '',
    restaurant_photo = {}
  } = {}) {
    super(id, enable, created_at, updated_at);
    this.subsidiary_id = subsidiary_id;
    this.restaurant_id = restaurant_id;
    this.restaurant_name = restaurant_name;
    this.subsidiary_name = subsidiary_name;
    this.last_message_id = last_message_id;
    this.is_active = is_active;
    this.tags = tags;
    this.receiver_id = receiver_id;
    this.last_message_viewers = last_message_viewers;
    this.subject = subject;
    this.order_summary = order_summary;
    this.other_text = other_text;
    this.atc_assigned = atc_assigned;
    this.create_from = create_from;
    this.restaurant_photo = restaurant_photo;
  }
}
