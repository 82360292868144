import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../constants';

export const useLastChargeHistory = restaurantId => {
  let query = [];
  if (restaurantId) {
    query = [
      {
        collection: COLLECTIONS.RESTAURANT,
        doc: restaurantId,
        subcollections: [{ collection: COLLECTIONS.CHARGES_HISTORY }],
        orderBy: ['startsOn', 'desc'],
        limit: 1,
        storeAs: 'last_charges_history'
      }
    ];
  }
  useFirestoreConnect(query);
  const lastChargeHistory = useSelector(
    state => state.firestore.ordered.last_charges_history
  );

  if (lastChargeHistory) {
    return lastChargeHistory[0];
  }
  return null;
};
