import { makeStyles } from '@material-ui/core';

export const useNewOrderStyles = makeStyles(theme => ({
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  button: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    padding: '13px 17px',
    borderRadius: 7,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: '7px 9px',
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(0)
    }
  },
  rejectButton: {
    textDecoration: 'underline',
    textUnderlinePosition: 'under'
  },
  btnText: {
    fontSize: 16,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  acceptButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  }
}));

export const useAcceptedStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  buttonWrapper: {
    width: '100%',
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  button: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    padding: '13px 17px',
    borderRadius: 7,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  },
  delayProd: {
    backgroundColor: theme.palette.expressColors.green,
    color: theme.palette.expressColors.white,
    '&:hover': {
      backgroundColor: theme.palette.expressColors.gray,
      color: theme.palette.expressColors.white
    }
  }
}));

export const useReadyToPickStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: '15px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  button: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    padding: '13px 17px',
    borderRadius: 7
  },
  middleButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  responsiveButton: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      height: 48
    }
  }
}));
