import { ORDER_STATUS } from '../../dto/enum';

export const COCKPIT_OPTIONS = [
  {
    value: ORDER_STATUS.MAKE_ORDER,
    label: 'orders_view.cockpit.headers.make_order',
    open: false
  },
  {
    value: ORDER_STATUS.ACCEPTED,
    label: 'orders_view.cockpit.headers.process',
    open: true
  },
  {
    value: ORDER_STATUS.DELIVERING,
    label: 'orders_view.cockpit.headers.delivery',
    open: false
  },
  {
    value: ORDER_STATUS.COMPLETED,
    label: 'orders_view.cockpit.headers.completed',
    open: false
  },
  {
    value: ORDER_STATUS.SCHEDULE,
    label: 'orders_view.cockpit.headers.schedule',
    open: false
  },
  {
    value: ORDER_STATUS.MAKE_MULTIPLE_DESTINATIONS,
    label: 'orders_view.cockpit.headers.plan_route',
    open: false
  }
];

export const COLORTYPES = {
  WARNING: 'warning',
  REGULAR: 'regular'
};

export const SIDEICONS = {
  makeOrder: '/static/merchant/costumerHistorial/motoGreen.png',
  inProcess: '/static/merchant/orders/ordersInProcess.png',
  delivering: '/static/merchant/orders/deliveryCar.png',
  completed: '/static/merchant/orders/ordersCompleted.png',
  scheduled: '/static/merchant/orders/ordersScheduled.png',
  map: '/static/merchant/map.png'
};
