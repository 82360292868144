import { registerStatus } from 'src/constants';
import Base from './base';

/* eslint camelcase: 0 */
export default class Subsidiary extends Base {
  constructor({
    id,
    enabled,
    created_at,
    updated_at,
    restaurant_id,
    city,
    address = '',
    address_2 = '',
    location,
    geohash,
    phone_number = '',
    delivery_time,
    main_category,
    menu_section,
    subsidiary_id,
    schedule = [],
    name = '',
    legal_representant = {},
    register_status = registerStatus.PENDING,
    archived_date = '',
    suspention_date = '',
    receiving_orders = true,
    restaurant_name = '',
    manager = '',
    manager_phone = '',
    xpress_motos = true,
    denied_merch = true
  } = {}) {
    super(id, enabled, created_at, updated_at);
    if (restaurant_id) this.restaurant_id = restaurant_id;
    if (city) this.city = city;
    this.address = address;
    this.address_2 = address_2;
    if (location) this.location = location;
    if (geohash) this.geohash = geohash;
    this.phone_number = phone_number;
    if (delivery_time) this.delivery_time = delivery_time;
    if (main_category) this.main_category = main_category;
    if (menu_section) this.menu_section = menu_section;
    if (subsidiary_id) this.subsidiary_id = subsidiary_id;
    this.schedule = schedule || [];
    this.name = name;
    this.legal_representant = legal_representant;
    this.register_status = register_status;
    this.archived_date = archived_date;
    this.suspention_date = suspention_date;
    this.receiving_orders = receiving_orders;
    this.restaurant_name = restaurant_name;
    this.xpress_motos = xpress_motos;
    this.denied_merch = denied_merch;
    this.manager = manager;
    this.manager_phone = manager_phone;
  }
}
