import React from 'react';
import { IconButton, Button, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { COLORS_ENTREGAS_COM } from 'src/theme/colors';
import Edit from '../../assets/icons/Edit.png';
import Cross from '../../assets/icons/Cross.png';
import Draft from '../../assets/icons/Draft.png';
import SixDots from '../../assets/icons/SixDots.png';

const useStyles = makeStyles(() => ({
  commonButton: {
    cursor: 'pointer'
  },
  outlinedButton: {
    borderColor: COLORS_ENTREGAS_COM.primary,
    borderWidth: 1,
    color: COLORS_ENTREGAS_COM.primary,
    textTransform: 'none',
    height: 50,
    borderRadius: 10
  }
}));

const GreenEditButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={Edit} alt={alt || ''} />
    </IconButton>
  );
};

export const GreenDraftButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={Draft} alt={alt || ''} />
    </IconButton>
  );
};

export const GreenCrossButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={Cross} alt={alt || ''} />
    </IconButton>
  );
};

export const SixDotsButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={SixDots} alt={alt || ''} />
    </IconButton>
  );
};

export const YellowButton = withStyles(theme => ({
  root: {
    color: theme.palette.expressColors.primaryLight,
    backgroundColor: theme.palette.expressColors.primaryDark,
    boxShadow: 'none'
  }
}))(Button);

export const GreenButton = withStyles(theme => ({
  root: {
    color: theme.palette.expressColors.white,
    backgroundColor: theme.palette.expressColors.green,
    boxShadow: 'none'
  }
}))(Button);

export const OrangeButton = withStyles(() => ({
  root: {
    color: COLORS_ENTREGAS_COM.white,
    background: COLORS_ENTREGAS_COM.primaryGradiant,
    boxShadow: 'none',
    textTransform: 'none',
    height: 50,
    borderRadius: 10,
    '&:disabled': {
      background: COLORS_ENTREGAS_COM.lightGray
    }
  }
}))(Button);

export const OutlinedOrangeButton = props => {
  const classes = useStyles();
  return (
    <Button variant="outlined" className={classes.outlinedButton} {...props} />
  );
};

export const BigOrangeButton = withStyles(() => ({
  root: {
    color: COLORS_ENTREGAS_COM.white,
    background: COLORS_ENTREGAS_COM.primaryLight,
    boxShadow: 'none',
    height: 50,
    borderRadius: 10
  }
}))(Button);

export const ApplicationButton = withStyles(() => ({
  root: {
    color: COLORS_ENTREGAS_COM.white,
    background: COLORS_ENTREGAS_COM.transparent,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 14
  }
}))(Button);

export const OrangeButtonCommerce = withStyles(() => ({
  root: {
    color: COLORS_ENTREGAS_COM.white,
    background: COLORS_ENTREGAS_COM.primary,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 14,
    width: '150px',
    fontSize: '18px',
    '&:hover': {
      backgroundColor: COLORS_ENTREGAS_COM.primaryLight
    },
    '&:disabled': {
      background: COLORS_ENTREGAS_COM.lightGray
    }
  }
}))(Button);

export const GrayButton = withStyles(theme => ({
  root: {
    color: COLORS_ENTREGAS_COM.gray,
    background: theme.palette.background.dark,
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    width: 140,
    borderRadius: 10,
    '&:disabled': {
      background: COLORS_ENTREGAS_COM.lightGray
    },
    '&:active, &:hover': {
      background: COLORS_ENTREGAS_COM.orange,
      color: COLORS_ENTREGAS_COM.white
    }
  }
}))(Button);

export const SimpleOrangeButton = withStyles(() => ({
  root: {
    'box- sizing': 'border- box',
    background: '#F79868',
    border: '1px solid #F79868',
    'border-radius': '5px',
    '&:hover': {
      backgroundColor: COLORS_ENTREGAS_COM.primaryLight
    },
    '&:disabled': {
      background: COLORS_ENTREGAS_COM.lightGray
    }
  }
}))(Button);

export default GreenEditButton;
