import Resizer from 'react-image-file-resizer';

export const FILE_TYPES = {
  IMAGE: 'image/*',
  ANY: ''
};

const FileUtils = {
  /**
   *
   * @param {FILE_TYPES | string} fileTypes
   * @returns {Promise<File>}
   */
  async selectSingleFile(fileTypes = FILE_TYPES.ANY) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', fileTypes);
    input.click();
    return new Promise(resolve => {
      input.onchange = event => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        resolve(file);
      };
      input.onabort = () => resolve(null);
    });
  },
  /**
   *
   * @param {File} imageFile
   * @returns {Promise<File>}
   */
  async resizeImage(imageFile) {
    const compressedBlob = new Promise(resolve => {
      Resizer.imageFileResizer(
        imageFile,
        2048,
        2048,
        'JPEG',
        70,
        0,
        uri => {
          resolve(uri);
        },
        'blob'
      );
    });
    return this.blobToFile(compressedBlob, imageFile.name);
  },
  /**
   *
   * @param {Blob | any} blob
   * @param {string} fileName
   * @returns {File}
   */
  blobToFile(blob, fileName) {
    const newBlob = blob;
    newBlob.lastModifiedDate = new Date();
    newBlob.name = fileName;
    return blob;
  },
  /**
   *
   * @param {File} file
   * @returns {string}
   */
  getFileUrl(file) {
    if (file) {
      return URL.createObjectURL(file);
    }
    return '';
  },
  isMaxSize(fileSize, maxFileSize = Number.POSITIVE_INFINITY) {
    return fileSize > maxFileSize;
  },
  isImage(file) {
    const IMAGES_MIMETYPES = [
      'image/gif',
      'image/png',
      'image/jpeg',
      'image/bmp',
      'image/webp'
    ];
    return IMAGES_MIMETYPES.includes(file.type);
  },
  validateImageSizeAndFormat(
    image,
    maxDimensions,
    maxSize,
    validImageExtensions
  ) {
    const messageDimensions = [];
    if (image) {
      if (maxDimensions) {
        // eslint-disable-next-line react/no-this-in-sfc
        if (maxDimensions.minWidth && maxDimensions.minWidth > this.width) {
          messageDimensions.push(
            `Ancho mínimo de ${maxDimensions.minWidth} px`
          );
        }
        // eslint-disable-next-line react/no-this-in-sfc
        if (maxDimensions.maxWidth && maxDimensions.maxWidth < this.width) {
          messageDimensions.push(
            `Ancho maximo de ${maxDimensions.maxWidth} px`
          );
        }
        if (
          maxDimensions.minHeight &&
          // eslint-disable-next-line react/no-this-in-sfc
          maxDimensions.minHeight > this.height
        ) {
          messageDimensions.push(
            `Alto mínimo de ${maxDimensions.minHeight} px`
          );
        }
        if (
          maxDimensions.maxHeight &&
          // eslint-disable-next-line react/no-this-in-sfc
          maxDimensions.maxHeight < this.height
        ) {
          messageDimensions.push(
            `Alto maximo de ${maxDimensions.maxHeight} px`
          );
        }
      }
      if (!validImageExtensions.includes(image.type)) {
        const format = image.type.split('/');
        console.log();
        messageDimensions.push(
          `El formato ${format[1] ? format[1] : image.type} no es aceptado.`
        );
      }
      if (maxSize) {
        if (image.size > maxSize) {
          messageDimensions.push(
            `El tamaño de la imagen subida excede el límite permitido (${maxSize /
              1024 /
              1024}mb)`
          );
        }
      }
    }
    return messageDimensions;
  },
  validateFileSize(file, maxSize) {
    const messageDimensions = [];
    if (maxSize) {
      if (file.size > maxSize) {
        messageDimensions.push(
          `El tamaño de la imagen subida excede el límite permitido (${maxSize /
            1024 /
            1024}mb)`
        );
      }
    }
    return messageDimensions;
  }
};

export default FileUtils;
