import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../constants';

const useProductsPerOrder = orderId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      doc: orderId,
      subcollections: [{ collection: COLLECTIONS.ORDER_PRODUCT }],
      storeAs: `products_${orderId}`
    }
  ]);
  const orders = useSelector(
    state => state.firestore.ordered[`products_${orderId}`]
  );
  return orders;
};

export default useProductsPerOrder;
