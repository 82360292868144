import React, { useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import Dialog from '@material-ui/core/Dialog';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FileUtils, { FILE_TYPES } from '../../../utils/fileUtils';

const SelectImageMessage = ({
  icon = null,
  onClose = () => {},
  onAccept = async file => {},
  compressed = false
}) => {
  const [imageFile, setImageFile] = useState(null);
  const [previewOpened, setPreviewOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();

  async function selectImage() {
    const file = await FileUtils.selectSingleFile(FILE_TYPES.IMAGE);
    if (compressed) {
      const compressedFile = await FileUtils.resizeImage(file);
      setImageFile(compressedFile);
      setPreviewOpened(true);
    } else {
      setImageFile(file);
      setPreviewOpened(true);
    }
  }

  function clean() {
    setPreviewOpened(false);
    setImageFile(null);
    setLoading(false);
  }
  async function accept() {
    setLoading(true);
    await onAccept(imageFile);
    clean();
  }

  function cancel() {
    clean();
    onClose();
  }

  return (
    <>
      <Dialog
        onClose={onClose}
        maxWidth={false}
        aria-labelledby="customized-dialog-title"
        open={previewOpened}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <Typography variant="h4" component="span">
            {translation('select_image.preview')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <img
            alt="alt"
            src={FileUtils.getFileUrl(imageFile)}
            style={{ maxWidth: '70vw', maxHeight: '70vh' }}
          />
        </DialogContent>
        <DialogActions>
          {!loading && (
            <>
              <Button onClick={cancel} color="primary">
                {translation('select_image.actions.cancel')}
              </Button>
              <Button onClick={accept} color="primary">
                {translation('select_image.actions.send')}
              </Button>
            </>
          )}
          {loading && (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </DialogActions>
      </Dialog>
      <div onClick={selectImage}>{icon}</div>
    </>
  );
};

export default SelectImageMessage;
