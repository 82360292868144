import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-multi-lang';
import { useHistory } from 'react-router';
import { COLORS } from '../../../theme/colors';
import XpressModal from '../../../components/Modal';
import ModalInfo from '../../../components/Modal/ModalInfo';
import WithLoading from '../../../components/WithLoading';
import { useRestaurantSnap } from '../../../hooks/useRestaurant';
import {
  resetLimitCall,
  sendNewOrder
} from '../../../services/makeOrderService';

const useStyles = makeStyles(() => ({
  iconInfo: {
    marginLeft: 10,
    color: COLORS.orange,
    cursor: 'pointer',
    width: 26,
    height: 26
  },
  content: {
    padding: '16px 32px 24px',
    marginLeft: '35%',
    marginRight: '35%',
    marginTop: '10%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  },
  buttonStyle: {
    marginTop: 20
  },
  boxStyle: {
    marginTop: 12
  }
}));

const BoxLoading = WithLoading(Box);

const ModalRequestDriver = ({ handleClose = () => {}, subsidiary }) => {
  const classes = useStyles();
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const translation = useTranslation();
  const history = useHistory();
  const restaurant = useRestaurantSnap(subsidiary?.restaurant_id);

  const points = [
    {
      text: '¿Cómo funciona la forma de solicitar una MOTO XPRESS?',
      bold: true
    },
    {
      text: '• Al solicitar una moto, XPRESS le enviará una moto de su flota',
      bold: false
    },
    {
      text: '• La moto da su precio de entrega al momento del recojo',
      bold: false
    },
    {
      text: '• El comercio y la moto acuerdan quién hace el pago del producto',
      bold: false
    },
    {
      text: '• El comercio y la moto acuerdan quién hace le pago de la carrera',
      bold: false
    },
    {
      text:
        '• La moto tiene contacto directo con el cliente para coordinar la entrega',
      bold: false
    },
    {
      text:
        '• El comercio provee todos los detalles del cliente y dirección para entregar los productos',
      bold: false
    },
    {
      text:
        '• Puedes entregar ordenes por teléfono y whatsApp usando este servicio',
      bold: false
    },
    {
      text:
        '• XPRESS no tiene detalles adicionales del cliente para la entrega',
      bold: false
    },
    {
      text: '• XPRESS no cobra por el uso de este servicio',
      bold: false
    }
  ];
  const clearModal = () => {
    handleClose();
    setShowModal(false);
    setLoading(false);
  };

  const sentOrderMoto = async () => {
    setLoading(true);
    const newOrder = await sendNewOrder(
      restaurant,
      subsidiary,
      { city_id: subsidiary?.city?.id },
      {},
      {},
      0,
      true,
      true,
      true
    );
    if (newOrder.isSucceeded && newOrder.data.order) {
      enqueueSnackbar(translation('notification.order_successfully'), {
        variant: 'success'
      });
      await resetLimitCall(subsidiary?.id);
      history.push(`/admin/orders`);
    } else {
      console.log('newOrder', newOrder);
      enqueueSnackbar(newOrder.message, {
        variant: 'error'
      });
    }
    handleClose();
    setLoading(false);
  };
  return (
    <div>
      <ModalInfo
        title="SOLICITA UN REPARTIDOR"
        isVisible={showModalInfo}
        description=" "
        confirm={() => setShowModalInfo(false)}
        onClose={() => setShowModalInfo(false)}
        component={
          <Box className={classes.boxStyle}>
            {points.map((point, index) => (
              <Typography
                key={index}
                style={{
                  fontWeight: point.bold ? 'bold' : 'normal',
                  fontSize: 14
                }}
              >
                {point.text}
              </Typography>
            ))}
          </Box>
        }
      />
      {showModal && (
        <XpressModal handleClose={handleClose}>
          <Grid>
            <Card className={classes.content}>
              <CardHeader
                className={classes.Header}
                title={
                  <Box display="flex">
                    <Typography style={{ fontWeight: 'bold' }}>
                      Solicitar una MOTO
                    </Typography>
                    <InfoOutlinedIcon
                      className={classes.iconInfo}
                      onClick={() => setShowModalInfo(true)}
                    />
                  </Box>
                }
              />
              <Divider />
              <BoxLoading isLoading={loading}>
                <CardContent>
                  <Box width="100%" mt={2} mb={2}>
                    <Typography style={{ fontWeight: 'bold', marginRight: 4 }}>
                      ¿Estás seguro de que necesitas una MOTO XPRESS?
                    </Typography>
                    <Typography>
                      La moto se dirigirá a la siguiente dirección de recojo:
                    </Typography>
                    <Box>
                      <Box style={{ display: 'flex' }}>
                        <Typography
                          style={{ fontWeight: 'bold', marginRight: 4 }}
                        >
                          Restaurante:
                        </Typography>
                        <Typography>
                          {subsidiary?.restaurant_name || '-'}
                        </Typography>
                      </Box>
                      <Box style={{ display: 'flex' }}>
                        <Typography
                          style={{ fontWeight: 'bold', marginRight: 4 }}
                        >
                          Sucursal:
                        </Typography>
                        <Typography>{subsidiary?.name || '-'}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.buttonStyle}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={sentOrderMoto}
                      disabled={false}
                    >
                      Solicitar Moto
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={clearModal}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </CardContent>
              </BoxLoading>
            </Card>
          </Grid>
        </XpressModal>
      )}
    </div>
  );
};

export default ModalRequestDriver;
