import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../constants';
import { useCurrentSubsidiary } from '../useRestaurant';
import { isOwner, isSupervisor } from '../../utils/helpers/userHelper';

export default function useMembersConnect() {
  const user = useSelector(state => state.account.user);
  const currentSubsidiary = useCurrentSubsidiary();
  const members = [];
  if (isSupervisor(user)) {
    if (currentSubsidiary.id) {
      members.push({
        collection: COLLECTIONS.USER,
        where: ['subsidiary_id', 'array-contains-any', [currentSubsidiary.id]],
        storeAs: 'members'
      });
    }
  }
  if (isOwner(user)) {
    if (user.restaurant_id) {
      members.push({
        collection: COLLECTIONS.USER,
        where: ['restaurant_id', '==', user.restaurant_id],
        storeAs: 'members'
      });
    }
  }

  useFirestoreConnect(members);
  return [];
}
