/* eslint-disable react-hooks/rules-of-hooks */
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../constants';

export const usePromotion = promotionId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PROMOS,
      doc: promotionId
    }
  ]);
  const promotion = useSelector(state => {
    if (
      state.firestore.data[COLLECTIONS.PROMOS] &&
      state.firestore.data[COLLECTIONS.PROMOS][promotionId]
    ) {
      return state.firestore.data[COLLECTIONS.PROMOS][promotionId];
    }
  });

  return promotion;
};
