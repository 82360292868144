import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { COLORS_ENTREGAS_COM } from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  text: {
    color: COLORS_ENTREGAS_COM.white,
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 20,
    marginRight: 20,
    marginLeft: 20,
    textAlign: 'center'
  },
  loader: {
    color: COLORS_ENTREGAS_COM.white
  }
}));

export default function BackdropLoader({ open = false, text = '' }) {
  const classes = useStyles();
  return (
    <Backdrop
      style={{
        zIndex: 9999,
        flexDirection: 'column',
        backgroundColor: 'rgba(0,0,0,0.7)'
      }}
      open={open}
    >
      {Boolean(text) && (
        <Typography className={classes.text}>{text}</Typography>
      )}
      <CircularProgress className={classes.loader} />
    </Backdrop>
  );
}
