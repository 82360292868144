import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  Card,
  Box,
  Typography,
  CardHeader,
  NativeSelect,
  FormHelperText,
  Button,
  TextField,
  CardContent,
  Avatar,
  Grid,
  OutlinedInput,
  Divider
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { Circle } from 'react-feather';
import { useChatLayoutStyles } from '../styles';
import { GreenCrossButton } from '../../../../components/Inputs/Buttons';
import { useOrderShortInfo } from '../../../../hooks/Order/useOrders';
import { useSubsidiaryActiveChats } from '../../../../hooks/Chat/useChat';
import {
  createChat,
  lastMessageViewrs
} from '../../../../services/chatService';
import { getOwner } from '../../../../services/restaurantService';
import { setChats } from '../../../../actions/chatActions';
import WithLoading from '../../../../components/WithLoading';

import { SUBJECT_OPTIONS } from '../../constants';

const MAX_OTHER_TEX = 12;

const BoxLoading = WithLoading(Box);
const ChatLayout = ({ handleClose }) => {
  const classes = useChatLayoutStyles();
  const translation = useTranslation();
  const dispatch = useDispatch();
  const [limitDay, setLimitDay] = useState(1);
  const chats = useSubsidiaryActiveChats(limitDay);
  const [subject, setSubject] = useState(SUBJECT_OPTIONS[0].value);
  const [valid, setValid] = useState(false);
  const [order, setOrder] = useState('');
  const [otherText, setOtherText] = useState('');
  const [owner, setOwner] = useState({});
  const orders = useOrderShortInfo();
  const [renderChats, setRenderChats] = useState(chats);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.account.user);
  const subsidiary = useSelector(state => state.account.currentSubsidiary);
  const restaurant = useSelector(state => state.account.restaurant);

  useEffect(() => {
    const sameValue =
      renderChats.length === chats.length &&
      renderChats.every((value, index) => value === chats[index]);
    if (chats && !sameValue) setRenderChats(chats);
    // eslint-disable-next-line
  }, [chats, limitDay]);
  useEffect(() => {
    const fetchOwner = async () => {
      const restOwner = await getOwner(restaurant.id);
      setOwner(restOwner);
    };
    fetchOwner();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    switch (subject) {
      case 'INVALID':
        setValid(false);
        break;
      case 'ORDER':
        if (order) setValid(true);
        else setValid(false);
        break;
      case 'OTHER':
        if (otherText) setValid(true);
        else setValid(false);
        break;
      default:
        setValid(true);
        break;
    }
  }, [subject, order, otherText]);

  const createNewChat = async () => {
    setLoading(true);
    dispatch(setChats([]));
    const [filteredOrder] = orders.filter(o => o.id === order);
    const chat = {
      subject,
      order_summary: { ...filteredOrder },
      other_text: otherText,
      restaurant_id: restaurant.id,
      restaurant_name: restaurant.name,
      subsidiary_id: subsidiary.id,
      subsidiary_name: subsidiary.name
    };
    const newChat = await createChat({
      chat,
      user,
      owner,
      restaurantPhoto: restaurant.logo_photo
    });
    setSubject(SUBJECT_OPTIONS[0].value);
    setOtherText('');
    setOrder('');
    dispatch(setChats([newChat]));
    handleClose();
    setLoading(false);
  };

  const openChat = async chat => {
    if (!chat.last_message_viewers.includes(user.id)) {
      const lasMessageViewrs = [...chat.last_message_viewers];
      lasMessageViewrs.push(user.id);
      await lastMessageViewrs(chat.id, {
        last_message_viewers: [...lasMessageViewrs]
      });
    }
    dispatch(setChats([chat]));
    handleClose();
  };

  const CardTitle = () => {
    return (
      <Box className={clsx(classes.header)}>
        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
          {translation('chat.chat_layout.title')}
        </Typography>
        <GreenCrossButton onClick={handleClose} />
      </Box>
    );
  };

  const ordersSelect = () => {
    return (
      <Box>
        <NativeSelect
          className={clsx(classes.nativeSelect)}
          variant="Outlined"
          value={order}
          onChange={e => setOrder(e.target.value)}
          input={<OutlinedInput />}
        >
          <option key="" value="" />
          {orders.map(so => (
            <option key={so.id} value={so.id}>
              {so.order_code}
            </option>
          ))}
        </NativeSelect>
        <FormHelperText>
          {translation('chat.chat_layout.subjects.select_order')}
        </FormHelperText>
      </Box>
    );
  };

  const getOtherTextLengthAvailable = () => MAX_OTHER_TEX - otherText.length;
  const otherTextBox = () => {
    return (
      <Box>
        <TextField
          fullWidth
          onChange={e => setOtherText(e.target.value)}
          value={otherText}
          variant="outlined"
          size="small"
          autoFocus
          inputProps={{ maxLength: MAX_OTHER_TEX }}
        />
        <Typography className={classes.feedback}>
          {`${getOtherTextLengthAvailable()} ${translation(
            'chat.chat_layout.subjects.chars_available'
          )}`}
        </Typography>
      </Box>
    );
  };

  const CardSubheader = () => {
    return (
      <Box>
        <Box className={clsx(classes.cockpit)}>
          <Box>
            <NativeSelect
              className={clsx(classes.nativeSelect)}
              variant="Outlined"
              value={subject}
              onChange={e => {
                setSubject(e.target.value);
                setOtherText('');
                setOrder('');
              }}
              input={<OutlinedInput />}
            >
              {SUBJECT_OPTIONS.map(o => (
                <option key={o.value} value={o.value}>
                  {translation(o.label)}
                </option>
              ))}
            </NativeSelect>
            <FormHelperText>
              {translation('chat.chat_layout.subjects.order_helper')}
            </FormHelperText>
          </Box>
          <Box>
            <Button
              className={clsx(classes.button)}
              onClick={createNewChat}
              disabled={!valid}
              variant="contained"
            >
              {translation('chat.chat_layout.new_chat')}
            </Button>
          </Box>
        </Box>
        <Box>{subject === 'ORDER' && ordersSelect()}</Box>
        <Box>{subject === 'OTHER' && otherTextBox()}</Box>
      </Box>
    );
  };

  const getTitle = ({ sub, label, orderCode, otherTextLabel }) => {
    switch (sub) {
      case 'ORDER':
        return (
          <Typography>{`${translation(label)} - ${orderCode}`}</Typography>
        );
      case 'OTHER':
        return (
          <Typography>{`${translation(label)} - ${otherTextLabel}`}</Typography>
        );
      default:
        return <Typography>{`${translation(label)}`}</Typography>;
    }
  };

  const getLapseTime = c => {
    let lapseTime = '';
    if (c.last_message_created) {
      const diferenceMinutes = moment().diff(
        moment(c.last_message_created.toDate()),
        'minutes'
      );
      if (diferenceMinutes < 60) {
        lapseTime = `${diferenceMinutes} min`;
      } else if (diferenceMinutes >= 60 && diferenceMinutes < 24 * 60) {
        const diferenceHours = moment().diff(
          moment(c.last_message_created.toDate()),
          'hours'
        );
        lapseTime = `${diferenceHours} h`;
      } else if (
        diferenceMinutes >= 24 * 60 &&
        diferenceMinutes < 24 * 60 * 7
      ) {
        const diferenceDays = moment().diff(
          moment(c.last_message_created.toDate()),
          'days'
        );
        lapseTime = `${diferenceDays} d`;
      } else {
        const diferenceWeeks = moment().diff(
          moment(c.last_message_created.toDate()),
          'weeks'
        );
        lapseTime = `${diferenceWeeks} sem`;
      }
    }
    return lapseTime;
  };

  const chatCards = () => {
    if (!renderChats.length === 0) return <></>;
    return renderChats.map(c => {
      const [option] = SUBJECT_OPTIONS.filter(so => so.value === c.subject);
      const restaurantPhoto = c.admin_photo || '';
      let isRead = true;
      if (!c.last_message_viewers.includes(user.id)) isRead = false;
      const lapseTime = getLapseTime(c);
      return (
        <Box
          key={c.id}
          className={clsx(classes.chatContainer)}
          onClick={() => openChat(c)}
        >
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={9}>
              <Box display="flex" alignItems="center">
                <Avatar
                  className={classes.avatar}
                  alt="avatar"
                  src={restaurantPhoto}
                />
                <div>
                  <Typography className={classes.fontWeightBold}>
                    {getTitle({
                      sub: option.value,
                      label: option.label,
                      orderCode: c.order_summary.order_code,
                      otherTextLabel: c.other_text
                    })}
                  </Typography>
                  <Typography
                    className={isRead ? classes.textRead : classes.textUnread}
                  >
                    {_.truncate(c.last_message_send, {
                      length: 40,
                      separator: ' '
                    })}
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  style={{
                    paddingRight: 7,
                    paddingTop: 10,
                    fontWeight: 300,
                    fontSize: 14
                  }}
                >
                  {lapseTime}
                </Typography>
                {!isRead && (
                  <div style={{ paddingTop: 12 }}>
                    <Circle fill="#56B19E" color="#56B19E" size={12} />
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Box>
      );
    });
  };

  return (
    <Box className={clsx(classes.container)}>
      <Card className={clsx(classes.cardContainer)}>
        <BoxLoading isLoading={loading}>
          <CardHeader
            className={clsx(classes.cardHeader)}
            title={<CardTitle />}
            subheader={<CardSubheader />}
          />
        </BoxLoading>
        <Typography style={{ fontSize: 20, marginBottom: 5, paddingLeft: 16 }}>
          {translation('chat.chat_layout.recent_conversations')}
        </Typography>
        <Divider />
        <CardContent>
          {chats && chatCards(chats)}
          <Box display="flex" justifyContent="center">
            <Button
              size="small"
              className={clsx(classes.buttonGreen)}
              onClick={() => setLimitDay(limitDay + 7)}
            >
              {translation('chat.chat_layout.see_more')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ChatLayout;
