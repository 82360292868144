import React, { forwardRef } from 'react';
import Modal from '@material-ui/core/Modal';
import { useTheme } from '@material-ui/core';
import Delete from './Delete';
import Comfirmation from './Comfirmation';
import UploadFile from './UploadFile';
import EditDocument from './EditDocment';

const DefaultModals = forwardRef(
  ({ handleConfirm, handleClose, type, message, path, status, name }, ref) => {
    switch (type) {
      case 'deleteConfirmation':
        return (
          <Delete
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
          />
        );
      case 'comfirmation':
        return (
          <Comfirmation
            ref={ref}
            message={message}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
          />
        );
      case 'uploadFile':
        return (
          <UploadFile
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            path={path}
          />
        );
      case 'editDocument':
        return (
          <EditDocument
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            status={status}
            name={name}
          />
        );
      default:
        return null;
    }
  }
);

const XpressModal = ({
  handleConfirm,
  handleClose,
  type,
  message,
  path,
  status,
  name,
  children
}) => {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{
          style: {
            background: `transparent linear-gradient(180deg, ${theme.palette.modalBackground.primary} 100%, ${theme.palette.modalBackground.primaryDegradation} 100%) 0% 0% no-repeat padding-box`,
            opacity: 0.44
          }
        }}
      >
        <>
          {type && (
            <DefaultModals
              message={message}
              handleConfirm={handleConfirm}
              handleClose={handleClose}
              type={type}
              path={path}
              status={status}
              name={name}
            />
          )}
          {!type && { ...children }}
        </>
      </Modal>
    </div>
  );
};

export default XpressModal;
