import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isDriver } from '../utils/helpers/userHelper';
import { ROUTES_PATHS } from '../constants';
import authService from '../services/authService';

function AuthDriver({ children }) {
  const account = useSelector(state => state.account);
  const dispatch = useDispatch();
  if (!account.user) {
    return <Redirect to={ROUTES_PATHS.DRIVER_LOGIN} />;
  }
  if (!isDriver(account.user)) {
    authService.logoutClean(dispatch);
    return <Redirect to="/" />;
  }
  return children;
}

AuthDriver.propTypes = {
  children: PropTypes.any
};

export default AuthDriver;
