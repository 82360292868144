import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Sounds from '../../assets/sounds/sounds';
import { useCurrentSubsidiary } from '../../hooks/useRestaurant';

const ticketAlert = new Audio(Sounds.ticket);

export default function ReadyToPickTicketAlert() {
  const [notifications, setNotifications] = useState([]);
  const currentSubsidiary = useCurrentSubsidiary();
  const orders_preparing = useSelector(
    state => state.firestore.ordered.orders_preparing
  );

  const notifyTicket = () => {
    if (!orders_preparing) return;
    const ordersWithTicket = orders_preparing.filter(o => o.ticket);
    if (!ordersWithTicket.length) return;
    const newNotifications = [];
    ordersWithTicket.forEach(o => {
      if (!notifications.includes(o.ticket)) {
        newNotifications.push(o.ticket);
        ticketAlert.play();
      }
    });
    setNotifications([...notifications, ...newNotifications]);
  };

  useEffect(() => {
    setNotifications([]);
    notifyTicket();
    // eslint-disable-next-line
  },[currentSubsidiary.id])
  useEffect(() => {
    notifyTicket();
    // eslint-disable-next-line
  }, [orders_preparing]);

  return null;
}
