import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  useMediaQuery
} from '@material-ui/core';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import NavBar from './NavBar';
import NavBarMobile from './NavBarMobile';
import TopBar from './TopBar';
import { useNewOrders } from '../../hooks/Order/useOrders';
import useMembersConnect from '../../hooks/Members/useMembers';
import ReadyToPickTicketAlert from '../../components/Orders/ReadyToPickTicketAlert';
import XpressModal from '../../components/Modal';
import NewOrderModal from '../../views/orders/OrderDetailsView/modal/NewOrderModal';
import Sounds from '../../assets/sounds/sounds';
import ChatViewPage from './xpressChat';
import { useAppOrders, useRestaurant } from '../../hooks/useRestaurant';
import { useSubsidiaryChat, useNewMessageChat } from '../../hooks/Chat/useChat';
import { useSubsidiary } from '../../hooks/AdminHooks/subsidiaries/useSubsidiaries';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const alert = new Audio(Sounds.alert);
const alertChat = new Audio(Sounds.alertChat);
function DashboardLayout({ children }) {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:430px)');
  const activeChats = useSubsidiaryChat();
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const subsidiary = useSelector(
    state => state.account.currentSubsidiary || ''
  );
  const translation = useTranslation();
  const [open, setOpen] = useState(false);
  const user = useSelector(state => state.account.user);
  const subsidiaryListen = useSubsidiary(subsidiary.id);
  const restaurant = useSelector(state => state.account.restaurant || '');
  useAppOrders(restaurant.id, subsidiary.id || '');
  const [modal, setModal] = useState(null);
  const [orderInModal, setOrderInModal] = useState({ id: null });
  const [messageAmount, setMessageAmount] = useState(null);
  const newOrders = useNewOrders();
  useMembersConnect();
  useRestaurant();
  const snackbar = useSnackbar();
  const newChat = useNewMessageChat();
  const [lastNewMessage, setLastNewMessage] = useState({ id: null });

  useEffect(() => {
    if (subsidiaryListen && !open) {
      if (subsidiary.xpress_motos !== subsidiaryListen.xpress_motos) {
        setOpen(true);
        setTimeout(async () => {
          window.location.reload();
        }, 2000);
      }
    }
  }, [subsidiaryListen, subsidiary, open]);
  useEffect(() => {
    if (user && activeChats) {
      const newMessages = activeChats.filter(
        c => !c.last_message_viewers.includes(user.id)
      );
      setMessageAmount(newMessages.length);
    }
  }, [user, activeChats]);

  const showNotification = dataLastChat => {
    alertChat.play();
    setLastNewMessage(dataLastChat.message);
    const title = `Xpress- ${dataLastChat.message.sender_name}`;
    const body = dataLastChat.message.message;
    snackbar.enqueueSnackbar(`${title} - ${body} `, {
      variant: 'Success'
    });
  };

  useEffect(() => {
    if (
      newChat &&
      !_.isEmpty(newChat.chat) &&
      newChat.message.id !== lastNewMessage.id
    ) {
      if (
        newChat.chat.merchantIdsToNotify &&
        newChat.chat.merchantIdsToNotify.includes(user.id)
      ) {
        if (!newChat.chat.last_message_viewers.includes(user.id)) {
          if (subsidiary.id === newChat.chat.subsidiary_id)
            showNotification(newChat);
        }
      }
    }
    // eslint-disable-next-line
  }, [snackbar, newChat]);

  useEffect(() => {
    if (newOrders && newOrders.length && newOrders[0].id !== orderInModal.id) {
      setShowModal(true);
      alert.play();
      alert.loop = true;
      setOrderInModal(newOrders[0]);
      setModal(
        <XpressModal>
          <NewOrderModal order={newOrders[0]} redirect />
        </XpressModal>
      );
    }
  }, [newOrders, orderInModal]);

  if (newOrders && !newOrders.length && showModal) {
    alert.pause();
    setShowModal(false);
  }
  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        restaurantId={restaurant.id}
        chatNewMessagesAmount={messageAmount}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        handleOpenNav={setMobileNavOpen}
        openMobile={isMobileNavOpen && !isMobile}
        chatNewMessagesAmount={messageAmount}
      />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>

      <NavBarMobile
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen && isMobile}
        chatNewMessagesAmount={messageAmount}
      />

      <ChatViewPage />
      {subsidiary && <ReadyToPickTicketAlert />}
      {showModal && modal}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h2">
                {translation('modal.disabled_motos')}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {translation('modal.body_motos_disabled')}
              </DialogContentText>
            </DialogContent>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
