import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
// import firebase from 'firebase/app';
import 'firebase/firestore';
import ReactGA from 'react-ga';
import ConfirmationProvider from './context/DialogContext';
import BackdropLoaderProvider from './components/loaders/backdropLoaderProvider';
import env from './env';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// firebase.firestore().enablePersistence();

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

ReactGA.initialize(env.googleAnalytics);

function App() {
  useStyles();
  const { settings } = useSettings();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  history.listen(location => {
    ReactGA.pageview(location.pathname);
  });
  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <ConfirmationProvider>
              <Router history={history}>
                <Auth>
                  <ScrollReset />
                  <GoogleAnalytics />
                  <CookiesNotification />
                  <Routes />
                  <BackdropLoaderProvider />
                </Auth>
              </Router>
            </ConfirmationProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
