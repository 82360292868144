/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_NOTIFICATIONS,
  GET_ADMIN_NOTIFICATIONS
} from 'src/actions/notificationsActions';

const initialState = {
  notifications: [],
  adminNotifications: []
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      const { notifications } = action.payload;

      return produce(state, draft => {
        draft.notifications = notifications;
      });
    }
    case GET_ADMIN_NOTIFICATIONS: {
      const { notifications } = action.payload;

      return produce(state, draft => {
        draft.adminNotifications = notifications;
      });
    }

    default: {
      return state;
    }
  }
};

export default notificationsReducer;
