import { useDispatch } from 'react-redux';
import { setBackdropLoader } from '../../reducers/backdropLoader';

export default function useBackdropLoader() {
  const dispatch = useDispatch();
  const showBackdrop = text => {
    dispatch(setBackdropLoader({ open: true, text }));
  };
  const hideBackdrop = () => {
    dispatch(setBackdropLoader({ open: false, text: '' }));
  };
  return [showBackdrop, hideBackdrop];
}
