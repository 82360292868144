import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBlhBl_GNqU14UTZmfAllGLjLAXOQhJ1y8',
  authDomain: 'xpress-production.firebaseapp.com',
  databaseURL: 'https://xpress-production.firebaseio.com',
  projectId: 'xpress-production',
  storageBucket: 'xpress-production.appspot.com',
  messagingSenderId: '455344764199',
  appId: '1:455344764199:web:ced4eb56b08738a739e502',
  measurementId: 'G-W8S4JCTMDP'
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

/* firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
}); */

const admin = firebase;

export const fbMessaging = firebase.messaging.isSupported();
export const FIRESTORE_TIMEOUT = 10000;
export const STORAGE_TIMEOUT = 30000;

// if (fbMessaging) {
//   firebase.messaging().usePublicVapidKey(process.env.REACT_APP_FCM_KEY);
// }
export default admin;
