import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { actionTypes } from 'redux-firestore';
import { logout } from 'src/actions/accountActions';
import { getUserRole, isAdmin } from '../../../utils/helpers/userHelper';
import { COLLECTIONS } from '../../../constants';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  userName: {
    fontWeight: 'bold',
    lineHeight: 'unset'
  },
  role: {
    lineHeight: 'unset',
    fontSize: 12
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const account = useSelector(state => state.account);
  const [isOpen, setOpen] = useState(false);
  const translation = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.SUBSIDIARY }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.RESTAURANT }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.PRODUCT }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.ORDER }
      });
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar(translation('account.unable_logout'), {
        variant: 'error'
      });
    }
  };
  const getRole = () => {
    const r = getUserRole(account.user);
    if (r) return translation(`roles.${r.toLowerCase()}`);
    return translation(`roles.unknown`);
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={account.user.profile_photo ? account.user.profile_photo.x40 : ''}
        />
        <Hidden smDown>
          <div className={classes.userInfoContainer}>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.userName}
            >
              {`${account.user.first_name} ${account.user.last_name}`}
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.role}
            >
              {getRole()}
            </Typography>
          </div>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to={`/${isAdmin(account.user) ? 'admin' : 'app'}/account`}
          onClick={handleClose}
        >
          {translation('account.account')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          {translation('account.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
