// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCVspJy8MtJy9My3GmU5KZhyFdEVPCjkgU',
  authDomain: 'xpress-logs.firebaseapp.com',
  databaseURL: 'https://xpress-logs-default-rtdb.firebaseio.com',
  projectId: 'xpress-logs',
  storageBucket: 'xpress-logs.appspot.com',
  messagingSenderId: '835028614793',
  appId: '1:835028614793:web:ea234f2c91730cadaf1058',
  measurementId: 'G-T50143GX95'
};

// Initialize Firebase
const LoggerApp = initializeApp(firebaseConfig, 'logger');
export default LoggerApp;
