import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  isAdmin,
  isATC,
  isDriver,
  isDriverInProcess,
  isDriverPending,
  isDriverAccepted
} from '../utils/helpers/userHelper';
import { ROUTES_PATHS } from '../constants';

function AuthGuard({ children }) {
  const account = useSelector(state => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if (isDriver(account?.user)) {
    if (isDriverAccepted(account?.user)) {
      return <Redirect to={ROUTES_PATHS.DRIVER_DASHBOARD} />;
    }
    if (isDriverInProcess(account?.user) || isDriverPending(account?.user)) {
      return (
        <Redirect
          to={`${ROUTES_PATHS.DRIVER_REGISTER}/${account?.user.id}/${account?.user.phone_number}`}
        />
      );
    }
  }
  if (isATC(account.user)) {
    return <Redirect to="/admin/orders" />;
  }
  if (isAdmin(account.user)) {
    return <Redirect to="/admin/orders" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
