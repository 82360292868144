import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';

const editProduct = async (id, fields) =>
  admin
    .firestore()
    .collection(COLLECTIONS.RESTAURANT)
    .doc(id)
    .update({ ...fields });

export default editProduct;

export const editRestaurant = async (id, fields) => {
  return admin
    .firestore()
    .collection(COLLECTIONS.RESTAURANT)
    .doc(id)
    .set({ ...fields }, { merge: true });
};

export const getOwner = async restId => {
  const userSnap = await admin
    .firestore()
    .collection(COLLECTIONS.USER)
    .where('restaurant_id', '==', restId)
    .get();
  const [owner] = userSnap.docs
    .map(doc => ({ ...doc.data() }))
    .filter(user => user.roles.OWNER);
  return owner;
};

export const updateMinimumPurchase = async (restaurantId, minimumPurchase) => {
  const updateMinimumPurchaseResult = admin
    .functions()
    .httpsCallable(`restaurant/minimumPurchaseRestaurant`);
  return updateMinimumPurchaseResult({
    restaurant_id: restaurantId,
    minimum_purchase: minimumPurchase
  });
};
